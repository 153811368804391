<template>
    <div>
        <div class="login-header">
            <img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo">
        </div>
        <div class="login-body">
            <div class="id-login">
                <div class="item">
                    <div :class="{'description':true, 'message-error': status === 'error', 'message-success': status === 'success'}">
                        {{ message }}
                    </div>
                </div>
                <div class="item">
                    <button type="submit" class="btn-submit" @click="backToLogin">{{ $t('login')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LoginMessage',
  props: [
    'message'
  ],
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      status: null
    };
  },
  created() {
    if (!this.message) {
      this.$router.push('/');
    }

    this.status = this.$route.params.status;
  },
  methods: {
    backToLogin() {
      window.location.href = this.baseUrl + '/frontend/login';
    }
  }
};
</script>

<style scoped src="../../sass/pages/forgot_password.css"></style>
<style scoped>
.login-body {
    padding-bottom: unset;
}

.item button {
    padding: unset;
    position: relative;
    border:unset;
    border-radius: 12px;
    color: #ffffff;
    height: 55px;
    width: 90%;
}

.description {
    font-size: 15px;
}

.message-error {
    color: #dc3545;
}

.message-success {
    color: #28a745;
}

@media (min-width: 500px) {
    .item button {
        width: 80%;
    }

    .description {
        width: 90%;
        padding-left: 10%;
    }
}

@media (min-width: 600px) {
    .item button {
        width: 60%;
    }

    .description {
        width: 80%;
        padding-left: 20%;
    }
}

@media (min-width: 700px) {
    .item input {
        width: 50%;
    }

    .description {
        width: 80%;
        padding-left: 20%;
    }
}

@media (min-width: 800px) {
    .item button {
        width: 60%;
    }

    .description {
        width: 80%;
        padding-left: 20%;
    }
}
</style>
