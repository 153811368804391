import BaseApi from './BaseApi';
class PageApi extends BaseApi {
  localityApiUrl = this.baseUrl + '/api/locality';

  getPageBySlug(pageSlug) {
    return this.axios.get(this.localityApiUrl + '/page/slug/' + `${pageSlug}`);
  }
}

export default new PageApi();
