<template>
    <div>
        <div class="login-header">
            <a href="/">
                <img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo">
            </a>
        </div>
        <div class="login-body">
            <div class="id-login">
                <p class="title">{{ $t('forgot_password.enter_email') }}</p>
                {{ $t('forgot_password.note') }}
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(sentRequest)">
                        <div class="item">
                            <validation-provider
                                :rules="'required|email|regex:^[a-zA-Z0-9_.+-]+@(?!-)[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'"
                                :custom-messages="{
                                    required: $t('login.validate.email.required'),
                                    email: $t('reset_password.email.email_validate'),
                                    regex: $t('reset_password.email.email_validate')
                                }"
                                v-slot="{ errors }"
                            >
                                <input type="text" v-model="form.email" @click="removeError" @keyup="trimEmail()">
                                <span v-if="emailError" class="error-message">{{ $t('forgot_password_frontend.email_error') }}</span>
                                <span class="error-message">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="item">
                            <button type="submit" class="btn-submit" @click="removeError">{{ $t('send')}}</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);
extend('regex', regex);

export default {
  name: 'ForgotPassword',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      form: {
        email: ''
      },
      emailError: false
    };
  },
  methods: {
    async sentRequest() {
      try {
        const result = await this.$auth.reporterForgotPassword({
          email: this.form.email
        });

        if (result.status === 'success') {
          window.location.href = this.baseUrl + '/frontend/reporter/forgot-password/sent';
        } else {
          this.emailError = true;
        }
      } catch (error) {
        this.emailError = true;
      }
    },
    removeError() {
      this.emailError = false;
    }
  }
};
</script>

<style scoped src="../../sass/pages/forgot_password.css"></style>
