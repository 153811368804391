<template>
<div class="b_page_tt">
    /* eslint-disable no-irregular-whitespace */

    <Header @show-login="showLogin()"/>

  <div class="main_side_wrapper">
    <main class="about_wrapper">
      <section class="about_topic">
        <h1>誰もが自分の「好き」と向き合い、発信し、共感者を募れる世界へ</h1>
        <div class="about_topic_kv">
          <img src="/locality/images/about-kv.jpg" alt="風景の写真です。">
        </div>
        <p>あなたの、好きなものは何ですか。<br><br>
          学校帰りに毎日食べた、和菓子屋さんのお饅頭。<br>
          神社にある大きな大きな木。<br>
          橋の上から見た山の姿。<br>
          そして、そこにいる人々。<br><br>
          その全てが、あなたの地元の「魅力」なのです。<br>
          あなたが知っている街の魅力。<br>
          あなただけしか知らない、街の魅力。<br>
          本当の「魅力」は、テレビやインターネットには載っていません。<br>
          もしかしたら、あなた以外誰も知らないかもしれない。<br><br>
          そんな「魅力」を誰かに伝えてみませんか。<br>
          あなたが好きな「魅力」には、本当はとても価値があるのです。<br><br>
          その「魅力」を、伝わるように伝えれば、<br>
          きっと世界の誰かが、<br>
          あなたが大切にしていたその想いに、共感してくれます。<br><br>
          ローカリティ！は、地元に住む人々が、<br>
          自分が大好きな地元の人やモノや出来事を、<br>
          自分で世界に発信し、<br>
          その魅力をともに愛する共感者を募る<br>
          全く新しいメディアプラットフォームです。
        </p>
        <h2>◆自分が好きなものこそ「ニュース」であり、「価値」である</h2>
        <p>ローカリティ！は、地域の人が、地元愛を爆発させるニュースサイトとして、2020年10月に始まりました。<br><br>
        「ニュース」とは、あなたの心動くこと、あなたの驚き・発見・感動のことを指します。人が生きる中で見つけた驚き・発見・感動を誰かに伝える、それこそが「ニュース」であり「価値」なのです。<br><br>
        ローカリティ！はそんな地元の「魅力」を発掘・発信するムーブメントが、全国から湧き上がってくる状態をつくりたいと思っています。ローカリティ！とは、メディアの名前でもあり、この想いを共有する人々の名前でもあります。<br><br>
        ローカリティ！のニュースは、「記者」ではなく、地域の魅力を発掘発信する「魅力発掘発信レポーター」（ハツレポーター）が書いています。そして、このサイトに載っているのは、ニュース「記事」ではなく、「魅力発掘発信レポート」略して「ハツレポ」なのです。<br><br>
        あなたの地元の好きなものを一緒に発信してくれる「仲間」を募集しています。<br><br>
        （ハツレポーター募集のリンクorバナー）
        </p>
        <h2>◆誰もが自分が大切にしている想いを「旗」として掲げられる世界へ</h2>
        <p>2021年10月、ローカリティ！は、誰もの大切にしているものを「旗」として掲げ、その共感者を募れるプラットフォームに進化します。<br><br>
        自分が好きなものを発掘発信し、共感者を募る世界を、もう一歩進めると、自分が大切にしている想いと向き合い、表現し、発信し、共感者を募る世界と言えると思います。<br><br>
        その自分が大切にしている想いと向き合い、表現し、共感者を募る行為を、私達は「旗を掲げる」と呼びたいと思います。<br><br>
        そしてその時、ローカルとは地理的概念を脱し、自分が大切に想う全ての存在を指すようになります。<br><br>
        ローカリティ！は、誰もが自分が大切にしている想いを「旗」として掲げて、共感者を募ることができるプラットフォームになりたいと思います。そして、共感者が集まり、社会のムーブメントを起こせるような基盤になりたいと思います。<br><br>
        人は、会社は、地域は、みな、大切にしている想いがあります。その想いを「旗」として掲げ、それに共感してくれる人が一人でも現れたら、人は生きていけるのではないでしょうか。私達は、そんな一人ひとりの大切にしている「想い」とともに、このローカリティ！を作っていきたいと思います。
        </p>
      </section>
      <section class="about_message">
        <h2>発行人兼編集主幹メッセージ</h2>
        <h3>「名も無き人々」が、自ら「旗」を掲げ、主役になる時代へ</h3>
        <p>私がイーストタイムズを創業した2015年当時、数々のインターネットメディアが勃興し、「新聞の時代は終わった」と豪語していました。しかし、それらのメディアは尽く、アクセス数が稼げるネタ、スポンサーからお金を引き出せるネタを、現場取材無しで書くことに終始していました。私は、そんな世界はおかしいと思いました。<br><br>
        「そこに生きる人々を伝える」をコンセプトに、私はイーストタイムズを創業し、東北各地の人々の生き様を聞いて、それを伝える仕事をしてきました。そこで出会った人々の姿は、東京で小さな世界で生きてきた私の価値観を大きく揺さぶりました。<br><br>
        津波で大きく破壊された街で、折れそうになる気持ちを、真っ赤な大漁旗に託し、プレハブの仮設商店街で営業を続ける人々。人がどんどんいなくなる街で、「俺はこの街が好きだから」と言って、東京から戻り、シャッター街の中、ガラス張りのカフェの明かりを灯し続ける若者。仕事の合間に「ちょっと見せたいものがある」と私を山の上まで連れて行き、「何でもない景色かもしれねえけっど、俺はいいもんだと思うんだ」と語ってくれた市役所職員。<br><br>
        人間とは、揺らぎがあるものです。<br>
        揺らぎには、言葉にならない「想い」があります。<br>
        人間とは、表現しきれぬ「想い」を持つ存在なのです。<br><br>
        アクセス数が無ければ価値がないのか、お金が無ければ価値がないのか。お金を稼がなければ、その「想い」に、価値はないのか。<br><br>
        私はそうではないと語る人々が、鮮やかに生きている世界を見てきました。<br><br>
        「そこに生きる人々を伝える」をテーマに始まった私達ですが、このローカリティ！で、「名も無き人々」が自ら「旗を」立てて共感者を募り、主役になる世界を作りたいと思います。<br><br>
        その「旗」には、きっと価値があります。<br><br>
        誰もが自分の「旗」を掲げ、それを互いに尊重できる世界を、ともに作りませんか。
        </p>
        <h4>合同会社イーストタイムズ代表社員CEO<br>ローカリティ！発行人兼編集主幹<br>中野宏一</h4>
        <div class="about_member_history">
          <p><span>略歴</span>1984年、秋田県生まれ埼玉県育ち。<br>
          東京大学法学部卒、東京大学公共政策大学院修了。<br>
          朝日新聞社において3年間校閲業務に従事した後、2015年に仙台に移住し、震災報道と地域報道を行うイーストタイムズ創業、代表に。Yahoo!系ニュース媒体で契約記者として数百万人に読まれるヒット記事を連発した。<br>
          2016年、住民参加型ニュースメディア「TOHOKU360」を創刊、副編集長兼発行人に。それらの経験を元に、東北各地や紀伊半島など、全国で住民らとともに地域の魅力をニュースの手法で発掘発信する事業を展開している。<br>
          2020年、全国のローカルの魅力を発掘発信するメディアをつくろうと、「ローカリティ！」創刊を決意。発行人兼編集主幹に。
          </p>
        </div>
      </section>
      <section class="about_message">
        <h2>編集長メッセージ</h2>
        <h3>誰もが主役になれる楽しい場を、一緒に創っていこう</h3>
        <p>サイトリニューアル、おめでとうございます。<br><br>
        「自分たちのメディアなのに、他人事のように『おめでとう』？」。それにはワケがあります。<br><br>
        「ローカリティ！」（愛称、ロカ！）は、仲間である書き手・ハツレポーターの皆さまとともに創り上げたものだからです。<br><br>
        なので、その皆さまに向けて「おめでとう」。めいっぱいの賛辞を贈らせてください。<br>
        今後も一緒にロカ！を進化させ、誰もが主役になれる楽しい場を創っていきましょう。
        </p>
        <h4>合同会社イーストタイムズChief Journalist<br>ローカリティ！編集長<br>森将太</h4>
        <div class="about_member_history">
          <p><span>略歴</span>1988年生、滋賀県出身<br>
          名古屋大学 教育学部卒<br><br>
          在学中に務めた新聞社の編集補助を経て、卒業後の2011年4月、テレビ局に入局。報道記者として、赴任した和歌山、福岡、京都で主に災害・科学文化・行政の取材に携わる。入局直前に起きた東日本大震災やローカルでの取材経験から、地域に根ざして暮らし、取材したいと考え、2019年に退職。同年から秋田県の自治体でコミュニティーペーパーの取材・編集を行う。県出身の作家・塩野米松さんの教えで、人の人生をまるごと聞いて、方言を含むその人の話し言葉で文章を書く「聞き書き」という手法で取材にあたる。
          </p>
        </div>
      </section>
      <section class="about_company">
        <h2>合同会社イーストタイムズ会社概要</h2>
        <a href="https://www.the-east.jp/">https://www.the-east.jp/</a>
        <div class="about_company_text">
          <p>「ローカリティ！」を運営する合同会社イーストタイムズは、2015年、東日本大震災の復興途上にあった仙台で、震災報道と地域報道を行うため、元新聞記者らが立ち上げた会社です。代表の中野宏一はYahoo!ニュース記者として地域のニュースを全国に報道するうちに、地域には魅力がないのではなく、魅力が発信されていないだけだと気づくようになりました。そこで、2016年より地域の住民らとともに、「東北ニューススクール」を開催。地域の住民が作るニュースネットワークを構築しました。地域や企業のブランディングやプロモーションを企画・立案・実施し、ファンづくりや関係性構築を行なっています。</p>
          <div class="about_company_img">
            <img src="/locality/images/about-flag.jpg" alt="会社のイメージ画像です。">
          </div>
        </div>
      </section>
    </main>

    <!-- サイドバーここから -->
    <!-- <aside class="side">
      <div class="side_reporter_img">
        <a href=""><img src="/locality/images/reporter-kv.jpg" alt="ハツレポーター募集のイメージ画像です。"></a>
      </div>
      <div class="side_search">
        <form action="">
          <input class="side_search_text" type="text" name="" id="" placeholder="検索…">
          <input class="side_search_submit" type="submit" value="検索">
        </form>
      </div>
      <div class="side_lately">
        <p>最近のハツレポ</p>
        <ul class="side_lately_title">
          <li><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「MOANA」の決意</a></li>
          <li><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></li>
          <li><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></li>
        </ul>
      </div>
      <div class="side_report">
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-philippines.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">NATURE<br>カオハガン島<br>ハツレポ</a>
          </div>
          <a href="">ローカリティ！</a>
          <h3><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「MOANA」の決意</a></h3>
          <span>カオハガンハウス<time datetime="2021-10-2">2021年10月2日</time></span>
          <p><a href="">フィリピン・カオハガン島のさわこです。 みなさん、南国の離島と聞くとどんな風景を思い浮かべますか？ きっとこんな感じではないでしょうか？？ そう！まるで絵にかいたようなこの美しいビーチがあるのが、フィリピンのカオハガ …<span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-wakayama.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">CULTURE<br>ハツレポ<br>ローカリティ！</a>
          </div>
          <a href="">和歌山</a>
          <h3><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></h3>
          <span>もとだてかづこ<time datetime="2021-9-26">2021年9月26日</time></span>
          <p><a href="">和歌山県那智勝浦町（なちかつうらちょう）。 毎年9月に、航海の安全と大漁を祈願し、勝浦八幡神社例大祭（かつうらはちまんじんじゃれいたいさい）が行われる。 祭りの日は、朝から町中がそわそわしている。 私が小学生の頃、約 …<span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-akita.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">CULTURE<br>ハツレポ<br>ローカリティ！</a>
          </div>
          <a href="">秋田</a>
          <h3><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></h3>
          <span>渡部生<time datetime="2021-9-25">2021年9月25日</time></span>
          <p><a href="">筆者の連載記事である『自分の手で作る酒。密着！ 大学生の日本酒づくり』において、秋田大学の学生とともに稲の種まきから日本酒の製造・販売までを行っている、秋田清酒株式会社の代表取締役社長、伊藤洋平（いとう・ようへい）氏 … <span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-pr.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">PR</a>
          </div>
          <h3><a href="">【オンラインイベント】旅する編集者企画！地域の人と繋がり一緒に魅力を伝えていきませんか？</a></h3>
          <span>ローカリティ！編集部<time datetime="2021-9-24">2021年9月24日</time></span>
          <p><a href="">【PR】 情報発信に課題を抱えていると言われている秋田県には、たくさんの魅力が隠れています。 皆さんの力で地元事業者さんと繋がり、「地域の魅力を編集する」ことで一緒に伝えて地域を盛り上げる１つのきっかけを作ってみませ …<span>READ MORE</span></a></p>
        </article>
      </div>
      <div class="side_archive">
        <p>アーカイブ</p>
        <ol class="side_archive_lists">
          <li><a href="">2021年10月</a></li>
          <li><a href="">2021年9月</a></li>
          <li><a href="">2021年8月</a></li>
          <li><a href="">2021年7月</a></li>
          <li><a href="">2021年6月</a></li>
          <li><a href="">2021年5月</a></li>
          <li><a href="">2021年4月</a></li>
          <li><a href="">2021年3月</a></li>
          <li><a href="">2021年2月</a></li>
          <li><a href="">2021年1月</a></li>
          <li><a href="">2020年12月</a></li>
          <li><a href="">2020年11月</a></li>
          <li><a href="">2020年10月</a></li>
        </ol>
      </div>
      <div class="side_blank"></div>
      <div class="side_category">
        <p>カテゴリー</p>
        <ul class="side_category_lists">
          <li><a href="">#私の１０年 (2)</a></li>
          <li><a href="">CULTURE (17)</a></li>
          <li><a href="">FOOD (13)</a></li>
          <li><a href="">NATURE (10)</a></li>
          <li><a href="">PR (1)</a></li>
          <li><a href="">SOCIAL (10)</a></li>
          <li><a href="">カオハガン島 (4)</a></li>
          <li><a href="">ハツレポ (134)</a></li>
          <li><a href="">ハツレポーター編集会議 (2)</a></li>
          <li><a href="">もしもし、こちらローカリティ！ (14)</a></li>
          <li><a href="">ゆく年くる年ハツレポ (12)</a></li>
          <li><a href="">ローカリティ！ (38)</a></li>
          <li><a href="">ローカリティ！桜前線 (8)</a></li>
          <li><a href="">三重 (1)</a></li>
          <li><a href="">京都 (3)</a></li>
          <li><a href="">兵庫 (1)</a></li>
          <li><a href="">千葉 (3)</a></li>
          <li><a href="">和歌山 (28)</a></li>
          <li><a href="">和歌山ローカル情報発信Lab. (13)</a></li>
          <li><a href="">埼玉 (3)</a></li>
          <li><a href="">大阪 (1)</a></li>
          <li><a href="">宮城 (10)</a></li>
          <li><a href="">宮崎 (2)</a></li>
          <li><a href="">富山 (1)</a></li>
          <li><a href="">山形 (1)</a></li>
          <li><a href="">岩手 (2)</a></li>
          <li><a href="">感謝の気持ち (8)</a></li>
          <li><a href="">新潟 (1)</a></li>
          <li><a href="">映像ハツレポ (4)</a></li>
          <li><a href="">東京 (4)</a></li>
          <li><a href="">沖縄 (2)</a></li>
          <li><a href="">海外 (1)</a></li>
          <li><a href="">神奈川 (1)</a></li>
          <li><a href="">福島 (2)</a></li>
          <li><a href="">秋田 (46)</a></li>
          <li><a href="">群馬 (1)</a></li>
          <li><a href="">長崎 (1)</a></li>
          <li><a href="">長野 (1)</a></li>
          <li><a href="">静岡 (1)</a></li>
          <li><a href="">香川 (3)</a></li>
          <li><a href="">鹿児島 (1)</a></li>
        </ul>
      </div>
    </aside> -->
    <!-- サイドバーここまで -->
  </div>

    <Footer/>
</div>
</template>

<script>
export default {
  name: 'Wanted',
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    }
  }
};
</script>
<style scoped src="../../sass/pages/style.css"></style>
