<template>
  <section :class="{ section_top: showMap == false }">
    <img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo">
    <p class="mt-4" v-if="articles.length == 0">表示する記事がありません。</p>
    <article v-for="article in articles" :key="'post-' + article.id">
      <div class="thumbnail" @click="showPostDetail(article)">
        <img :alt="article.title" :src=" article.thumbnail_path">
      </div>
      <div class="article-infomation">
        <h2 @click="showPostDetail(article)">{{ article.title }}</h2>
        <aside class="tags">
          <span class="tags2" v-for="category in article.categories" :key="category.id"
          data-toggle="tooltip" :title="'#'+category.name" @click.prevent="selectCategory(category.id)">
            #{{ category.name }}
          </span>
        </aside>
        <aside class="detail d-flex flex-nowrap text-nowrap">
          <span class="author pr-1 text-truncate">{{ article.reporter.name }}</span>
          <time>{{ article.formatted_publish_time }}</time>
          <span class="good right"><i class="fas fa-heart"></i> {{ article.likes.length }}</span>
          <span class="comment right"><i class="fas fa-comment"></i> {{ article.comments_count }}</span>
          <span class="share right"><i class="fas fa-share-alt"></i> {{ article.total_share }}</span>
        </aside>
      </div>
    </article>
    <post-detail-modal v-if="currentPopupPost"/>
  <!-- <BannerArticle/> -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Article',
  props: {
    articles: {
      type: Array
    }
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL
    };
  },
  computed: {
    ...mapGetters({
      showMap: 'PageStore/showMap',
      currentMapLevel: 'MapStore/currentMapLevel',
      currentPopupPost: 'PostStore/currentPopupPost'
    })
  },
  methods: {
    ...mapActions({
      setCurrentGeoCenter: 'MapStore/setCurrentGeoCenter',
      convertLatLngToXY: 'MapStore/convertLatLngToXY',
      setCurrentCenter: 'MapStore/setCurrentCenter',
      setPopupStatus: 'PopupStore/setPopupStatus',
      setPopupPost: 'PostStore/setPopupPost',
      clickPost: 'PostStore/clickPost',
      getPosts: 'PostStore/getPosts'
    }),
    formatTime(time) {
      const newTime = new Date(Date.parse(time));
      return newTime.getFullYear() + '年' + newTime.getMonth() + '月' + newTime.getDate() + '日';
    },
    async showPostDetail(post) {
      await this.setPopupPost(post.id);
      await this.clickPost(post.id);
      this.setPopupStatus('true');
      this.goToPostMarker(post);
    },
    async goToPostMarker(post) {
      const location = { lat: post.geo_lat, lng: post.geo_lng };
      const MapPage = this.currentMapLevel.source_type;

      if (MapPage === 'local') {
        this.setCurrentCenter(await this.convertLatLngToXY(location));
      } else if (MapPage === 'osm') {
        this.setCurrentGeoCenter(location);
      }
    },
    selectCategory(category) {
      this.getPosts({ categories: [category] });
    }
  },
  mounted() {
    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/article.css"></style>
