import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import 'bootstrap';
import VueI18n from 'vue-i18n';

import http from './http/http';
import router from './router';
import auth from './plugins/auth';

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: window.currentLocale, // set locale
  messages: window.translate // set locale messages
});

// Import Pages
const pages = require.context('./pages', true, /\.vue$/i);
pages.keys().map(key => {
  Vue.component(key.split('/').pop().split('.')[0], pages(key).default);
});
// Import Components
const components = require.context('./components', true, /\.vue$/i);
components.keys().map(key => {
  Vue.component(key.split('/').pop().split('.')[0], components(key).default);
});

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./stores', false, /.*\.js$/);

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }

    return { ...modules, [name]: module };
  }, {});

const store = new Vuex.Store({
  modules
});

Vue.use(VueCookies);

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  http: http,
  store: store,
  i18n,
  router: router,
  created() {
    Vue.use(auth, window.currentUser);
  }
});
