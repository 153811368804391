var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b_page_tt"},[_c('Header',{on:{"show-login":function($event){return _vm.showLogin()}}}),_vm._v(" "),_c('div',{staticClass:"main_side_wrapper"},[_c('main',{staticClass:"wanted_wrapper"},[_vm._m(0),_vm._v(" "),_c('br'),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("〒150-0021")]),_vm._v(" "),_c('p',[_vm._v("東京都渋谷区恵比寿西1丁目33-6")]),_vm._v(" "),_c('p',[_vm._v("co-ba ebisu 1F")]),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('validation-provider',{attrs:{"rules":{ required: true },"custom-messages":_vm.validateMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-7"},[_c('p',[_vm._v("お問い合わせの種類 "),_c('span',{staticClass:"sp_r"},[_vm._v("*")])]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.menu),expression:"formData.menu"}],staticClass:"form-control",attrs:{"name":"menu"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "menu", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("---")]),_vm._v(" "),_c('option',{attrs:{"value":"ともしびプロジェクト"}},[_vm._v("ともしびプロジェクト")]),_vm._v(" "),_c('option',{attrs:{"value":"情報提供（取材してほしいこと）"}},[_vm._v("情報提供（取材してほしいこと）")]),_vm._v(" "),_c('option',{attrs:{"value":"サイトに関するお問い合わせ"}},[_vm._v("サイトに関するお問い合わせ")]),_vm._v(" "),_c('option',{attrs:{"value":"その他"}},[_vm._v("その他")])]),_vm._v(" "),_c('span',{staticClass:"help-block error-help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{ required: true },"custom-messages":_vm.validateMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-7"},[_c('p',[_vm._v("氏名 "),_c('span',{staticClass:"sp_r"},[_vm._v("*")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"help-block error-help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{ required: true },"custom-messages":_vm.validateMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-7"},[_c('p',[_vm._v("メールアドレス"),_c('span',{staticClass:"sp_r"},[_vm._v("*")]),_vm._v(" （編集部からご連絡させていただくことがあります）")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",attrs:{"type":"email","name":"email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"help-block error-help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-7"},[_c('p',[_vm._v("電話番号")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.phone),expression:"formData.phone"}],staticClass:"form-control",attrs:{"type":"text","name":"phone"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "phone", $event.target.value)}}})])]),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{ required: true },"custom-messages":_vm.validateMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('p',[_vm._v("お問い合わせ内容 "),_c('span',{staticClass:"sp_r"},[_vm._v("*")])]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.content),expression:"formData.content"}],staticClass:"form-control",attrs:{"name":"content","rows":"10"},domProps:{"value":(_vm.formData.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "content", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"help-block error-help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"button_mail",attrs:{"type":"submit"},on:{"click":function($event){return handleSubmit(_vm.submitForm)}}},[_vm._v("送信")])]}}])})],1)]),_vm._v(" "),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baner_ct"},[_c('h2',[_vm._v("CONTACT")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("ローカリティ！編集部")])])}]

export { render, staticRenderFns }