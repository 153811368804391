<template>
    <div class="modal fade" id="post_detail_modal"
         tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true"
         data-keyboard="false"
         @click="hidePostDetail()">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body text-left" style="position: relative">
                    <button type="button" class="close mb-3" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="hidePostDetail()">&times;</span>
                    </button>
                    <div class="post-content" id="post_content">
                        <h4>{{ currentPost.title }}</h4>
                        <p></p>
                        <p class="time mb-0">{{currentPost.reporter.name}} {{currentPost.formatted_publish_time }}</p>
                        <div @click="shareCount" class="d-inline-block m-0">
                            <vue-goodshare-facebook
                                :page_url="shareUrl()"
                                title_social="Facebook"
                                button_design="gradient"
                                class="share-btn facebook-btn"
                                has_icon
                            />
                        </div>
                        <div @click="shareCount" class="d-inline-block m-0">
                            <vue-goodshare-twitter
                                :page_url="shareUrl()"
                                title_social="Twitter"
                                class="share-btn twitter-btn"
                                has_icon
                            />
                        </div>
                        <div
                            class="btn-line line-logo d-inline-block m-0"
                            @click="shareViaLine()"
                        >
                            <img :src="baseUrl + '/locality/images/line.png'">
                            Line
                        </div>
                        <div
                            class="btn-copy copy-icon d-inline-block m-0"
                            @click="copySpotUrl(currentPost.id)"
                        >
                            <i class="far fa-copy" />
                            Copy Spot Url
                        </div>
                        <input ref="spotUrl" type="text" :value="getSpotUrl(currentPost.id)" class="spotUrl">
                        <div id="box-content" class="ck-content">
                          <p v-html="currentPost.content" class="mt-3 mt-xl-0"></p>
                        </div>
                        <p class="tag_md">
                          <span v-for="category in currentPost.categories" :key="category.id" @click.prevent="selectCategory(category.id)">#{{ category.name }}</span>
                        </p>
                        <div class="reporter-info">
                            <div class="reporter-image">
                              <a :href="'/reporter/'+currentPost.reporter.id">
                                <img :src="currentPost.reporter.profile_url" :alt="currentPost.reporter.name">
                              </a>
                            </div>
                            <h4 class="reporter-name">{{currentPost.reporter.name}}
                                <reporter-follow-button :reporter="currentPost.reporter"></reporter-follow-button>
                            </h4>
                            <div class="reporter-location"
                                v-if="currentPost.reporter.reporter_information
                                && currentPost.reporter.reporter_information.prefecture
                                && currentPost.reporter.reporter_information.municipality"
                            >
                            {{currentPost.reporter.reporter_information.prefecture.name + '/' + currentPost.reporter.reporter_information.municipality.name}}
                            </div>
                            <div v-if="currentPost.reporter.reporter_information" class="reporter-bio">{{currentPost.reporter.reporter_information.bio}}</div>
                        </div>

                        <div class="detail-2">
                            <div class="social-box">
                                <span class="mr-4">
                                    <a href="#" @click="submitLike">
                                        <i v-if="!liked" class="far fa-heart"></i>
                                        <i v-if="liked" class="fas fa-heart"></i>
                                    </a>
                                    {{ currentPost.likes.length }}
                                </span>
                                <span class="mr-4"><i class="far fa-comment"></i> {{ currentPost.comments_count }}</span>
                                <span class="head-btn-wrap">
                                    <a href="#" @click="showSns = !showSns">
                                        <i class="fas fa-share-alt"></i> {{ currentShare }}
                                    </a>
                                    <div v-show="showSns" class="share-btn-wrap">
                                        <button href="#" class="btn close-btn-wrap" @click="showSns = false">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <div class="">
                                            <div @click="shareCount">
                                                <vue-goodshare-facebook
                                                    :page_url="shareUrl()"
                                                    title_social="Facebook"
                                                    button_design="gradient"
                                                    class="share-btn facebook-btn"
                                                    has_icon
                                                />
                                            </div>
                                            <div @click="shareCount">
                                                <vue-goodshare-twitter
                                                    :page_url="shareUrl()"
                                                    title_social="Twitter"
                                                    class="share-btn twitter-btn"
                                                    has_icon
                                                />
                                            </div>
                                            <div
                                                class="btn-line line-logo"
                                                @click="shareViaLine()"
                                            >
                                                <img :src="baseUrl + '/locality/images/line.png'">
                                                Line
                                            </div>
                                            <div
                                                class="btn-copy copy-icon"
                                                @click="copySpotUrl(currentPost.id)"
                                            >
                                                <i class="far fa-copy" />
                                                Copy Spot Url
                                            </div>
                                        </div>
                                        <div class="share-model-footer"></div>
                                    </div>
                                </span>
                            </div>
                            <!-- <span class="float-right follow">+ <i class="fas fa-user"></i></span> -->
                            <comment-create v-if="$auth.check()" :post="currentPost"></comment-create>
                          <comment></comment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Comment from './Comment';
import CommentCreate from './CommentCreate';
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue';
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue';
import ReporterFollowButton from '../Reporter/FollowButton';

export default {
  name: 'PostModal',
  components: {
    CommentCreate,
    Comment,
    ReporterFollowButton,
    VueGoodshareFacebook,
    VueGoodshareTwitter
  },
  data() {
    return {
      user: {},
      showSns: false,
      baseUrl: process.env.MIX_APP_URL
    };
  },
  async created() {
    this.user = await this.$auth.user();
  },
  watch: {
    popupStatus() {
      if (this.popupStatus === 'true') {
        $('#post_detail_modal').modal('show');
      }
    }
  },
  computed: {
    ...mapGetters({
      popupStatus: 'PopupStore/popupStatus',
      currentPost: 'PostStore/currentPopupPost',
      currentLike: 'PostStore/currentLike',
      currentShare: 'PostStore/currentShare'
    }),
    liked() {
      let liked = false;
      if (this.$auth.check()) {
        this.currentPost.likes.forEach((like) => {
          if (this.user.id === like.user_id) {
            liked = true;
          }
        });
      }
      return liked;
    }
  },
  methods: {
    ...mapActions({
      setPopupStatus: 'PopupStore/setPopupStatus',
      likePost: 'PostStore/likePost',
      unLikePost: 'PostStore/unLikePost',
      updateLike: 'PostStore/updateLike',
      sharePost: 'PostStore/sharePost',
      setPopupPost: 'PostStore/setPopupPost',
      getPosts: 'PostStore/getPosts'
    }),
    async hidePostDetail() {
      this.setPopupStatus('false');
    },
    async submitLike() {
      if (this.$auth.check()) {
        const postId = this.currentPost.id;
        const userId = this.user.id;
        if (this.liked === false) {
          const result = await this.likePost({
            user_id: userId,
            post_id: postId
          });

          const newLike = result.data;

          await this.updateLike({
            post: this.currentPost,
            userId,
            newLike,
            status: true
          });
        } else {
          await this.unLikePost({
            user_id: userId,
            post_id: postId
          });

          await this.updateLike({
            post: this.currentPost,
            userId,
            status: false
          });
        }
      } else {
        if (process.browser) {
          sessionStorage.setItem('spotLikeId', this.currentPost.id);
        }
        await $('#post_detail_modal').modal('hide');
        this.$router.push('/frontend/login');
      }
    },
    formatTime(time) {
      const newTime = new Date(Date.parse(time));
      return newTime.getFullYear() + '年' + newTime.getMonth() + '月' + newTime.getDate() + '日';
    },
    shareUrl() {
      try {
        return `${process.env.MIX_APP_URL}/post/${this.currentPost.id}`;
      } catch (error) {
        return error;
      }
    },
    getSpotUrl(spotId) {
      return process.env.MIX_APP_URL + '/post/' + spotId;
    },
    copySpotUrl() {
      this.$refs.spotUrl.style.display = 'unset';
      this.$refs.spotUrl.select();
      document.execCommand('copy');
      this.$refs.spotUrl.style.display = 'none';
      this.shareCount();
      alert('URLをコピーしました！');
    },
    shareViaLine() {
      this.shareCount();
      window.open(`http://line.me/R/msg/text/?Locality%0D%0A${this.shareUrl()}`);
    },
    async shareCount() {
      await this.sharePost(this.currentPost.id);
    },
    selectCategory(category) {
      $('#post_detail_modal').modal('hide');
      this.getPosts({ categories: [category] });
    }
  },
  async mounted() {
    if (this.$auth.check()) {
      if (parseInt(sessionStorage.getItem('spotLikeId'))) {
        await this.setPopupPost(parseInt(sessionStorage.getItem('spotLikeId')));
        await this.submitLike();
        sessionStorage.removeItem('spotLikeId');
      }
    };

    $('#post_detail_modal').on('hide.bs.modal', event => {
      $('#post_content').scrollTop(0);
      this.showSns = false;
    });
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../sass/compoments/post/post_detail_modal.css"></style>
