<template>
  <div>
  <nav :class="{ nav_top: showMap == false }">
    <ul>
      <li v-bind:class="{ active: currentPage == 'post_topic' }">
          <a href="#" @click="changePage('post_topic')"><img alt="topic" :src="baseUrl + '/locality/images/ic_light.png'"> <span>トピックス</span></a>
      </li> <!-- Topics -->
      <li v-bind:class="{ active: currentPage == 'post_new' }">
          <a href="#" @click="changePage('post_new')"><img alt="post new" :src="baseUrl + '/locality/images/ic_new.png'"> <span>新着</span></a>
      </li> <!-- News and Ranking -->
      <li v-bind:class="{ active: currentPage == 'my_location' }">
          <a href="#" @click="changePage('my_location')"><img alt="my loca" :src="baseUrl + '/locality/images/ic_li.png'"> <span>マイロカ</span></a>
      </li> <!-- MyLoca -->
      <li v-bind:class="{ active: currentPage == 'current_location' }">
        <a href="#" @click="changePage('current_location')"><img alt="location" :src="baseUrl + '/locality/images/ic_location.png'"> <span>GPS</span></a>
      </li> <!-- GPS -->
      <li v-bind:class="{ active: currentPage == 5 }">
        <a href="#" @click.prevent="setSelectPopup(true)"><img alt="category" :src="baseUrl + '/locality/images/ic_category.png'"> <span>カテゴリ</span></a>
      </li> <!-- Categories -->
      <li @click="changeNavBar(!showMap)"><img v-if="showMap" alt="lists" :src="baseUrl + '/locality/images/ic_more.png'"><img v-else alt="lists" :src="baseUrl + '/locality/images/ic_more_2.png'"></li> <!-- Lists or Map -->
    </ul>
  </nav>
<!--    <button class="bt_ranking" :class="{active:postType== 'ranking'}" v-if="(currentPage == 'post_new' || currentPage == 'post_ranking')"-->
<!--            v-show="showMap"-->
<!--            type="button" @click="switchPostType('ranking')">-->
<!--      <img :src="baseUrl + '/locality/images/crown.png'" alt="ranking">-->
<!--    </button>-->

      <div class="modal fade" id="show_developing_modal"
           tabindex="-1" role="dialog"
           aria-labelledby="exampleModalLabel" aria-hidden="true"
           data-keyboard="false">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
              <div class="modal-content">
                  <h3>ロカミン設定</h3>
                  <p>この機能を利用するにはログインする必要があります。<br>
                  ログイン画面へ移動しますか？</p>
                  <div class="text-center pt-2">
                      <button type="button" class="btn btn-secondary bt-login" data-dismiss="modal" @click="redirectLogin()">移動する</button>
                      <button type="button" class="btn btn-secondary bt-close" data-dismiss="modal">残る</button>
                  </div>
              </div>
          </div>
      </div>

      <!-- <div class="modal fade" id="show_developing_modal"
             tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true"
             data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    あなたも「ロカミン」になって、自分だけのローカル情報プラットフォームを作ろう！
                    <br>ユーザーの皆さん＝「ロカミン」が、ローカリティ！上に自分の「好き！」を集めて、心のふるさと＝「マイ・ローカル（マイロカ）」をカスタマイズできる機能を実装予定！
                    <br>①あなたが「好き！」なトピックスをフォローして、自分だけのオリジナルローカル情報マップを作ろう！
                    <br>②記事に「いいね」して、お気に入りをコレクションしよう！
                    <br>③記事にコメントして、あなたの地元愛を仲間に伝えよう！
                    <br>近日中実装予定
                    <br>coming soon...
                    <div class="text-center pt-2">
                        <button type="button" class="btn btn-secondary show-developing-button" data-dismiss="modal">閉じる</button>
                    </div>
                </div>
            </div>
        </div> -->

      <setting-subcribe-modal></setting-subcribe-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SettingSubcribeModal from './user/SettingSubcribeModal';

export default {
  name: 'AppNavBar',
  components: { SettingSubcribeModal },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      postType: null
    };
  },
  computed: {
    ...mapGetters({
      currentPage: 'PageStore/currentPage',
      currentMap: 'MapStore/currentMap',
      showMap: 'PageStore/showMap'
    })
  },
  methods: {
    ...mapActions({
      changePostType: 'PostStore/changePostType',
      setCurrentPage: 'PageStore/setCurrentPage',
      changeShowMap: 'PageStore/changeShowMap',
      setSelectPopup: 'CategoryStore/setSelectPopup',
      setCurrentCenter: 'MapStore/setCurrentCenter',
      setCurrentLocation: 'MapStore/setCurrentLocation',
      setCurrentGeoLocation: 'MapStore/setCurrentGeoLocation',
      setCurrentGeoCenter: 'MapStore/setCurrentGeoCenter',
      getGPSLocation: 'MapStore/getGPSLocation',
      convertLatLngToXY: 'MapStore/convertLatLngToXY',
      setLoadingMap: 'MapStore/setLoadingMap',
      setMyloca: 'SubcribeStore/showHideSetting',
      getSubcribeSetting: 'SubcribeStore/getSubcribeSetting',
      checkSubcribeSetting: 'SubcribeStore/checkSubcribeSetting',
      getPosts: 'PostStore/getPosts'
    }),
    async changePage(page) {
      this.setLoadingMap(true);
      switch (page) {
      case 'post_topic':
        this.switchPostType('topic');
        break;
      case 'post_new':
        this.switchPostType('new');
        break;
      case 'my_location':
        if (this.$auth.check()) {
          const userId = this.$auth.user().id;
          await this.getSubcribeSetting(userId);
          if (await this.checkSubcribeSetting()) {
            this.postType = 'my_location';
            await this.getPosts({
              type: 'myloca',
              user_id: userId
            });
          } else {
            this.setMyloca();
          }
        } else {
          $('#show_developing_modal').modal('show');
        }
        break;
      case 'current_location':
        await this.gotoCurrentLocation();
        this.switchPostType('new_all');
        break;
      }
      this.setCurrentPage(page);
      this.setLoadingMap(false);
    },
    changeNavBar(show) {
      this.changeShowMap(show);
    },
    switchPostType(type) {
      if (this.postType !== type || type === 'new_all') {
        this.postType = type;
        this.changePostType(this.postType);
      }
    },
    async gotoCurrentLocation() {
      let currentLocation = await this.getGPSLocation();

      const geoCurrent = currentLocation;

      if (currentLocation) {
        this.setLoadingMap(true);

        currentLocation = await this.convertLatLngToXY(currentLocation);
        const currentLat = currentLocation.lat;
        const currentLng = currentLocation.lng;

        if (currentLat >= this.currentMap.max_bounds_br_lat &&
            currentLng <= this.currentMap.max_bounds_br_lng &&
            currentLat <= 0 && currentLng >= 0
        ) {
          this.setCurrentCenter(currentLocation);
          this.setCurrentLocation(currentLocation);

          this.setCurrentGeoCenter(geoCurrent);
          this.setCurrentGeoLocation(geoCurrent);
        } else {
          alert('現在地が地図の範囲外です。');
        }

        this.setLoadingMap(false);
      }
    },
    showDevelopingModal() {
      $('#show_developing_modal').modal('show');
    },
    redirectLogin() {
      this.$router.push('/frontend/login');
    }
  },
  beforeMount() {
    if (this.$route.query.page === 'myloca') {
      this.changePage('my_location');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/nar_bar.css"></style>
<style scoped>
.modal-content {
    text-align: left;
    padding: 10px;
}
/* .modal-content {
    text-align: left;
    padding: 10%;
} */

.show-developing-button {
    color: #ffffff;
    background-color: #021B6A;
    border-color: #021B6A;
    box-shadow: none;
}
.modal-content h3 {
    background: #021B6A;
    color: #fff;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 30px;
}

.modal-content p {
    color: #021B6A;
    margin-bottom: 20px;
    font-weight: bold;
}

.modal-content .bt-login {
    background: #021B6A;
    color: #fff;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 100%;
    line-height: 30px;
    font-weight: bold;
    font-size: 1rem;
}

.modal-content .bt-close {
    color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 60%;
    line-height: 15px;
    background: #6d6e6ec2;
    border: none;
}

@media (min-width: 1200px){
  .modal-content {
    margin-bottom: 160px;
  }
  .modal-dialog {
    max-width: 400px;
  }
}
</style>
