<template>
<div class="b_page_tt">
    /* eslint-disable no-irregular-whitespace */

    <Header @show-login="showLogin()"/>

  <main class="terms_wrapper">
    <h1>利用規約</h1>
    <p>本利用規約（以下「本規約」といいます。）は、合同会社イーストタイムズ（以下「当社」といいます。）の提供するサービスである「ローカリティ！」（以下「本サービス」といいます。）のご利用にあたり、ユーザーの皆様に遵守していただく事項および当社とユーザーの皆様との間の権利義務関係が定められています。ユーザーの皆様は、本サービス利用開始前に、必ず全文お読み下さいますようお願いいたします。</p>
    <ol class="terms_lists">
      <li class="terms_list_item">
        <h2>第1条 適 用</h2>
        <ol class="terms_second_lists">
          <li>１.  本規約は、本サービスの利用に関し、当社とユーザー（第2条に定義）との間の権利義務関係を定めることを目的とします。</li>
          <li>２.  本規約は、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。</li>
          <li>３.  当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成します。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第2条 定 義</h2>
        <p>本規約において使用する以下の用語はそれぞれ以下に定める意味を有します。</p>
        <ol class="terms_second_lists">
          <li>１.  「当社ウェブサイト」とは、そのドメインが「thelocality.net」である当社が運営するウェブサイト（当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</li>
          <li>２.  「ローカリティ！」とは、当社が提供するローカリティ！という名称のサービス（サービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</li>
          <li>３.  「本サービス」とは、ローカリティ！を意味します。</li>
          <li>４.  「ユーザー」とは、本サービスの利用者を意味します。</li>
          <li>５.  「投稿情報」とは、本サービスにおいてユーザーが投稿または送信した一切の情報を意味します。</li>
          <li>６.  「登録ユーザー」とは、ユーザーのうち第3条に基づきローカリティ！の利用者としての登録がなされた個人または法人を意味します。</li>
          <li>７.  「登録希望者」とは、ローカリティ！の登録ユーザーとなることを希望する者を意味します。</li>
          <li>８.  「登録情報」とは、当社が登録希望者が登録ユーザーとして登録する際に提出を求める一定の情報を意味します。</li>
          <li>９.  「利用契約」とは、第3条第3項に基づき当社と登録ユーザーの間で成立する、本規約の諸規定に従ったローカリティ！の利用契約を意味します。</li>
          <li>１０. 「外部SNSサービス」とは、Facebook、twitterその他の他の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、登録ユーザーの認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開等の機能を持ち、本サービスの実施に利用されるサービスを意味します。</li>
          <li>１１. 「外部SNS事業者」とは、外部SNSサービスのサービス提供者を意味します。</li>
          <li>１２. 「外部SNS利用規約」とは、登録ユーザーと外部SNS事業者との権利関係を定める規約を意味します。</li>
          <li>１３. 「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得しまたはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第3条 登 録</h2>
        <ol class="terms_second_lists">
          <li>１.  登録希望者は、本規約を遵守することに同意し、登録情報を当社の定める方法で当社に提供することにより、当社に対し、登録ユーザーとしてのローカリティ！の利用の登録を申請することができます。</li>
          <li>２.  当社は、当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合には、登録希望者に対し、登録ユーザーとしてローカリティ！の利用を開始させます。登録希望者が、登録ユーザーとしてローカリティ！の利用を開始した時をもって、登録希望者の登録ユーザーとしての登録は完了したものとします。</li>
          <li>３.  前項に定める登録の完了時に、登録ユーザーを対象とする本規約の諸規定に従ったローカリティ！の利用契約が登録ユーザーと当社の間に成立し、登録ユーザーはローカリティ！を当社の定める方法で利用することができるようになります。</li>
          <li>
            ４.  当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
            <ol class="terms_third_lists">
              <li>a. 当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
              <li>b. 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人､保佐人または補助人の同意等を得ていなかった場合</li>
              <li>c. 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）であるまたは資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</li>
              <li>d. その他、当社が登録を適当でないと判断した場合</li>
            </ol>
          </li>
          <li>５.  本条第1項から第4項までの規定に係わらず、登録希望者が外部SNSサービスにおけるID等を利用して、本規約を遵守することに同意した上で、当社が定める登録手続を行うことで、ローカリティ！の登録ユーザーとなることができる場合があります。この場合は、当社が定める登録手続の完了時点で、本条第3項に定める利用契約が登録ユーザーと当社の間で成立するものとします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第4条 登録情報の変更</h2>
        <p>登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第5条 パスワードおよびユーザーIDの管理</h2>
        <ol class="terms_second_lists">
          <li>１.  登録ユーザーは、自己の責任において、パスワードおよびユーザーIDを管理および保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
          <li>２.  パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。</li>
          <li>３.  登録ユーザーは、パスワードまたはユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第6条 外部SNSサービス</h2>
        <ol class="terms_second_lists">
          <li>１.  当社は、外部SNSサービスと連携してローカリティ！を提供することがありますが、登録ユーザーは、自らの責任において外部SNSサービスを利用するものとし、当社は、外部SNSサービスの利用に関連して登録ユーザーに発生した損害について、一切の責任を負いません。</li>
          <li>２.  登録ユーザーは、外部SNSサービスの利用に当たっては、外部SNS利用規約を遵守するものとします。</li>
          <li>３.  登録ユーザーは、外部SNSサービスに登録された登録ユーザーのプロフィール情報(外部SNSサービスにおけるユーザー名、メールアドレスおよびプロフィール画像を意味します。)を当社が取得し、本サービスにおいて表示、公開をすること、および登録ユーザーが許可した場合には、投稿情報が、外部SNSサービスに投稿されることを予め承認するものとします。また、登録ユーザーは、他のユーザーにより、自らの投稿情報が、外部SNSサービスに投稿されることがあることを予め承認するものとします。当社は、外部SNSサービスに投稿された投稿情報について、削除等を行う義務を負わないものとします。</li>
          <li>４.  登録ユーザーが第3条第5項に従い外部SNSサービスのID等を利用してローカリティ！に登録した場合、そのID等の登録、利用については、外部SNSサービスが規定する各規約の定めに従うものとし、当社はその内容について一切の責任を負うものではありません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第7条 広告</h2>
        <p>当社は、登録ユーザーが送受信したメッセージ、投稿情報その他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社は本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</p>
      </li>
      <li class="terms_list_item">
        <h2>第8条 情報の保存</h2>
        <p>当社は、登録ユーザーが送受信したメッセージ、投稿情報その他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社は本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</p>
      </li>
      <li class="terms_list_item">
        <h2>第9条 投稿情報</h2>
        <ol class="terms_second_lists">
          <li>１.  ユーザーは、投稿情報について、自らが投稿または送信することについての適法な権利を有していることおよび投稿情報が第三者の権利を侵害しないことについて、表明し、保証するものとします。</li>
          <li>２.  ユーザーは、当社に対し、投稿情報について、無償にて利用（複製、複写、改変、第三者への再許諾、投稿情報を用いた二次的著作物または編集著作物の作成、当該二次的著作物についての一切の利用および第三者への再許諾その他のあらゆる利用を含む。）する権利を許諾するものとします。本項に基づく、ユーザーの当社への利用許諾の範囲には、ローカリティ！を通じて、当社が他のユーザーに対して投稿情報の利用を再許諾することも含むものとし、これにより、ユーザーは、ローカリティ！上で他のユーザーが投稿した投稿情報を、当社の定める方法で利用することができるようになります。またユーザーは、本項に基づく登録情報の利用について、著作者人格権（公表権、氏名表示権、同一性保持権を意味します。）を行使しないことにつき、同意するものとします。</li>
          <li>３.  前項の利用許諾および同意には、地域制限、著作権表示義務その他付随条件はないものとし、ユーザーの当社に対する利用許諾および同意は投稿情報の知的財産権が存続する限り継続するものとします。</li>
          <li>４.  当社または当社から委託を受けた第三者が、投稿情報を当社または第三者のウェブサイト等において利用および公開する場合があります。この場合、当社は、前項に基づき、投稿情報について、要約、抜粋、サイズ変更または切り抜き等の改変等の行為を行う場合があります。また、ユーザーは、当社が投稿情報を利用する場合には、ユーザーが投稿または送信した際のユーザーIDその他の登録事項を表示する場合があることを予め了承するものとします。</li>
          <li>５.  当社は、本規約に基づく投稿情報の利用を、本規約または個別の同意において明示的に許諾したものを除き、投稿を行ったユーザー自身を除く、他のユーザーおよびその他の第三者に対して許諾するものではなく、ユーザーおよびその他の第三者は、他のユーザーの投稿情報に関する権利を侵害する行為を行ってはならず、他のユーザーの投稿情報の複写や公衆送信等の利用を希望する場合には著作権法等の例外規定による他当社および投稿を行ったユーザーの許諾を得る必要があります。</li>
          <li>６.  当社、ユーザーその他の第三者が投稿情報を利用したことによってユーザーに生じた損害については、当社では一切の保証をいたしません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第10条 本サービスの利用</h2>
        <ol class="terms_second_lists">
          <li>１.  ユーザーは、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。</li>
          <li>２.  本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持は、ユーザーの費用と責任において行うものとします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第11条 禁止行為</h2>
        <ol class="terms_second_lists">
          <li>
            １.  ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
            <ol class="terms_third_lists">
              <li>a. 当社、または本サービスの他の利用者、外部SNS事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為（かかる侵害を直接または間接に惹起する行為を含みます。）</li>
              <li>b. 犯罪行為に関連する行為または公序良俗に反する行為</li>
              <li>c. 面識のない異性との出会いや交際に関する情報を送信する行為</li>
              <li>d. 事実に反する情報または事実に反するおそれのある情報を送信する行為およびローカリティ！の登録および利用にあたり虚偽の情報を提供する行為</li>
              <li>e. 他のユーザーの個人情報を収集、利用する行為または聞き出す行為</li>
              <li>f. 他のユーザーに対する精神的被害・経済的被害を与える行為</li>
              <li>g. 本人の承諾のなく個人情報を送信する行為</li>
              <li>h. アダルト画像、動画を含む情報およびそれを示唆する情報を送信する行為</li>
              <li>i. アダルトサイト、出会い系サイト関連の表現・内容またはこれらのサイト等へのリンクを送信する行為</li>
              <li>j. 宗教活動または宗教団体への勧誘行為</li>
              <li>k. 反社会的勢力に対する利益供与その他の協力行為</li>
              <li>l. 当社、本サービスの他の利用者、その他本サービスに関連する人物もしくは団体等を装い、身分を偽る行為、またはそのおそれがある行為</li>
              <li>m. 本サービスの信用を損なう行為および趣旨に反する行為、またはそのおそれのある行為</li>
              <li>n. 本サービスを利用した営利行為、またはその準備行為</li>
              <li>o. 当社が定めるデータ容量以上のデータを本サービスを通じて送信する行為</li>
              <li>p. いわゆる「さくら」的な行為等、故意に商品やサービスを優良と誤認させるような内容を投稿または送信する行為</li>
              <li>q. 競合する商品やサービスの信頼を低下させる目的で中傷する内容を投稿または送信する行為</li>
              <li>r. 他者になりすまして本サービスを利用する行為</li>
              <li>s. コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</li>
              <li>t. スクレイピング、クローリング（クローラ、ロボットおよびスパイダー等のプログラム）ならびにその他の類似の手段によって本サービスにアクセスし、本サービスに関する情報を取得する行為</li>
              <li>u. 本サービスに含まれるソフトウェア情報および著作物について、リバースエンジニアリング、逆コンパイル、または逆アセンブルする行為</li>
              <li>v. 当社による本サービスの運営を妨害するおそれのある行為</li>
              <li>w. その他、当社が不適切と判断する行為</li>
            </ol>
          </li>
          <li>２.  当社は、ローカリティ！におけるユーザーによる投稿情報その他の情報の送信行為が前項各号のいずれかに該当しまたは該当するおそれがあると当社が判断した場合には、ユーザーに事前に通知することなく、当該投稿情報その他の情報の全部または一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第12条 本サービスの停止等</h2>
        <ol class="terms_second_lists">
          <li>
            １.  当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部または一部を停止または中断することができるものとします。
            <ol class="terms_third_lists">
              <li>a. 本サービスに係るコンピューター・システムの点検または保守作業を定期的または緊急に行う場合</li>
              <li>b. コンピューター、通信回線等が事故により停止した場合</li>
              <li>c. 火災、停電、天災地変等の不可抗力により本サービスの運営ができなくなった場合</li>
              <li>d. 外部SNSサービスに、トラブル、サービス提供の中断または停止、本サービスとの連携の停止、仕様変更等が生じた場合</li>
              <li>e. その他、当社が停止または中断を必要と判断した場合</li>
            </ol>
          </li>
          <li>２.  当社は、当社の都合により、ユーザーに事前に通知することなく、本サービスの提供を終了することができます。ただし、当社は、登録ユーザーには、本サービスの提供の終了について、事前に通知するものとします。</li>
          <li>３.  当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第13条 ダウンロード等についての注意事項</h2>
        <p>ユーザーは、本サービスの利用開始に際しまたは本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等をユーザーのコンピューター等にインストールする場合には、ユーザーが保有する情報の消滅もしくは改変または機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はユーザーに発生したかかる損害について一切責任を負わないものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第14条 権利帰属</h2>
        <p>当社ウェブサイトおよび本サービスに関する所有権および知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、当社は、本規約に基づき、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用を許諾するものではありません。</p>
      </li>
      <li class="terms_list_item">
        <h2>第15条 登録取消等</h2>
        <ol class="terms_second_lists">
          <li>
            １.  当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、当該登録ユーザーについてローカリティ！の利用を一時的に停止し、または登録ユーザーとしての登録を取り消すことができます。これに加え、当社は、当該登録ユーザーの投稿情報の全部または一部を削除することができます。
            <ol class="terms_third_lists">
              <li>a. 本規約のいずれかの条項に違反した場合</li>
              <li>b. 登録情報に虚偽の事実があることが判明した場合</li>
              <li>c. 第3条第4項各号に該当する場合</li>
              <li>d. その他、当社が登録ユーザーとしての登録の継続を適当でないと判断した場合</li>
            </ol>
          </li>
          <li>２.  前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</li>
          <li>３.  当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。</li>
          <li>4.  登録ユーザーは、30日前までに当社所定の方法で当社に通知することにより、自己の登録ユーザーとしての登録を取り消すことができます。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第16条 保証の否認および免責</h2>
        <ol class="terms_second_lists">
          <li>１.  当社は、本サービスにつき如何なる保証も行うものではありません。さらに、ユーザーが当社から直接または間接に本サービスまたは他の登録ユーザーに関する情報を得た場合であっても、当社はユーザーに対し如何なる保証も行うものではありません。</li>
          <li>２.  ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。</li>
          <li>３.  本サービスまたは当社ウェブサイトに関連してユーザーと他のユーザー、外部SNS事業者その他の第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理および解決するものとし、当社はかかる事項について一切責任を負いません。</li>
          <li>４.  当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、登録ユーザーのメッセージまたは情報の削除または消失､登録ユーザーの登録の取消、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連してユーザーが被った損害につき、賠償する責任を一切負わないものとします。</li>
          <li>５.  本サービスから当社ウェブサイト以外の他のウェブサイトへのリンクまたは他のウェブサイトから本サービスへのリンクが提供されている場合でも、当社は、当該他のウェブサイトおよびそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第17条 紛争処理および損害賠償</h2>
        <ol class="terms_second_lists">
          <li>１.  ユーザーは、本規約に違反することにより、または本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。</li>
          <li>２.  当社は、本サービスに関連してユーザーが被った損害について、当社に故意または重大な過失のある場合をのぞき、一切賠償の責任を負いません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第18条 秘密保持</h2>
        <ol class="terms_second_lists">
          <li>
            １.  本規約において「秘密情報」とは、利用契約または本サービスに関連して、ユーザーが、当社より書面、口頭もしくは記録媒体等により提供もしくは開示されたか、または知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。ただし、以下各号に規定するものについては、秘密情報から除外するものとします。
            <ol class="terms_third_lists">
              <li>(1)当社から提供もしくは開示がなされた時または知得した時に、既に一般に公知となっていた、または既に知得していたもの</li>
              <li>(2)当社から提供もしくは開示または知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの</li>
              <li>(3)提供または開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの</li>
              <li>(4)秘密情報によることなく単独で開発したもの</li>
              <li>(5)当社から秘密保持の必要なき旨書面で確認されたもの</li>
            </ol>
          </li>
          <li>２.  ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示または漏洩しないものとします。</li>
          <li>３.  第2項の定めにかかわらず、ユーザーは、法律、裁判所または政府機関の命令、要求または要請に基づき、秘密情報を開示することができます。ただし、当該命令、要求または要請があった場合、速やかにその旨を当社に通知しなければなりません。</li>
          <li>４.  ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報ならびに秘密情報を記載または包含した書面その他の記録媒体物およびその全ての複製物を返却または廃棄しなければなりません。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第19条 利用契約の有効期間</h2>
        <p>利用契約は、ローカリティ！の提供期間中、登録ユーザーについて第3条に基づく登録が完了した日から当該登録ユーザーの登録が取り消された日まで、当社と登録ユーザーとの間で有効に存続するものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第20条 本規約等の変更</h2>
        <ol class="terms_second_lists">
          <li>１.  当社は、本サービスの内容を自由に変更できるものとします。</li>
          <li>２.  当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本条において同じ。）を変更できるものとします。当該変更後、ユーザーが本サービスを利用した場合または当社の定める期間内に登録取消の手続をとらなかった場合には、ユーザーは、本規約の変更に同意したものとみなします。</li>
          <li>３.  当社は、本サービス内または当社ウェブサイト等の適宜の場所へ掲示することにより、いつでも本規約を変更できるものとします。変更後の本規約は、変更後の本規約とともに掲示された効力発生日からその効力を生じるものとし、ユーザーは本規約の変更後も本サービスを使い続けることにより、変更後の本規約に同意をしたものとみなします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第21条 連絡又は通知</h2>
        <p>本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、および本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第22条 地位の譲渡等</h2>
        <ol class="terms_second_lists">
          <li>１.  ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
          <li>２.  当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務ならびに登録ユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
        </ol>
      </li>
      <li class="terms_list_item">
        <h2>第23条 分離可能性</h2>
        <p>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社およびユーザーは、当該無効もしくは執行不能の条項または部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効もしくは執行不能な条項または部分の趣旨ならびに法律的および経済的に同等の効果を確保できるように努めるものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第24条 存続規定</h2>
        <p>第5条（パスワード及びユーザーIDの管理）第2項、第6条（外部SNSサービス）第1項および第4項、第8条（情報の保存）、第9条（投稿情報）、第11条（禁止行為）第2項、第12条（本サービスの停止等）第3項、第13条（ダウンロード等についての注意事項）、第14条（権利帰属）、第15条（登録取消等）第2項および第3項、第16条（保証の否認及び免責）、第17条（紛争処理及び損害賠償）、第18条（秘密保持）、第22条（本規約の譲渡等）、第23条（分離可能性）、本条ならびに第25条（準拠法及び管轄裁判所）の規定は利用契約の終了後も有効に存続するものとします。ただし、第18条（秘密保持）については、利用契約終了後1年間に限り存続するものとします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第25条 準拠法および管轄裁判所</h2>
        <p>本規約の準拠法は日本法とし、本規約に起因しまたは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      </li>
      <li class="terms_list_item">
        <h2>第26条 協議解決</h2>
        <p>当社およびユーザーは、本規約に定めのない事項または本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従い、協議の上速やかに解決を図るものとします。</p>
      </li>
    </ol>
    <p class="terms_end">以上</p>
  </main>

    <Footer/>
</div>
</template>

<script>
export default {
  name: 'Wanted',
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    }
  }
};
</script>
<style scoped src="../../sass/pages/style.css"></style>
