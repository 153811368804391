var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('div',{staticClass:"top_pro row m-0"},[_c('div',{staticClass:"col-3 text-left"},[_c('router-link',{attrs:{"to":'/reporter/'+_vm.$auth.user().id}},[_vm._v("キャンセル")])],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"title"},[_vm._v("プロフィールを編集")])]),_vm._v(" "),_c('div',{staticClass:"col-3 text-right"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"submit"}},[_vm._v("完了")])])]),_vm._v(" "),_c('div',{attrs:{"id":"form_profile"}},[_c('div',{staticClass:"text-center b_avatar"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageDataPreview,"alt":"avatar"}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('input',{staticClass:"d-none",attrs:{"type":"file"}}),_vm._v(" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sh),expression:"!sh"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showIconCrop = !_vm.showIconCrop}}},[_vm._v("プロフィール写真を変更")]),_vm._v(" "),(_vm.fileSizeErr)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("最大ファイルサイズは2MBです。ファイルサイズを確認してください")]):_vm._e(),_vm._v(" "),(_vm.fileTypeErr)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("ドキュメントは有効なファイル形式ではありません")]):_vm._e(),_vm._v(" "),_c('crop-upload',{attrs:{"lang-ext":{btn: {off: 'キャンセル', close: '閉じる', back: '他の画像を選択', save: '保存'},
                    hint: 'ここにファイルをドロップまたはファイルを選択'},"no-square":true,"width":300,"height":300,"field":"profile_image","img-format":"png"},on:{"src-file-set":_vm.srcFileSet,"crop-success":_vm.cropIconSuccess},model:{value:(_vm.showIconCrop),callback:function ($$v) {_vm.showIconCrop=$$v},expression:"showIconCrop"}})],1),_vm._v(" "),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("名前")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('validation-provider',{attrs:{"rules":_vm.processedValidateRules.name,"custom-messages":_vm.validateMessages.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],attrs:{"type":"text"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("自己紹介")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.reporter_information.bio),expression:"user.reporter_information.bio"}],domProps:{"value":(_vm.user.reporter_information.bio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.reporter_information, "bio", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("場所")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('validation-provider',{attrs:{"rules":_vm.processedValidateRules.prefecture,"custom-messages":_vm.validateMessages.prefecture},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.reporter_information.prefecture_id),expression:"user.reporter_information.prefecture_id"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user.reporter_information, "prefecture_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeSelectLocation]}},[_c('option',{domProps:{"value":null}},[_vm._v("都道府県を選択してください ")]),_vm._v(" "),_vm._l((_vm.locationSelectData.prefectures),function(prefecture){return _c('option',{key:prefecture.id,domProps:{"value":prefecture.id}},[_vm._v("\n                                "+_vm._s(prefecture.name)+"\n                            ")])})],2),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"rules":_vm.processedValidateRules.municipality,"custom-messages":_vm.validateMessages.municipality},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.reporter_information.municipality_id),expression:"user.reporter_information.municipality_id"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user.reporter_information, "municipality_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("市区町村を選択してください ")]),_vm._v(" "),_vm._l((_vm.locationSelectData.municipalities),function(municipality){return _c('option',{key:municipality.id,domProps:{"value":municipality.id}},[_vm._v("\n                                "+_vm._s(municipality.name)+"\n                            ")])})],2),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("メールアドレス")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],attrs:{"type":"text","name":"email","readonly":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}})])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }