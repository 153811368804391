<template>
<div class="b_page_tt">
    <Header @show-login="showLogin()"/>
    <div class="main_side_wrapper">
    <main class="wanted_wrapper">
      <div class="baner_ct">
        <h2>CONTACT</h2>
      </div>
      <br>
      <p><strong>ローカリティ！編集部</strong></p>
      <br>
      <p>〒150-0021</p>
      <p>東京都渋谷区恵比寿西1丁目33-6</p>
      <p>co-ba ebisu 1F</p>
      <br><br>

<validation-observer ref="observer" v-slot="{ handleSubmit }">

    <validation-provider
        :rules="{ required: true }"
        :custom-messages="validateMessages"
        v-slot="{ errors }">
          <div class="row mb-3">
            <div class="col-md-7">
              <p>お問い合わせの種類 <span class="sp_r">*</span></p>
    <select class="form-control" name="menu" v-model="formData.menu">
      <option value="">---</option>
      <option value="ともしびプロジェクト">ともしびプロジェクト</option>
      <option value="情報提供（取材してほしいこと）">情報提供（取材してほしいこと）</option>
      <option value="サイトに関するお問い合わせ">サイトに関するお問い合わせ</option>
      <option value="その他">その他</option>
    </select>
              <span class="help-block error-help-block">{{ errors[0] }}</span>
            </div>
        </div>
    </validation-provider>

    <validation-provider
        :rules="{ required: true }"
        :custom-messages="validateMessages"
        v-slot="{ errors }">
          <div class="row mb-3">
            <div class="col-md-7">
              <p>氏名 <span class="sp_r">*</span></p>
              <input type="text"
                v-model="formData.name"
                name="name"
                class="form-control">
              <span class="help-block error-help-block">{{ errors[0] }}</span>
            </div>
        </div>
    </validation-provider>

    <validation-provider
        :rules="{ required: true }"
        :custom-messages="validateMessages"
        v-slot="{ errors }">
          <div class="row mb-3">
            <div class="col-md-7">
              <p>メールアドレス<span class="sp_r">*</span> （編集部からご連絡させていただくことがあります）</p>
              <input type="email"
                v-model="formData.email"
                name="email"
                class="form-control">
              <span class="help-block error-help-block">{{ errors[0] }}</span>
            </div>
        </div>
    </validation-provider>

<div class="row mb-3">
  <div class="col-md-7">
    <p>電話番号</p>
    <input type="text" class="form-control" name="phone" v-model="formData.phone">
  </div>
</div>

    <validation-provider
        :rules="{ required: true }"
        :custom-messages="validateMessages"
        v-slot="{ errors }">
          <div class="row mb-3">
            <div class="col-12">
              <p>お問い合わせ内容 <span class="sp_r">*</span></p>
              <textarea name="content" rows="10" class="form-control" v-model="formData.content"></textarea>
              <span class="help-block error-help-block">{{ errors[0] }}</span>
            </div>
        </div>
    </validation-provider>

<button type="submit" class="button_mail" @click="handleSubmit(submitForm)">送信</button>
</validation-observer>

    </main>

    <!-- <aside class="side">
      <div class="side_reporter_img">
        <a href=""><img src="/locality/images/reporter-kv.jpg" alt="ハツレポーター募集のイメージ画像です。"></a>
      </div>
      <div class="side_search">
        <form action="">
          <input class="side_search_text" type="text" name="" id="" placeholder="検索…">
          <input class="side_search_submit" type="submit" value="検索">
        </form>
      </div>
      <div class="side_lately">
        <p>最近のハツレポ</p>
        <ul class="side_lately_title">
          <li><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「MOANA」の決意</a></li>
          <li><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></li>
          <li><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></li>
        </ul>
      </div>
      <div class="side_report">
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-philippines.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">NATURE<br>カオハガン島<br>ハツレポ</a>
          </div>
          <a href="">ローカリティ！</a>
          <h3><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「MOANA」の決意</a></h3>
          <span>カオハガンハウス<time datetime="2021-10-2">2021年10月2日</time></span>
          <p><a href="">フィリピン・カオハガン島のさわこです。 みなさん、南国の離島と聞くとどんな風景を思い浮かべますか？ きっとこんな感じではないでしょうか？？ そう！まるで絵にかいたようなこの美しいビーチがあるのが、フィリピンのカオハガ …<span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-wakayama.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">CULTURE<br>ハツレポ<br>ローカリティ！</a>
          </div>
          <a href="">和歌山</a>
          <h3><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></h3>
          <span>もとだてかづこ<time datetime="2021-9-26">2021年9月26日</time></span>
          <p><a href="">和歌山県那智勝浦町（なちかつうらちょう）。 毎年9月に、航海の安全と大漁を祈願し、勝浦八幡神社例大祭（かつうらはちまんじんじゃれいたいさい）が行われる。 祭りの日は、朝から町中がそわそわしている。 私が小学生の頃、約 …<span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-akita.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">CULTURE<br>ハツレポ<br>ローカリティ！</a>
          </div>
          <a href="">秋田</a>
          <h3><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></h3>
          <span>渡部生<time datetime="2021-9-25">2021年9月25日</time></span>
          <p><a href="">筆者の連載記事である『自分の手で作る酒。密着！ 大学生の日本酒づくり』において、秋田大学の学生とともに稲の種まきから日本酒の製造・販売までを行っている、秋田清酒株式会社の代表取締役社長、伊藤洋平（いとう・ようへい）氏 … <span>READ MORE</span></a></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-pr.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">PR</a>
          </div>
          <h3><a href="">【オンラインイベント】旅する編集者企画！地域の人と繋がり一緒に魅力を伝えていきませんか？</a></h3>
          <span>ローカリティ！編集部<time datetime="2021-9-24">2021年9月24日</time></span>
          <p><a href="">【PR】 情報発信に課題を抱えていると言われている秋田県には、たくさんの魅力が隠れています。 皆さんの力で地元事業者さんと繋がり、「地域の魅力を編集する」ことで一緒に伝えて地域を盛り上げる１つのきっかけを作ってみませ …<span>READ MORE</span></a></p>
        </article>
      </div>
      <div class="side_archive">
        <p>アーカイブ</p>
        <ol class="side_archive_lists">
          <li><a href="">2021年10月</a></li>
          <li><a href="">2021年9月</a></li>
          <li><a href="">2021年8月</a></li>
          <li><a href="">2021年7月</a></li>
          <li><a href="">2021年6月</a></li>
          <li><a href="">2021年5月</a></li>
          <li><a href="">2021年4月</a></li>
          <li><a href="">2021年3月</a></li>
          <li><a href="">2021年2月</a></li>
          <li><a href="">2021年1月</a></li>
          <li><a href="">2020年12月</a></li>
          <li><a href="">2020年11月</a></li>
          <li><a href="">2020年10月</a></li>
        </ol>
      </div>
      <div class="side_blank"></div>
      <div class="side_category">
        <p>カテゴリー</p>
        <ul class="side_category_lists">
          <li><a href="">#私の１０年 (2)</a></li>
          <li><a href="">CULTURE (17)</a></li>
          <li><a href="">FOOD (13)</a></li>
          <li><a href="">NATURE (10)</a></li>
          <li><a href="">PR (1)</a></li>
          <li><a href="">SOCIAL (10)</a></li>
          <li><a href="">カオハガン島 (4)</a></li>
          <li><a href="">ハツレポ (134)</a></li>
          <li><a href="">ハツレポーター編集会議 (2)</a></li>
          <li><a href="">もしもし、こちらローカリティ！ (14)</a></li>
          <li><a href="">ゆく年くる年ハツレポ (12)</a></li>
          <li><a href="">ローカリティ！ (38)</a></li>
          <li><a href="">ローカリティ！桜前線 (8)</a></li>
          <li><a href="">三重 (1)</a></li>
          <li><a href="">京都 (3)</a></li>
          <li><a href="">兵庫 (1)</a></li>
          <li><a href="">千葉 (3)</a></li>
          <li><a href="">和歌山 (28)</a></li>
          <li><a href="">和歌山ローカル情報発信Lab. (13)</a></li>
          <li><a href="">埼玉 (3)</a></li>
          <li><a href="">大阪 (1)</a></li>
          <li><a href="">宮城 (10)</a></li>
          <li><a href="">宮崎 (2)</a></li>
          <li><a href="">富山 (1)</a></li>
          <li><a href="">山形 (1)</a></li>
          <li><a href="">岩手 (2)</a></li>
          <li><a href="">感謝の気持ち (8)</a></li>
          <li><a href="">新潟 (1)</a></li>
          <li><a href="">映像ハツレポ (4)</a></li>
          <li><a href="">東京 (4)</a></li>
          <li><a href="">沖縄 (2)</a></li>
          <li><a href="">海外 (1)</a></li>
          <li><a href="">神奈川 (1)</a></li>
          <li><a href="">福島 (2)</a></li>
          <li><a href="">秋田 (46)</a></li>
          <li><a href="">群馬 (1)</a></li>
          <li><a href="">長崎 (1)</a></li>
          <li><a href="">長野 (1)</a></li>
          <li><a href="">静岡 (1)</a></li>
          <li><a href="">香川 (3)</a></li>
          <li><a href="">鹿児島 (1)</a></li>
        </ul>
      </div>
    </aside> -->

    </div>
    <Footer/>
</div>
</template>

<script>
import axios from 'axios';
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  name: 'Wanted',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      formData: {
        menu: null,
        name: null,
        email: null,
        phone: null,
        content: null
      },
      validateMessages: {
        required: '必須項目に入力してください。',
        email: '有効なメールアドレスを入力してください。'
      }
    };
  },
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async submitForm() {
      axios.post(
        '/admin/contact/send-mail', this.formData
      ).then(() => {
        alert('ありがとうございます。メッセージは送信されました。');
        location.reload();
      });
    }
  }
};
</script>
<style scoped src="../../sass/pages/style.css"></style>
