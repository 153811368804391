var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"login-header"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo",attrs:{"alt":"locality logo","src":_vm.baseUrl + '/storage/logo.png'}})])]),_vm._v(" "),_c('div',{staticClass:"login-body"},[_c('div',{staticClass:"id-login"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('forgot_password.enter_email')))]),_vm._v("\n            "+_vm._s(_vm.$t('forgot_password.note'))+"\n            "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sentRequest)}}},[_c('div',{staticClass:"item"},[_c('validation-provider',{attrs:{"rules":'required|email|regex:^[a-zA-Z0-9_.+-]+@(?!-)[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$',"custom-messages":{
                                required: _vm.$t('login.validate.email.required'),
                                email: _vm.$t('reset_password.email.email_validate'),
                                regex: _vm.$t('reset_password.email.email_validate')
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],attrs:{"type":"text"},domProps:{"value":(_vm.form.email)},on:{"click":_vm.removeError,"keyup":function($event){return _vm.trimEmail()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),(_vm.emailError)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.$t('forgot_password_frontend.email_error')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"item"},[_c('button',{staticClass:"btn-submit",attrs:{"type":"submit"},on:{"click":_vm.removeError}},[_vm._v(_vm._s(_vm.$t('send')))])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }