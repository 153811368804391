<template>
    <div class="box-footer">
            <ul>
                <li><a href="/about">運営会社</a></li>
                <li><a href="/terms">利用規約</a></li>
                <li><a href="/privacy">プライバシーポリシー</a></li>
                <li><a href="/contact">お問い合わせ</a></li>
            </ul>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/footer.css"></style>