export const namespaced = true;

export const state = {
  popup_status: 'false'
};

export const mutations = {
  SET_POPUP_STATUS(state, status) {
    state.popup_status = status;
  }
};

export const getters = {
  popupStatus() {
    return state.popup_status;
  }
};

export const actions = {
  setPopupStatus({ commit }, status) {
    commit('SET_POPUP_STATUS', status);
  }
};
