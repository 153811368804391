import Vue from 'vue';
import VueRouter from 'vue-router';

import Topics from '../pages/Topics';
// import News from '../pages/News';
// import Myloca from '../pages/Myloca';
// import GPS from '../pages/GPS';
// import Categories from '../pages/Categories';
import Reporters from '../pages/Reporters';
import Login from '../pages/Login';
import LoginCallBack from '../pages/SocialCallbackPage';
import LoginMessage from '../pages/LoginMessage';
import Logout from '../pages/Logout';
import ReporterDetail from '../pages/ReporterDetail';
import UserDetail from '../pages/UserDetail';
import Profile from '../pages/Profile';
import UserProfile from '../pages/UserProfile';
import Wanted from '../pages/Wanted';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import ForgotPassword from '../pages/ForgotPassword';
import ForgotPasswordSent from '../pages/ForgotPasswordSent';
import CustomPage from '../pages/CustomPage';

Vue.use(VueRouter);

Vue.router = new VueRouter({
  hashbang: false,
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/topics'
    },
    {
      path: '/topics',
      component: Topics
    },
    {
      path: '/news',
      component: Topics
    },
    // {
    //   path: '/ranking',
    //   component: Ranking
    // },
    {
      path: '/myloca',
      component: Topics
    },
    {
      path: '/location',
      component: Topics
    },
    {
      path: '/category/:categoryId',
      component: Topics
    },
    {
      path: '/reporters',
      component: Reporters
    },
    {
      path: '/frontend/login',
      component: Login
    },
    {
      path: '/login/social/callback',
      component: LoginCallBack
    },
    {
      path: '/frontend/login/:status',
      component: LoginMessage
    },
    {
      path: '/frontend/logout',
      component: Logout
    },
    {
      path: '/reporter/:reporterId',
      component: ReporterDetail
    },
    {
      path: '/user/:userId',
      name: 'user.profile',
      component: UserDetail
    },
    {
      path: '/profile',
      component: Profile
    },
    {
      path: '/profile/user',
      component: UserProfile
    },
    {
      path: '/wanted',
      component: Wanted
    },
    {
      path: '/about',
      component: About
    },
    {
      path: '/contact',
      component: Contact
    },
    {
      path: '/terms',
      component: Terms
    },
    {
      path: '/privacy',
      component: Privacy
    },
    {
      path: '/post/:spot_id',
      component: Topics
    },
    {
      path: '/frontend/reporter/forgot-password',
      component: ForgotPassword
    },
    {
      path: '/frontend/reporter/forgot-password/sent',
      component: ForgotPasswordSent
    },
    {
      path: '/:slug_page',
      component: CustomPage
    }
  ]
});

export default Vue.router;
