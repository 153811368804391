<template>
<div class="b_page_tt">
    /* eslint-disable no-irregular-whitespace */
    <Header @show-login="showLogin()"/>

    <div class="main_side_wrapper">
    <main class="wanted_wrapper">
      <section class="wanted_topic">
        <h1>第5期ハツレポーターを募集します！</h1>
        <div class="wanted_kv">
          <img src="/locality/images/wanted_banner.jpg" alt="レポーター募集のイメージ画像です。">
        </div>
        <p class="wanted_sub_title">この世界は、もっと魅力に溢れている。<br>
          あなたの愛する、あなたの地元の魅力を<br>
          ハツレポーターになって誰かに届けよう。<br>
          あなたの愛は、きっと誰かに届くはず。<br>
          一緒に世界を変えよう。</p>
        <p class="wanted_sub_text">合同会社イーストタイムズが運営するニュースサイト「ローカリティ！」は、地元の魅力的な人、モノ、風景、歴史などを自分なりの視点で発掘し、文章や写真・動画にして世の中に発信する「魅力発掘発信レポーター」、略して「ハツレポーター」の第5期生の募集を開始します。<br><br>
          ニュースを発信するのが新聞やテレビなどの既存メディアに限られる時代は終わり、誰もがニュースとなり得ることを発信できる世の中となりました。ローカリティ！は、「自分が魅力的だと思ったことを発掘し、発信したい」という１人１人の気持ちをカタチにするメディアを目指して、ニュースという言葉を再定義し、「魅力発掘発信レポート」略して「ハツレポ」と名付けて掲載しています。<br><br>
          昨年10月にローカリティ！のWebサイトが立ち上がり、始めにイーストタイムズのメンバーがハツレポを掲載してきましたが、12月にスターティングハツレポーターである第１期生が、そして第4期生も加わり、総勢107名となりました。ハツレポの数も150本以上に達しています。<br><br>
          さらにこの活動を広げるため、思いに共感する仲間を更に募ることにしました。</p>
        <p class="wanted_text">「みんなに伝えたいことがあるけど、SNS以外にアウトプットする場がない」</p>
        <p class="wanted_text">「ニュースってなると敷居が高いけど、ハツレポなら私でもチャレンジできそう」</p>
        <p class="wanted_sub_text">そう考えるみなさま、ハツレポーターになってみませんか。プロとして文章を書いた経験などは問いません。ご応募、お待ちしています！</p>
      </section>
      <section class="wanted_meeting">
        <h2>説明会開催！</h2>
        <p>新聞やネットには載っていない地元の魅力を全国に伝えるため、それぞれが日夜マイペースに活動しています。<br>
        ですが、まだまだ掘り起こされていないローカルの魅力がたくさんあるのです。<br>
        あなたの身の回りにあふれている、あなたしか知らないその土地の魅力を、我々と一緒に発信してみませんか？──と、言われても……。<br>
        「地元のことは好きだし、情報発信もちょっと興味があるけど、僕が私が参加できるのかな……？」<br>
        「参加はしてみたいけど……文章を書いたこともないし自信がないな……」<br>
        「そもそも自分の地元ってどんな魅力があったっけ……？」こちらは、そんな気持ちをお抱えの皆様に向けた、「『ローカリティ！』とはなんぞや？」についての説明会です。「どういう活動をするのかもう少し詳しく知りたい」というご要望にお応えして、我々が提唱する「地域の魅力とは何なのか？」を特別ダイジェスト版でご紹介！<br>
        我々含め、既に参加しているハツレポーターたちが、どういう想いで『ローカリティ！』に携わっているのかを、この説明会を通じて、ぜひ肌で感じていただければと思います。ほんの少しでも『ローカリティ！』にご興味のある皆様、ぜひお気軽にご参加ください！</p>
        <div class="wanted_meeting_overview">
          <h3><strong>説明会開催日時</strong></h3>
          <ol class="wanted_meeting_date">
            <li><time datetime="10-20 11:00">11/20（土）10:00-11:00</time></li>
          </ol>
          <span class="mb-3"><strong>説明会参加申し込みURL</strong></span>
          <span><a class="text-primary" href="https://peatix.com/event/3051728/view">https://peatix.com/event/3051728/view</a></span>
          <p>※こちらにご参加いただいた方は、その後、開催するローカリティ！スクールへの参加費用￥5000が半額の￥2500でハツレポーターにご登録いただけます。<br>
          ※以前、イーストタイムズが講師をしたセミナーにご参加の方は無料でご登録いただけます。</p>
          <p>※オンラインミーティングソフトzoomの利用があります。携帯電話などでご参加の方は、事前にインストールをお願いします。</p>
        </div>
        <div class="wanted_details">
          <div class="wanted_condition">
            <h3>【応募条件】</h3>
            <ul class="wanted_condition_lists">
              <li>・ハツレポーターとなって地元愛を爆発させたい人！</li>
              <li>・文章を書くプロとしての経験は問いません、初心者大歓迎。</li>
              <li>・お住まいの地域は問いません、どんな地域の方も大歓迎！海外も可。</li>
            </ul>
          </div>
          <div class="wanted_task">
            <h3>【ハツレポーターにやって頂きたいこと】</h3>
            <ul class="wanted_task_lists">
              <li>
                <h4>① 魅力発掘発信レポート「ハツレポ」の提出</h4>
                <p>あなたの大好きな地域の魅力やニュースを、あなたの言葉で全世界に伝えてみよう！<br>
                提出されたハツレポは、イーストタイムズ所属の大手メディアの記者経験者らによる校閲などを経て、当サイトや各種SNSにクレジット付きで掲載されます。</p>
                <span>提出目安：毎月１本（ノルマではありません）</span>
                <span>原稿料：税込1,000円／本をお支払いします</span>
              </li>
              <li>
                <h4>② 「ハツレポーター会議」への参加</h4>
                <p>月に数回、ハツレポーター同士の交流やハツレポの情報交換、編集部からのお知らせなどを共有する機会を設けます。<br>
                文章の書き方や写真の撮り方など、魅力的なハツレポを作るためのお悩み相談などにも対応。<br>
                井戸端会議のように、ゆるやかで楽しい集まり・繋がりを持つ場なので、お気軽にご参加ください。</p>
              </li>
              <li>
                <h4>③ ローカリティ！から依頼するハツレポ作成</h4>
                <p>提携している外部事業者様から委託されるハツレポを、有志の方に編集部から依頼して、作成していただきます。<br>
                作成された記事は、ローカリティ！だけでなく、提携先様が持つ各種メディアでも掲載されます。</p>
                <span>原稿料：お支払いします（委託記事の種別によって異なります）</span>
              </li>
              <li>
                <h4>④ コミュニケーション＆エンジョイ！</h4>
                <p>ローカリティ！に集まるのは、暮らす場所は違えど、同じ「地元愛」を持つ仲間同士です。<br>
                日常のちょっとした雑談からご自身の活動の宣伝まで、様々な話題をシェアして盛り上がりましょう！</p>
                <p>※チームコミュニケーションツール「slack」及びミーティングツール「zoom」を使用した、オンラインでのコミュニケーションがメインとなります。</p>
              </li>
            </ul>
          </div>
          <div class="wanted_before">
            <h3>【ハツレポーターになる前に】</h3>
            <p>・ハツレポーターになるために「ローカリティ！スクール」へ参加いただきます。（受講料5000円）</p>
            <p>ハツレポはどのように書くのか？の講義に参加いただきます。ローカリティ！スクールの受講を経てslackにご登録後、最初のハツレポをご提出頂き、正式にハツレポーターとしてご加入いただきます。</p>
            <p>※以前にイーストタイムズが開催した講義などに参加された方は無料でご案内します。</p>
          </div>
          <div class="wanted_limit">
            <h3>【申し込み募集締め切り】</h3>
            <time datetime="2021-12-4">2021年12月4日（土）</time>
          </div>
          <div class="wanted_form">
            <h3>【応募フォーム】</h3>
            <a href="https://docs.google.com/forms/d/1H6ckgfH42H1in3OyQhW1n7IwwfD4QcB36g7YIaqXeBc/">応募フォーム</a>
            <p>※説明会に参加された方はスクール申し込み時の受講料が半額になります。是非説明会にご参加の上、お申込みください。</p>
          </div>
        </div>
      </section>
    </main>

    <!-- サイドバーここから -->
    <!-- <aside class="side">
      <div class="side_reporter_img">
        <a href=""><img src="/locality/images/reporter-kv.jpg" alt="ハツレポーター募集のイメージ画像です。"></a>
      </div>
      <div class="side_search">
        <form action="">
          <input class="side_search_text" type="text" name="" id="" placeholder="検索…">
          <input class="side_search_submit" type="submit" value="検索">
        </form>
      </div>
      <div class="side_lately">
        <p>最近のハツレポ</p>
        <ul class="side_lately_title">
          <li><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「Moana」の決意</a></li>
          <li><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></li>
          <li><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></li>
        </ul>
      </div>
      <div class="side_report">
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-philippines.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href=""><span>NATURE<br></span><span>カオハガン島<br></span><span>ハツレポ</span></a>
          </div>
          <a href="">ローカリティ！</a>
          <h3><a href="">「わたしたちが島を守る」海洋ゴミ問題に立ち上がった若い女性たち「Moana」の決意</a></h3>
          <span>カオハガンハウス<time datetime="2021-10-2">2021年10月2日</time></span>
          <p>フィリピン・カオハガン島のさわこです。 みなさん、南国の離島と聞くとどんな風景を思い浮かべますか？  きっとこんな感じではないでしょうか？？ そう！まるで絵にかいたようなこの美しいビーチがあるのが、フィリピンのカオハガ …<span>READ MORE</span></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-wakayama.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href=""><span>CULTURE<br></span><span>ハツレポ<br></span><span>ローカリティ！</span></a>
          </div>
          <a href="">和歌山</a>
          <h3><a href="">「静かな湾を神の乗った船が進む神々しさ、和歌山県那智勝浦町の海中神事」</a></h3>
          <span>もとだてかづこ<time datetime="2021-9-26">2021年9月26日</time></span>
          <p>和歌山県那智勝浦町（なちかつうらちょう）。 毎年9月に、航海の安全と大漁を祈願し、勝浦八幡神社例大祭（かつうらはちまんじんじゃれいたいさい）が行われる。 祭りの日は、朝から町中がそわそわしている。 私が小学生の頃、約 …<span>READ MORE</span></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-akita.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href=""><span>CULTURE<br></span><span>ハツレポ<br></span><span>ローカリティ！</span></a>
          </div>
          <a href="">秋田</a>
          <h3><a href="">酒造りは過去から学び、酒造りを未来へ繋げる。秋田県大仙市の酒蔵</a></h3>
          <span>渡部生<time datetime="2021-9-25">2021年9月25日</time></span>
          <p>筆者の連載記事である『自分の手で作る酒。密着！大学生の日本酒づくり』において、秋田大学の学生とともに稲の種まきから日本酒の製造・販売までを行っている、秋田清酒株式会社の代表取締役社長、伊藤洋平（いとう・ようへい）氏 … <span>READ MORE</span></p>
        </article>
        <article class="side_report_item">
          <div class="side_report_link">
            <div class="side_report_img">
              <img src="/locality/images/side-pr.jpg" alt="レポートのイメージ画像です。">
            </div>
            <a href="">PR</a>
          </div>
          <h3><a href="">【オンラインイベント】旅する編集者企画！地域の人と繋がり一緒に魅力を伝えていきませんか？</a></h3>
          <span>ローカリティ！編集部<time datetime="2021-9-24">2021年9月24日</time></span>
          <p>【PR】 情報発信に課題を抱えていると言われている秋田県には、たくさんの魅力が隠れています。 皆さんの力で地元事業者さんと繋がり、「地域の魅力を編集する」ことで一緒に伝えて地域を盛り上げる１つのきっかけを作ってみませ …<span>READ MORE</span></p>
        </article>
      </div>
      <div class="side_archive">
        <p>アーカイブ</p>
        <ol class="side_archive_lists">
          <li><a href="">2021年10月</a></li>
          <li><a href="">2021年9月</a></li>
          <li><a href="">2021年8月</a></li>
          <li><a href="">2021年7月</a></li>
          <li><a href="">2021年6月</a></li>
          <li><a href="">2021年5月</a></li>
          <li><a href="">2021年4月</a></li>
          <li><a href="">2021年3月</a></li>
          <li><a href="">2021年2月</a></li>
          <li><a href="">2021年1月</a></li>
          <li><a href="">2020年12月</a></li>
          <li><a href="">2020年11月</a></li>
          <li><a href="">2020年10月</a></li>
        </ol>
      </div>
      <div class="side_blank"></div>
      <div class="side_category">
        <p>カテゴリー</p>
        <ul class="side_category_lists">
          <li><a href="">#私の１０年 (2)</a></li>
          <li><a href="">CULTURE (17)</a></li>
          <li><a href="">FOOD (13)</a></li>
          <li><a href="">NATURE (10)</a></li>
          <li><a href="">PR (1)</a></li>
          <li><a href="">SOCIAL (10)</a></li>
          <li><a href="">カオハガン島 (4)</a></li>
          <li><a href="">ハツレポ (134)</a></li>
          <li><a href="">ハツレポーター編集会議 (2)</a></li>
          <li><a href="">もしもし、こちらローカリティ！ (14)</a></li>
          <li><a href="">ゆく年くる年ハツレポ (12)</a></li>
          <li><a href="">ローカリティ！ (38)</a></li>
          <li><a href="">ローカリティ！桜前線 (8)</a></li>
          <li><a href="">三重 (1)</a></li>
          <li><a href="">京都 (3)</a></li>
          <li><a href="">兵庫 (1)</a></li>
          <li><a href="">千葉 (3)</a></li>
          <li><a href="">和歌山 (28)</a></li>
          <li><a href="">和歌山ローカル情報発信Lab. (13)</a></li>
          <li><a href="">埼玉 (3)</a></li>
          <li><a href="">大阪 (1)</a></li>
          <li><a href="">宮城 (10)</a></li>
          <li><a href="">宮崎 (2)</a></li>
          <li><a href="">富山 (1)</a></li>
          <li><a href="">山形 (1)</a></li>
          <li><a href="">岩手 (2)</a></li>
          <li><a href="">感謝の気持ち (8)</a></li>
          <li><a href="">新潟 (1)</a></li>
          <li><a href="">映像ハツレポ (4)</a></li>
          <li><a href="">東京 (4)</a></li>
          <li><a href="">沖縄 (2)</a></li>
          <li><a href="">海外 (1)</a></li>
          <li><a href="">神奈川 (1)</a></li>
          <li><a href="">福島 (2)</a></li>
          <li><a href="">秋田 (46)</a></li>
          <li><a href="">群馬 (1)</a></li>
          <li><a href="">長崎 (1)</a></li>
          <li><a href="">長野 (1)</a></li>
          <li><a href="">静岡 (1)</a></li>
          <li><a href="">香川 (3)</a></li>
          <li><a href="">鹿児島 (1)</a></li>
        </ul>
      </div>
    </aside> -->
    <!-- サイドバーここまで -->
    </div>

    <Footer/>
</div>
</template>

<script>
export default {
  name: 'Wanted',
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    }
  }
};
</script>
<style scoped src="../../sass/pages/style.css"></style>
