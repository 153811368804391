import UserApi from '../api/UserApi';
export const namespaced = true;
export const state = {
};
export const getters = {
};

export const mutations = {
};

export const actions = {
  async getFollowPosts({ commit }, userId) {
    const result = await UserApi.getFollowPosts(userId);
    return result.data;
  }
};
