<template>
  <button class="follow mb-2" v-if="$auth.check() && currentReporter" :class="{followed:isFollower}"
    @click="switchFollow"
  >
    + <i class="fas fa-user"></i></button>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Reporter-FollowButton',
  props: ['reporter'],
  computed: {
    isFollower() {
      let result = false;
      const currentUser = this.$auth.user();
      this.currentReporter.followers.forEach(follower => {
        if (currentUser.id === follower.user_id) {
          result = true;
        }
      });
      return result;
    }
  },
  data: function() {
    return {
      currentReporter: null
    };
  },
  async created() {
    this.currentReporter = await this.syncReporter(this.reporter);
  },
  methods: {
    ...mapActions({
      toggleFollow: 'ReporterStore/toggleFollow',
      syncReporter: 'ReporterStore/syncReporter'
    }),
    async switchFollow() {
      const followData = this.toggleFollow({
        user_id: this.$auth.user().id,
        reporter_id: this.reporter.id
      });
      if (followData.status === 'followed') {

      }
    }
  }
};
</script>

<style scoped>
.follow {
  border: none;
  border-radius: 5px;
  color: white;
  width: 40px;
  font-size: 1rem;
}
button.follow.followed{
  background: #079DE0;
}
</style>
