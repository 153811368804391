import BaseApi from './BaseApi';

class LocationApi extends BaseApi {
    apiUrl = this.baseUrl + '/api/locality/location';

    getSelectData(searchData) {
      return this.axios.get(this.apiUrl + '/select-data', { params: searchData });
    }

    getAllLocation() {
      return this.axios.get(this.apiUrl + '/all');
    }
}
export default new LocationApi();
