<template>
    <div>
        <l-map v-if="currentMapLevel && currentMapLevel.source_type === 'local'"
               class="locality-map"
               :zoom="this.startZoom"
               :min-zoom="currentMap.min_zoom"
               :max-zoom="currentMap.max_zoom"
               :center="currentCenter"
               @update:center="centerUpdate"
               @update:zoom="zoomUpdate"
               :crs="crs"
               :bounds.sync="bounds"
               :max-bounds="currentMapLevel.bounds"

               :options="{
                  maxBoundsViscosity:1.0
                }
        "
        @dragend="getMarkerPosition($event)"
        >
            <l-tile-layer
                :url="currentMap.tilesPath"
                :no-wrap="false"
                :tile-size="128"
            />
            <template v-for="(levelMarker) in filterLevelMarker">
                <l-marker
                    v-if="levelMarker.type == 'basic'"
                    :key="levelMarker.id"
                    :lat-lng="levelMarker.latLng"
                    :icon="levelMarker.icon"
                    :draggable="false"
                    @click="selectMarker(levelMarker)"
                />
                <round-marker
                    v-if="levelMarker.type == 'round'"
                    :key="levelMarker.id"
                    :level-marker="levelMarker"
                    :draggable="false"
                    @select-marker="selectMarker(levelMarker)"
                />
                <advance-marker
                    v-if="levelMarker.type == 'advance'"
                    :key="levelMarker.id"
                    :marker="levelMarker"
                    :draggable="false"
                    @click-marker="selectMarker(levelMarker)"
                />
                <terrane-moving-marker
                    v-if="levelMarker.type == 'moving' && !inZoom"
                    :key="levelMarker.id"
                    :marker="levelMarker"
                    :z-index-offset="levelMarker.zIndex*100"
                    @click-marker="markerClick(levelMarker)"
                    @update-ref-latlng="updateRefLatLng(levelMarker,$event)"
                    @update-main-latlng="updateMainLatLng(levelMarker,$event)"
                />
                <terrane-marker
                    v-if="levelMarker.type === 'terrane'"
                    :key="levelMarker.id"
                    :marker="levelMarker"
                />

            </template>
            <template v-if="locationLevel === 'LOCATION'">
                <template v-for="post in posts">
                    <post-marker v-bind:key="'post-' + post.id"
                                 :post="post"
                                 @select-marker="showPostDetail(post.id)"
                    ></post-marker>
                </template>
            </template>
            <template v-if="locationLevel === 'PREFECTURE'">
                <template v-for="(prefecture,index) in prefectures">
                    <prefecture-marker v-bind:key="'prefecture-' + index + prefecture.id"
                                       :prefecture="prefecture"
                    ></prefecture-marker>
                </template>
            </template>

            <template v-if="locationLevel === 'MUNICIPALITY'">
                <template v-for="(municipality,index) in municipalities">
                    <municipality-marker v-bind:key="'municipality-' + index + municipality.id"
                                       :municipality="municipality"
                    ></municipality-marker>
                </template>
            </template>
            <current-location-marker v-if="currentLocation"
                :user-current-location="currentLocation"
            ></current-location-marker>
        </l-map>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import RoundMarker from './marker/RoundMarker';
import AdvanceMarker from './marker/AdvanceMarker';
import TerraneMarker from './marker/TerraneMarker';
import TerraneMovingMarker from './marker/TerraneMovingMarker';
import CurrentLocationMarker from './marker/CurrentLocationMarker';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import PostMarker from './marker/PostMarker';
import PrefectureMarker from './marker/PrefectureMarker';
import MunicipalityMarker from './marker/MunicipalityMarker';
export default {
  name: 'VisualMap',
  components: {
    PostMarker,
    PrefectureMarker,
    MunicipalityMarker,
    LMap,
    LTileLayer,
    LMarker,
    RoundMarker,
    AdvanceMarker,
    TerraneMovingMarker,
    TerraneMarker,
    CurrentLocationMarker
  },
  computed: {
    ...mapGetters({
      currentMap: 'MapStore/currentMap',
      currentMapLevel: 'MapStore/currentMapLevel',
      allLevelMarkers: 'MapStore/allLevelMarkers',
      currentZoom: 'MapStore/currentZoom',
      currentCenter: 'MapStore/currentCenter',
      currentLocation: 'MapStore/currentLocation',
      posts: 'PostStore/posts',
      prefectures: 'PostStore/prefectures',
      municipalities: 'PostStore/municipalities',
      locationLevel: 'MapStore/locationLevel'
    }),
    filterLevelMarker() {
      const app = this;
      return this.allLevelMarkers.filter(function(levelMarker) {
        if (levelMarker.available && app.currentMapLevel.id === levelMarker.map_level_id) {
          return levelMarker;
        }
      });
    }
  },
  data() {
    return {
      startZoom: null,
      bounds: null,
      crs: L.CRS.Simple
    };
  },
  watch: {
    bounds() {
    }
  },
  created() {
    console.log('Create Visual map');
    console.log(this.currentCenter);
    this.startZoom = 5;
    const originalInitTile = L.GridLayer.prototype._initTile;
    // Fix white space between tiles
    L.GridLayer.include({
      _initTile(tile) {
        originalInitTile.call(this, tile);

        const tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      }
    });
    this.startZoom = (this.currentZoom) ? this.currentZoom : this.currentMap.default_zoom;
    this.mapCenter = this.currentMap.currentCenter;
  },

  methods: {

    async getMarkerPosition(event) {
      const bounds = this.bounds;

      if (bounds) {
        const coordinates = [
          await this.convertXYtoLatLng({
            lng: bounds._southWest.lng,
            lat: bounds._northEast.lat
          }),
          await this.convertXYtoLatLng({
            lng: bounds._northEast.lng,
            lat: bounds._northEast.lat
          }),
          await this.convertXYtoLatLng({
            lng: bounds._northEast.lng,
            lat: bounds._southWest.lat
          }),
          await this.convertXYtoLatLng({
            lng: bounds._southWest.lng,
            lat: bounds._southWest.lat
          })
        ];
        await this.changeBound({ coordinates });
        await this.getPosts();
      }
    },

    ...mapActions({
      setCurrentMapLevel: 'MapStore/setCurrentMapLevel',
      setCurrentMapLevelByZoom: 'MapStore/setCurrentMapLevelByZoom',
      setCurrentCenter: 'MapStore/setCurrentCenter',
      convertXYtoLatLng: 'MapStore/convertXYtoLatLng',
      setPopupStatus: 'PopupStore/setPopupStatus',
      setPopupPost: 'PostStore/setPopupPost',
      clickPost: 'PostStore/clickPost',
      getPosts: 'PostStore/getPosts',
      changeBound: 'PostStore/changeBound'
    }),
    zoomUpdate(zoom) {
      this.setCurrentMapLevelByZoom(zoom);
    },
    centerUpdate(newCenter) {
      this.setCurrentCenter(newCenter);
    },
    selectMarker() {},
    async showPostDetail(postId) {
      await this.setPopupPost(postId);
      await this.clickPost(postId);
      this.setPopupStatus('true');
    }
  }
};
</script>

<style scoped>
.locality-map {
    height: 58vh;
    z-index: 0;
}
@media (min-width: 1024px) {
    .locality-map {
        height: calc(100vh - 130px);
        margin-top: 0;
    }
}
</style>
