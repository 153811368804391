<template>
    <div>
        <l-marker
            :lat-lng="latLng"
            :icon="icon"
            :draggable="false"
            @click="clickMarker"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import 'leaflet/dist/leaflet.css';
import { latLng } from 'leaflet';
import * as L from 'leaflet';
const Vue2Leaflet = require('vue2-leaflet');

export default {
  name: 'PrefectureMarker',
  components: {
    'l-marker': Vue2Leaflet.LMarker
  },
  props: {
    prefecture: {
      type: Object,
      default: null
    }
  },
  data: function() {
    return {
      latLng: latLng(0, 0),
      icon: null
    };
  },
  methods: {
    ...mapActions({
      convertLatLngToXY: 'MapStore/convertLatLngToXY',
      setFilterPrefecture: 'PostStore/setFilterPrefecture'
    }),
    clickMarker() {
      console.log('Click prefecture');

      this.setFilterPrefecture(this.prefecture.id);
    }
  },
  async created() {
    this.latLng = await this.convertLatLngToXY({
      lat: this.prefecture.geo_lat,
      lng: this.prefecture.geo_lng
    });
    this.latLng = latLng(this.latLng);

    let imageUrl = process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';
    if (this.prefecture.image) {
      imageUrl = this.prefecture.image.url;
    }
    this.icon = L.divIcon({
      shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [100, 50],
      shadowAnchor: [25, 50],
      iconAnchor: [25, 55],
      html: '<div class="post-marker" style="background-image:url(' + imageUrl + ')"></div>'
    });
  }
};
</script>
<style>
.leaflet-marker-icon {
    background: transparent;
    border: none;
}
.post-marker {
    position:relative;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 2px solid #fff;
    width: 48px;
    height: 48px;
    box-shadow: 0px 9px 7px #0000004a;
}
.post-marker:focus {
    outline:none;
}
.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive:focus {
    outline: none;
}
.post-marker:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}
</style>
