<template>
  <div>
    <VueSlickCarousel class="banner d-block" :arrows="true" v-bind="settings" v-if="slides.length > 0">
      <a v-for="slide in slides" :key="slide.id" :href="slide.url" target="_blank" rel="noopener noreferrer">
        <img :src="slide.image_url" :alt="slide.title">
      </a>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GeoMark',
  components: { VueSlickCarousel },
  props: {
    banner_imgs: [
      {
        name: String,
        path: String
      }
    ]
  },
  data() {
    return {
      settings: {
        autoplay: true,
        speed: 800,
        autoplaySpeed: 5000
      }
    };
  },
  computed: {
    ...mapGetters({
      slides: 'SlideStore/slides'
    })
  },
  methods: {
    ...mapActions({
      getSlideByPackKey: 'SlideStore/getSlideByPackKey'
    })
  },
  created() {
    this.getSlideByPackKey('frontend-banner');
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/banner.css"></style>
<style>
.banner button {
  background: #c9c9c9 !important;
  border-radius: 50%;
}
</style>
