<template>
  <div>
    <l-marker
      ref="mainMarker"
      :icon="marker.mainIcon"
      :lat-lng="marker.latLng"
      :radius="0.1"
      :draggable="draggable"
      @update:lat-lng="updateMainLatLng($event)"
    />

    <l-marker
      ref="refMarker"
      :lat-lng="marker.ref_latLng"
      :icon="marker.icon"
      :draggable="draggable"
      @click="$emit('click-marker',marker)"
      @update:lat-lng="updateRefLatLng($event)"
    />
    <l-polyline
      ref="polyLine"
      :lat-lngs="polyLatLngs"
      :color="'white'"
    />
  </div>
</template>

<script>
let Vue2Leaflet = {};
if (process.client) {
  Vue2Leaflet = require('vue2-leaflet');
}

export default {
  name: 'AdvanceMarker',
  components: {
    LMarker: Vue2Leaflet.LMarker,
    LPolyline: Vue2Leaflet.LPolygon
  },
  props: {
    marker: {
      type: Object,
      default: null
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      polyLatLngs: [this.marker.latLng, this.marker.ref_latLng],
      polylineLatlng: []
    };
  },

  mounted() {
    this.$refs.mainMarker.mapObject.on('dragstart', this.dragStartHandler)
      .on('drag', this.dragHandler);
    this.$refs.refMarker.mapObject.on('dragstart', this.dragStartHandler)
      .on('drag', this.dragHandler);
  },
  methods: {
    updateMainLatLng(evt) {
      this.$emit('click-marker', this.marker);
      this.$emit('update-main-latlng', evt);
    },
    updateRefLatLng(evt) {
      this.$emit('click-marker', this.marker);
      this.$emit('update-ref-latlng', evt);
    },
    dragStartHandler(e) {
      const latlngs = this.$refs.polyLine.mapObject.getLatLngs();
      const latlng = e.target.getLatLng();
      for (let i = 0; i < latlngs.length; i++) {
        if (latlng.equals(latlngs[i])) {
          this.polylineLatlng = i;
        }
      }
    },
    dragHandler(e) {
      const latlngs = this.$refs.polyLine.mapObject.getLatLngs();
      const latlng = e.target.getLatLng();
      latlngs.splice(this.polylineLatlng, 1, latlng);
      this.$refs.polyLine.mapObject.setLatLngs(latlngs);
    }
  }
};
</script>
