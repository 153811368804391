<template>
  <l-marker
    :lat-lng="levelMarker.latLng"
    :icon="levelMarker.icon"
    :draggable="false"
    @click="$emit('select-marker',levelMarker)"
    @update:lat-lng="updateLatLng($event)"
  />
</template>
<script>
import 'leaflet/dist/leaflet.css';
const Vue2Leaflet = require('vue2-leaflet');

export default {
  name: 'RoundMarker',
  components: {
    'l-marker': Vue2Leaflet.LMarker
  },
  props: {
    levelMarker: {
      type: Object,
      default: null
    }
  },
  methods: {
    markerClick(marker) {
      this.$emit('round-marker-click', marker);
    },
    updateLatLng(evt) {
      this.$emit('update-round-latlng', evt);
    }
  }
};
</script>
<style>
.leaflet-marker-icon {
  background: transparent;
  border: none;
}
.round-marker {
  position:relative;
  background-color:#fff;
  border-radius: 50%;
  border: 4px solid #fff;
  width: 100%;
  height: 100%;
  box-shadow: 1px -2px 10px #00000087;
}
.round-marker:focus {
  outline:none;
}
.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive:focus {
  outline: none;
}
.round-marker:after {
  content:'';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 10px #fff;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
.round-img {
  border-radius: 50%;
  width: 100%!important;
  height: 100%!important;
  border: 1px solid #fff;
}
</style>
