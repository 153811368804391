import PostApi from '../api/PostApi';
import Vue from 'vue';
const _ = require('lodash');
export const namespaced = true;

export const state = {
  posts: [],
  prefectures: [],
  municipalities: [],
  categories: [],
  current_post: null,
  current_popup_post: null,
  current_type: 'topic',
  filter: { },
  filter_prefecture: null,
  filter_municipality: null,
  current_popup_like: null,
  current_popup_share: null,
  current_bounds: null
};

export const mutations = {
  SET_POSTS(state, posts) {
    // reset filter
    state.filter_prefecture = null;
    state.filter_municipality = null;
    state.posts = posts;
  },
  PUSH_POST(state, post) {
    state.posts.push(post);
  },
  SET_CURRENT_TYPE(state, type) {
    state.current_type = type;
  },
  SET_PREFECTURES(state, prefectures) {
    state.prefectures = prefectures;
  },
  SET_MUNICIPALITIES(state, municipalities) {
    state.municipalities = municipalities;
  },
  SET_CATEGORIES(state, municipalities) {
    state.municipalities = municipalities;
  },
  SET_SHOW_SELECT_CATEGORY_POPUP(state, showPopup) {
    state.show_select_category_popup = showPopup;
  },
  SET_POPUP_POST(state, post) {
    state.current_popup_post = post;
  },
  SET_POPUP_LIKE(state, likeCount) {
    state.current_popup_like = likeCount;
  },
  SET_POPUP_SHARE(state, shareCount) {
    state.current_popup_share = shareCount;
  },
  SET_CURRENT_BOUNDS(state, bounds) {
    state.current_bounds = bounds;
  },
  ADD_LIKE_TO_POST(state, option) {
    const posts = state.posts;
    const currentPost = state.current_popup_post;
    currentPost.likes.push(option.newLike);
    posts.forEach((post) => {
      if (post.id === currentPost.id) {
        post.likes.push(option.newLike);
      }
    });
  },
  REMOVE_LIKE_OF_USER(state, option) {
    const posts = state.posts;
    const currentPost = state.current_popup_post;
    currentPost.likes.forEach((like, index) => {
      if (like.user_id === option.userId) {
        currentPost.likes.splice(index);
      }
    });
    posts.forEach((post) => {
      if (post.id === currentPost.id) {
        post.likes.forEach((like, index) => {
          if (like.user_id === option.userId) {
            post.likes.splice(index);
          }
        });
      }
    });
  },
  ADD_SHARE_TO_POST(state, shareCount) {
    const posts = state.posts;
    const currentPost = state.current_popup_post;
    posts.forEach((post) => {
      if (post.id === currentPost.id) {
        post.total_share = shareCount;
      }
    });
  },

  SET_FILTER_PREFECTURE(state, prefectureId) {
    state.filter_prefecture = prefectureId;
    console.log(state.filter_prefecture);
  },
  SET_FILTER_MUNICIPALITY(state, municipalityId) {
    state.filter_municipality = municipalityId;
  },
  UPDATE_COMMENT_COUNT(state, commentCountData) {
    state.posts.forEach(post => {
      if (post.id === commentCountData.post_id) {
        post.comments_count = commentCountData.comments_count;
      }
    });
  }
};

export const getters = {
  posts() {
    let posts = state.posts;
    if (state.filter_prefecture) {
      posts = posts.filter(post => {
        return post.prefecture_id === state.filter_prefecture;
      });
    }
    if (state.filter_municipality) {
      posts = posts.filter(post => {
        return post.municipality_id === state.filter_municipality;
      });
    }
    return posts;
  },
  prefectures() {
    return state.prefectures;
  },
  municipalities() {
    return state.municipalities;
  },
  currentPopupPost() {
    return state.current_popup_post;
  },
  currentPost() {
    return state.current_popup_post;
  },
  currentLike() {
    return state.current_popup_like;
  },
  currentShare() {
    return state.current_popup_share;
  },
  currentBOUND() {
    return state.current_bounds;
  }
};

export const actions = {
  async getPosts({ commit, state }, filter = {}) {
    if (filter.type) {
      commit('SET_CURRENT_TYPE', filter.type);
    } else {
      filter.type = state.current_type;
    }
    const result = await PostApi.getPosts({ ...filter, ...state.current_bounds });
    const allPosts = result.data;
    let i = 1;
    allPosts.forEach(post => {
      if (i <= 15) {
        post.type = filter.type;
        i++;
      }
    });
    commit('SET_POSTS', allPosts);

    // Set Prefectures and Municipality
    const prefectures = [];
    const municipalities = [];
    allPosts.forEach((post) => {
      post.prefecture.image = post.image;
      post.municipality.image = post.image;
      prefectures.push(post.prefecture);
      municipalities.push(post.municipality);
    });
    commit('SET_PREFECTURES', prefectures);
    commit('SET_MUNICIPALITIES', municipalities);

    return allPosts;
  },
  changePostType({ commit, dispatch }, type) {
    return dispatch('getPosts', { type: type });
  },
  async changeBound({ commit }, bounds = {}) {
    return await commit('SET_CURRENT_BOUNDS', bounds);
  },
  async getPrefectures({ commit }) {
    const result = await PostApi.getPrefectures();
    const prefectures = result.data;
    commit('SET_PREFECTURES', prefectures);
  },
  async getMunicipalities({ commit }) {
    const result = await PostApi.getMunicipalities();
    const municipalities = result.data;
    commit('SET_MUNICIPALITIES', municipalities);
  },
  showSelectCategoryPopup({ commit }, popupStatus) {
    commit('SET_SHOW_SELECT_CATEGORY_POPUP', popupStatus);
  },
  async clickPost(store, spotId) {
    const postData = {
      spot_id: spotId
    };

    if (Vue.$cookies.get('session_key')) {
      postData.session_key = Vue.$cookies.get('session_key');
    }

    PostApi.clickPost(postData).catch(() => {
    }).then((result) => {
      Vue.$cookies.set('session_key', result.data.session_key);
    });
  },
  async likePost(store, likeData) {
    const response = await PostApi.likeSpot(likeData);
    return response;
  },
  async sharePost({ commit, store }, spotId) {
    const currentShare = state.current_popup_share;
    const shareData = {
      spot_id: spotId
    };

    if (Vue.$cookies.get('session_key')) {
      shareData.session_key = Vue.$cookies.get('session_key');
    }

    PostApi.sharePost(shareData).catch(() => {
    }).then((result) => {
      Vue.$cookies.set('session_key', result.data.session_key);
    });
    commit('SET_POPUP_SHARE', parseInt(currentShare) + 1);
    commit('ADD_SHARE_TO_POST', parseInt(currentShare) + 1);
  },
  async unLikePost(store, likeData) {
    const result = await PostApi.unLikeSpot(likeData);
    return result.data;
  },
  updateLike({ commit }, option) {
    const currentLike = state.current_popup_like;
    if (option.status) {
      commit('ADD_LIKE_TO_POST', option);
      commit('SET_POPUP_LIKE', currentLike + 1);
    } else {
      commit('REMOVE_LIKE_OF_USER', option);
      commit('SET_POPUP_LIKE', currentLike - 1);
    }
  },
  async setPopupPost({ commit, state }, postId) {
    let currentPost = null;
    state.posts.forEach(post => {
      if (post.id === postId) {
        currentPost = _.cloneDeep(post);
      }
    });
    if (!currentPost) {
      const result = await PostApi.findPostById(postId);
      currentPost = result.data;
      commit('PUSH_POST', currentPost);
    }

    commit('SET_POPUP_POST', currentPost);
    commit('SET_POPUP_LIKE', currentPost.total_like);
    commit('SET_POPUP_SHARE', currentPost.total_share);
  },
  async setDetailPopupPost({ commit, state }, postId) {
    let currentPost = null;
    state.posts.forEach(post => {
      if (post.id === postId) {
        currentPost = _.cloneDeep(post);
      }
    });

    if (!currentPost) {
      const postData = {
        canShowMap: false
      };
      const result = await PostApi.findPostDetailById(postId, postData);
      currentPost = _.cloneDeep(result.data);
    }

    commit('SET_POPUP_POST', currentPost);
    commit('SET_POPUP_LIKE', currentPost.total_like);
    commit('SET_POPUP_SHARE', currentPost.total_share);
  },
  removePopupPost({ commit }) {
    const post = [];
    commit('SET_POPUP_POST', post);
  },
  // Quick filters
  setFilterPrefecture({ commit }, prefectureId) {
    commit('SET_FILTER_PREFECTURE', prefectureId);
    commit('SET_FILTER_MUNICIPALITY', null);
  },
  setFilterMunicipality({ commit }, municipalityId) {
    commit('SET_FILTER_MUNICIPALITY', municipalityId);
    commit('SET_FILTER_PREFECTURE', null);
  },
  updateCommentCount({ commit }, commentCountData) {
    commit('UPDATE_COMMENT_COUNT', commentCountData);
  },
  async getPostByPage({ commit, dispatch }, { page, userId }) {
    let check = null;
    switch (page) {
    case 'post_topic':
      dispatch('changePostType', 'topic');
      break;
    case 'my_location':
      check = await dispatch('SubcribeStore/checkSubcribeSetting', userId, { root: true });
      if (check === false) {
        dispatch('SubcribeStore/getSubcribeSetting', null, { root: true });
      } else {
        dispatch('getPosts', {
          type: 'myloca',
          user_id: userId
        });
      }
      break;
    default:
      dispatch('changePostType', 'topic');
      break;
    }
  }
};
