<template>
  <div>
  <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateProfile)">
          <div class="top_pro row m-0">
              <div class="col-3 text-left"><router-link :to="'/reporter/'+$auth.user().id">キャンセル</router-link></div>
              <div class="col-6"><p class="title">プロフィールを編集</p></div>
              <div class="col-3 text-right"><button type="submit" class="btn btn-link">完了</button></div>
          </div>
          <div id="form_profile">
              <div class="text-center b_avatar">
                  <img class="avatar" :src="imageDataPreview" alt="avatar">
                  <br>
                  <input type="file" class="d-none">
                  <a href="#" v-show="!sh" @click.prevent="showIconCrop = !showIconCrop">プロフィール写真を変更</a>
                  <p v-if="fileSizeErr" style="color: red">最大ファイルサイズは2MBです。ファイルサイズを確認してください</p>
                  <p v-if="fileTypeErr" style="color: red">ドキュメントは有効なファイル形式ではありません</p>
                  <crop-upload
                      v-model="showIconCrop"
                      :lang-ext="{btn: {off: 'キャンセル', close: '閉じる', back: '他の画像を選択', save: '保存'},
                      hint: 'ここにファイルをドロップまたはファイルを選択'}"
                      :no-square="true" :width="300"
                      :height="300"
                      field="profile_image"
                      img-format="png"
                      @src-file-set="srcFileSet"
                      @crop-success="cropIconSuccess"/>
              </div>

              <div class="row m-0">
                  <div class="col-3"><p>名前</p></div>
                  <div class="col-9">
                      <validation-provider
                          :rules="processedValidateRules.name"
                          :custom-messages="validateMessages.name"
                          v-slot="{ errors }"
                      >
                          <input type="text" v-model="user.name">
                          <span class="error-message">{{ errors[0] }}</span>
                      </validation-provider>
                  </div>
              </div>

              <!--      <div class="row m-0">-->
              <!--        <div class="col-3"><p>ユーザーネーム</p></div>-->
              <!--        <div class="col-9">-->
              <!--          <input type="text" name="" value="taro_tanaka">-->
              <!--        </div>-->
              <!--      </div>-->

              <div class="row m-0">
                  <div class="col-3"><p>自己紹介</p></div>
                  <div class="col-9">
                      <textarea v-model="user.reporter_information.bio"></textarea>
                  </div>
              </div>

              <div class="row m-0">
                  <div class="col-3"><p>場所</p></div>
                  <div class="col-9">
                      <validation-provider
                          :rules="processedValidateRules.prefecture"
                          :custom-messages="validateMessages.prefecture"
                          v-slot="{ errors }"
                      >
                          <select @change="changeSelectLocation" v-model="user.reporter_information.prefecture_id">
                              <option :value="null">都道府県を選択してください </option>
                              <option :value="prefecture.id" v-for="prefecture in locationSelectData.prefectures" v-bind:key="prefecture.id">
                                  {{prefecture.name}}
                              </option>
                          </select>
                          <span class="error-message">{{ errors[0] }}</span>
                      </validation-provider>
                      <validation-provider
                          :rules="processedValidateRules.municipality"
                          :custom-messages="validateMessages.municipality"
                          v-slot="{ errors }"
                      >
                          <select v-model="user.reporter_information.municipality_id">
                              <option :value="null">市区町村を選択してください </option>
                              <option :value="municipality.id" v-for="municipality in locationSelectData.municipalities" v-bind:key="municipality.id">
                                  {{ municipality.name }}
                              </option>
                          </select>
                          <span class="error-message">{{ errors[0] }}</span>
                      </validation-provider>
                  </div>
              </div>

              <!--      <div class="row m-0">-->
              <!--        <div class="col-3"><p>Web</p></div>-->
              <!--        <div class="col-9">-->
              <!--          <input type="text" name="" value="Webサイトを追加">-->
              <!--        </div>-->
              <!--      </div>-->

              <div class="row m-0">
                  <div class="col-3"><p>メールアドレス</p></div>
                  <div class="col-9">
                      <input type="text" name="email" v-model="user.email" readonly>
                  </div>
              </div>

              <!--      <div class="row m-0">-->
              <!--        <div class="col-3"><p>名前</p></div>-->
              <!--        <div class="col-9">-->
              <!--          <input type="text" name="phone" value="+0818011111111">-->
              <!--        </div>-->
              <!--      </div>-->

              <!--      <div class="row m-0">-->
              <!--        <div class="col-3"><p>名前</p></div>-->
              <!--        <div class="col-9">-->
              <!--          <input type="text" name="sex" value="男性">-->
              <!--        </div>-->
              <!--      </div>-->

              <!--      <div class="row m-0">-->
              <!--        <div class="col-3"><p>生年月日</p></div>-->
              <!--        <div class="col-9">-->
              <!--          <input  name="birthday" type="date">-->
              <!--        </div>-->
              <!--      </div>-->

          </div>
      </form>
  </ValidationObserver>
  </div>
</template>

<script>
import CropUpload from 'vue-image-crop-upload';
import LocationApi from '../../../../../Admin/Resources/assets/js/Api/LocationApi';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

extend('unique', {
  validate(value, args) {
    return true;
  },
  params: ['table', 'column', 'except', 'idColumn', 'wheres']
});
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  name: 'Profile',
  props: [
    'sh',
    'validateRules',
    'validateMessages'
  ],
  components: {
    CropUpload,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      locationSelectData: {
        prefectures: [],
        municipalities: []
      },
      imageDataPreview: null,
      showIconCrop: false,
      fileSizeRequire: 2048000,
      fileSizeErr: false,
      fileTypeErr: false,
      imageData: null,
      user: null
    };
  },
  created() {
    if (this.$auth.check()) {
      this.user = this.$auth.user();
      if (this.user.reporter_information) {
        this.showIconCrop = false;
        LocationApi.getSelectData({
          prefecture_id: this.user.reporter_information.prefecture_id
        }).then((response) => {
          this.locationSelectData = response.data;
        });
        this.imageDataPreview = this.user ? this.user.profile_url : this.baseUrl + '/storage/logo.png';
      } else {
        this.$router.push('/profile/user');
      }
    } else {
      this.$router.push('/frontend/login');
    }
  },
  computed: {
    processedValidateRules() {
      Object.keys(this.validateRules).forEach((field) => {
        if (this.validateRules[field].regex) {
          this.validateRules[field].regex = RegExp(this.validateRules[field].regex);
        }
      });
      return this.validateRules;
    }
  },
  methods: {
    cropIconSuccess(imgDataUrl) {
      if (!this.fileTypeErr && !this.fileSizeErr) {
        this.imageData = this.imageDataPreview = imgDataUrl;
      }
      this.showIconCrop = false;
    },
    srcFileSet(fileName, fileType, fileSize) {
      this.fileSizeErr = false;
      this.fileTypeErr = true;
      if (fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg') {
        this.fileTypeErr = false;
      } else {
        return false;
      }
      if (fileSize > this.fileSizeRequire) {
        this.fileSizeErr = true;
      }
    },
    changeSelectLocation() {
      LocationApi.getSelectData({
        prefecture_id: this.user.reporter_information.prefecture_id
      }).then((response) => {
        this.locationSelectData = response.data;
        this.user.reporter_information.municipality_id = null;
      });
    },
    async updateProfile() {
      try {
        const result = await this.$auth.reporterUpdateProfile(
          this.user.id,
          {
            profile_image: this.imageData,
            name: this.user.name,
            prefecture_id: this.user.reporter_information.prefecture_id,
            municipality_id: this.user.reporter_information.municipality_id,
            bio: this.user.reporter_information.bio,
            password: null
          }
        );

        if (result.status === 'success') {
          window.location.href = this.baseUrl + '/reporter/' + this.user.id;
        } else {
          alert('エラーが発生しました。');
        }
      } catch (error) {
        alert('エラーが発生しました。');
      }
    }
  }
};
</script>
<style scoped src="../../sass/pages/profile.css"></style>
<style>
    @media (max-width: 650px) {
        .vue-image-crop-upload .vicp-wrap {
            width: 90vw;
        }

        .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left {
            width: 50vw;
        }

        .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container {
            width: 50vw;
        }
    }
</style>
