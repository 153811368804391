<template>
  <div>
    <l-image-overlay
      v-if="marker.type === 'terrane'"
      :url="marker.imageURL"
      :bounds="setBoundOverlay(marker)"
      :class-name="'terrance-marker'"
    />
    <l-polygon
      :ref="'poly-terrane-' + marker.id"
      :class-name="'terrance-marker'"
      :lat-lngs="allPolys"
      :draggable="true"
      :options="{
        draggable: true,
        opacity: 0.001,
        fillOpacity: 0.001
      }"
      :interactive="false"
    />
  </div>
</template>

<script>

let Vue2Leaflet = {};
let Leaflet = {};
if (process.client) {
  Vue2Leaflet = require('vue2-leaflet');
  Leaflet = require('leaflet');
}

export default {
  name: 'TerraneMarker',
  components: {
    LImageOverlay: Vue2Leaflet.LImageOverlay,
    LPolygon: Vue2Leaflet.LPolygon
  },
  props: {
    marker: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      allPolys: [],
      latLng: Leaflet.latLng
    };
  },
  computed: {

  },
  watch: {
    'marker.marker': {
      deep: true,
      handler() {
        this.calLatLngForPoly();
      }
    }

  },
  mounted() {
    this.calLatLngForPoly();
    const app = this;
    this.$refs['poly-terrane-' + this.marker.id].mapObject.on('drag', function(evt) {
      const latLngs = evt.target.getLatLngs();
      const bound1 = latLngs[0][0];
      app.marker.lat = bound1.lat + app.marker.marker.size_h / 2;
      app.marker.lng = bound1.lng + app.marker.marker.size_h / 2;
    }).on('dragend', function(evt) {
      // eslint-disable-next-line new-cap
      const latLng = new app.latLng(app.marker.lat, app.marker.lng);

      app.$emit('update-latlng', latLng);
    });
  },
  methods: {
    setBoundOverlay(marker) {
      return [
        [parseFloat(marker.lat) - marker.marker.size_h / 2, parseFloat(marker.lng) - marker.marker.size_w / 2],
        [parseFloat(marker.lat) + marker.marker.size_h / 2, parseFloat(marker.lng) + marker.marker.size_w / 2]
      ];
    },
    calLatLngForPoly() {
      this.allPolys = [
        [parseFloat(this.marker.lat) - this.marker.marker.size_h / 2, parseFloat(this.marker.lng) - this.marker.marker.size_w / 2],
        [parseFloat(this.marker.lat) - this.marker.marker.size_h / 2, parseFloat(this.marker.lng) + this.marker.marker.size_w / 2],
        [parseFloat(this.marker.lat) + this.marker.marker.size_h / 2, parseFloat(this.marker.lng) + this.marker.marker.size_w / 2],
        [parseFloat(this.marker.lat) + this.marker.marker.size_h / 2, parseFloat(this.marker.lng) - this.marker.marker.size_w / 2]

      ];
    },
    clickPoly() {
    }
  }
};
</script>

<style>
.terrance-marker {
  cursor: move;
}
</style>
