import BaseApi from './BaseApi';

class ReporterApi extends BaseApi {
    apiUrl = this.baseUrl + '/api/user';

    getFollowPosts(userId) {
      return this.axios.get(this.apiUrl + '/' + userId + '/follow-posts');
    }

    updateSubcribe(userId, subcribeData) {
      return this.axios.post(this.apiUrl + '/' + userId + '/subcribe/update', subcribeData);
    }

    getSubcribeSetting(userId) {
      return this.axios.get(this.apiUrl + '/' + userId + '/subcribe');
    }

    checkSubcribeSetting(userId) {
      return this.axios.get(this.apiUrl + '/' + userId + '/subcribe/check');
    }
}

export default new ReporterApi();
