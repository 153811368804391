import SlideApi from '../api/SlideApi';
export const namespaced = true;
export const state = {
  slides: []
};

export const mutations = {
  SET_SLIDES(state, slides) {
    state.slides = slides;
  }
};

export const getters = {
  slides() {
    return state.slides;
  }
};

export const actions = {
  async getSlideByPackKey({ commit }, $key) {
    const result = await SlideApi.getSlideByPackKey($key);
    commit('SET_SLIDES', result.data);
  }
};
