import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=6bf41c06&scoped=true&"
import script from "./UserDetail.vue?vue&type=script&lang=js&"
export * from "./UserDetail.vue?vue&type=script&lang=js&"
import style0 from "../../sass/pages/reporter_detail.css?vue&type=style&index=0&id=6bf41c06&scoped=true&lang=css&"
import style1 from "./UserDetail.vue?vue&type=style&index=1&id=6bf41c06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf41c06",
  null
  
)

export default component.exports