<template>
    <div>
        <div class="login-header">
            <img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo">
        </div>
        <div class="login-body">
            <div class="id-login">
                <p class="title">{{ $t('送信完了') }}</p>
                <div class="description">
                    入力したメールアドレスにパスワード再設定のメールを送信しました。<br>
                    <span class="note">※パスワード再設定URLの有効期限は送信時から２時間となります。</span>
                </div>
                <div class="item">
                    <button type="submit" class="btn-submit" @click="backToLogin">{{ $t('login')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ForgotPasswordSent',
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL
    };
  },
  methods: {
    backToLogin() {
      window.location.href = this.baseUrl + '/frontend/login';
    }
  }
};
</script>

<style scoped src="../../sass/pages/forgot_password.css"></style>
<style scoped>
.description {
    font-size: 11px;
}

.note {
     color: red;
}

.item button {
    width: 345px;
}

@media (min-width: 1024px) {
    .description {
        font-size: 13px;
    }

    .item button {
        width: 415px;
    }
}
</style>
