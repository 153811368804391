import LocationApi from '../api/LocationApi';

export const namespaced = true;

export const state = {
  locations: []
};

export const mutations = {
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  }
};

export const getters = {
  locations() {
    return state.locations;
  }
};

export const actions = {
  async getLocations({ commit }) {
    const result = await LocationApi.getAllLocation();
    commit('SET_LOCATIONS', result.data);
  }
};
