<template>
    <div class="modal-background">
        <div class="modal-login">
            <button type="button" class="close" @click="$emit('show-login')">
                <span aria-hidden="true">&times;</span>
            </button>
            <a href="#" class="btn btn-login" @click="socialLogin('google')">
                Google
            </a>
            <a href="#" class="btn btn-login" @click="socialLogin('facebook')">
                Facebook
            </a>
            <a href="#" class="btn btn-login">
                Instagram
            </a>
            <a href="#" class="btn btn-login" @click="socialLogin('twitter')">
                Twitter
            </a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Login',
  props: {
    showLogin: Boolean
  },
  methods: {
    socialLogin(type) {
      const redirect = process.env.MIX_APP_URL + '/login/social/callback';
      const socialLoginUrl = process.env.MIX_APP_URL + '/login/' + type + '?redirect=' + redirect;
      window.location = socialLoginUrl;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}

li {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100px;
    width: 33.3333%;
}

a {
    align-items: center;
    color: black;
    display: flex;
    justify-content: center;
    height: 100%;
    text-decoration: none;
    width: 100%;
}

.modal-background {
    align-items: center;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
}

.modal-login {
    background: white;
    border-radius: 15px;
    max-width: 400px;
    padding: 25px;
    text-align: left;
    width: 95vw;
    padding-top: 15px;
}

.modal-login h2 {
    font-size: 1em;
    font-weight: normal;
    margin: 0 5px;
}
</style>
