const L = require('leaflet');

class MarkerHelper {
  createIcon(levelMarker, type) {
    const iconUrl = (levelMarker.marker.icon) ? levelMarker.marker.icon.link : process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';
    if (type === 'round') {
      return L.divIcon({
        iconUrl: iconUrl,
        shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
        iconSize: [levelMarker.marker.size_w, levelMarker.marker.size_h],
        iconAnchor: [levelMarker.marker.icon_anchor_lat, levelMarker.marker.icon_anchor_lng],
        shadowSize: [levelMarker.marker.shadow_size_w, levelMarker.marker.shadow_size_h],
        shadowAnchor: [22, 38],
        html: '<div class="round-marker"><img class="round-img" src="' + iconUrl + '"></div>'
      });
    } else if (type === 'basic') {
      return L.icon({
        iconUrl: (levelMarker.marker.icon) ? levelMarker.marker.icon.link : process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png',
        shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
        iconSize: [levelMarker.marker.size_w, levelMarker.marker.size_h],
        iconAnchor: [levelMarker.marker.icon_anchor_lat, levelMarker.marker.icon_anchor_lng],
        shadowSize: [levelMarker.marker.shadow_size_w, levelMarker.marker.shadow_size_h],
        shadowAnchor: [22, 38]
      });
    } else if (type === 'dot') {
      return L.icon({
        iconUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/dot-icon.png',
        iconSize: [10, 10],
        iconAnchor: [5, 6],
        shadowSize: [15, 15],
        shadowAnchor: [6, 8]
      });
    }
  }
}
export default new MarkerHelper();
