import UserApi from '../api/UserApi';

export const namespaced = true;

export const state = {
  subcribe_status: false,
  show_setting: false,
  subcribe_setting: {
    location: [],
    categories: [],
    reporters: []
  }
};

export const mutations = {
  SET_SUBCRIBE_STATUS(state, status) {
    state.subcribe_status = status;
  },
  SET_SHOW_SETTING(state, status) {
    state.show_setting = status;
  },
  SET_SUBCRIBE_SETTING(state, subcribeSetting) {
    state.subcribe_setting = subcribeSetting;
  }
};

export const getters = {
  isShowSetting() {
    return state.show_setting;
  },

  subcribeSetting() {
    return state.subcribe_setting;
  }
};

export const actions = {
  showHideSetting({ commit }) {
    commit('SET_SHOW_SETTING', !state.show_setting);
  },
  async getSubcribeSetting({ commit }, userId) {
    const result = await UserApi.getSubcribeSetting(userId);

    const subcribeSetting = {
      location: result.data.followingsMunicipalitiesIds,
      categories: result.data.followingsCategoriesIds,
      reporters: result.data.followingsReportersIds
    };

    commit('SET_SUBCRIBE_SETTING', subcribeSetting);
  },
  async setShowSetting({ commit }, status) {
    commit('SET_SHOW_SETTING', status);
  },
  async saveSetting({ commit }, settingData) {
    const userId = settingData.userId;
    const subcribeData = settingData.data;
    const result = await UserApi.updateSubcribe(userId, subcribeData);
    if (result.data.status === 'success') {
      commit('SET_SUBCRIBE_STATUS', true);
    };
  },
  checkSubcribeSetting() {
    const subcribeSetting = state.subcribe_setting;
    return !((subcribeSetting.location === 0 && subcribeSetting.reporters === 0));
  }
};
