import CommentApi from '../api/CommentApi';

export const namespaced = true;
export const state = {
  comments: []
};

export const getters = {
  comments() {
    return state.comments;
  }
};

export const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  PUSH_COMMENT(state, comment) {
    state.comments.unshift(comment);
  },
  PUSH_LIKE(state, likeData) {
    state.comments.forEach(comment => {
      if (comment.id === likeData.comment_id) {
        comment.likes.push(likeData.like);

        console.log(comment.likes);
      }
    });
  },
  REMOVE_LIKE(state, likeData) {
    console.log('Remove Like');

    state.comments.forEach(comment => {
      if (comment.id === likeData.comment_id) {
        comment.likes.forEach(like => {
          if (like.user_id === likeData.user_id) {
            console.log('Remove');

            comment.likes.splice(comment.likes.indexOf(like), 1);
            console.log(comment.likes);
          }
        });
      }
    });
  }
};

export const actions = {
  async getComments({ commit }, postId) {
    const response = await CommentApi.getComments(postId);
    const comments = response.data;
    commit('SET_COMMENTS', comments);
    return comments;
  },
  async createComment({ commit }, commentData) {
    const postId = commentData.post_id;
    const response = await CommentApi.createComment(postId, commentData);
    const newComment = response.data;
    newComment.likes = [];
    await commit('PUSH_COMMENT', newComment);
    return newComment;
  },
  async toggleLike({ commit }, likeData) {
    const response = await CommentApi.toggleLike(likeData.comment_id, likeData.user_id);
    const result = response.data;

    if (result.status === 'liked') {
      likeData.like = result.like;
      commit('PUSH_LIKE', likeData);
    } else {
      commit('REMOVE_LIKE', likeData);
    }
  }
};
