<template>
  <div
    class="modal fade"
    id="category-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("frontend.all_categories") }}
          </h5>
          <button
            type="button"
            class="close"
            @click.prevent="setSelectPopup(false)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            class="category"
            role="button"
            v-bind:key="'category-' + category.id"
            v-for="category in categories"
            @click.prevent="addCategory(category.id, category.name, $event)"
          >
            <div class="category-image">
              <img v-if="category.image" :src="category.image.url" alt="" />
            </div>
            <h4 class="category-name">{{ category.name }}</h4>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <span
              class="badge badge-secondary mr-2"
              v-for="(name, index) in categoryNameArr"
              :key="index"
              >{{ name }}</span
            >
          </div>
          <div class="col-12 text-center mt-2">
            <button
              type="button"
              class="btn btn-primary mr-3"
              @click="filterConfirm()"
            >
              {{ $t('Confirm') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="removeDataSelect()"
            >
              {{ $t('Reset') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'SelectCategory',
  data() {
    return {
      categoryIdArr: [],
      categoryNameArr: []
    };
  },
  computed: {
    ...mapGetters({
      selectPopup: 'CategoryStore/selectPopup',
      categories: 'CategoryStore/categories'
    })
  },
  watch: {
    selectPopup(popupStatus) {
      if (popupStatus) {
        $('#category-modal').modal({ backdrop: 'static', keyboard: false });
      } else {
        $('#category-modal').modal('hide');
      }
    }
  },
  created() {
    this.getCategories();
  },
  methods: {
    ...mapActions({
      setSelectPopup: 'CategoryStore/setSelectPopup',
      getCategories: 'CategoryStore/getCategories',
      getPosts: 'PostStore/getPosts'
    }),
    addCategory(id, name, event) {
      if (!this.categoryIdArr.includes(id)) {
        this.categoryIdArr.push(id);
        this.categoryNameArr.push(name);
        $(event.currentTarget).addClass('border-selected');
      }
    },
    removeDataSelect() {
      this.categoryIdArr = [];
      this.categoryNameArr = [];
      this.getPosts({ categories: this.categoryIdArr });
      $('.category').removeClass('border-selected');
    },
    filterConfirm() {
      this.getPosts({ categories: this.categoryIdArr });
      this.setSelectPopup(false);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/select_category.css"></style>
