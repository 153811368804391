<template>
  <div>
    <div class="comment-list" :class="{'with-create':this.$auth.check()}">
      <div v-for="comment in comments" v-bind:key="'comment-' + comment.id">
        <div class="comment">
          <img class="comment-img" :src="comment.user.profile_url" alt="">
          <div class="comment-content">
            <a href="#" class="comment-user">{{ comment.user.name }}</a>
            {{ comment.content }}
            <div class="comment-info">
              <p>{{ moment(comment.created_at).fromNow() }}</p>
            </div>
          </div>
          <a href="#" class="like-btn" v-if="$auth.check()" :class="{liked: isLiked(comment)}"
             @click.prevent="submitLike(comment)"
          >
            <i :class="{
              'far fa-heart':!isLiked(comment),
              'fas fa-heart':isLiked(comment)
            }"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Vue from 'vue';
Vue.prototype.moment = moment;

moment.locale('ja');
export default {
  name: 'Comment',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      currentPost: 'PostStore/currentPopupPost',
      comments: 'CommentStore/comments'
    })
  },
  watch: {
    currentPost() {
      if (this.currentPost) {
        this.getcomments(this.currentPost.id);
      }
    }
  },
  async created() {
    this.getcomments(this.currentPost.id);
  },
  methods: {
    ...mapActions({
      getcomments: 'CommentStore/getComments',
      toggleLike: 'CommentStore/toggleLike'
    }),
    isLiked(comment) {
      let result = false;
      if (comment.likes) {
        comment.likes.forEach(like => {
          if (like.user_id === this.$auth.user().id) {
            result = true;
          }
        });
      }

      return result;
    },
    async submitLike(comment) {
      if (this.$auth.check()) {
        await this.toggleLike({
          user_id: this.$auth.user().id,
          comment_id: comment.id
        });
      }
    }
  }

};
</script>

<style scoped>
.comment-list {
    background: #f5f5f5;
  margin-top: 15px;
}

a.comment-user {
  text-decoration: none;
  margin-right: 1%;
  font-weight: bold;
  color: #021B6A;
}

.comment {
  display: flex;
  padding: 15px;
  align-content: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
}

img.comment-img {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  margin-right: 15px;
}

img.comment-img-large {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  margin-right: 15px;
}

.comment-content {
  font-size: 0.85rem;
  width: calc(100% - 75px);
  word-wrap: break-word;
}
.comment-info {

}
a.like-btn {
  color: #d2d2d2;
}
a.like-btn.liked {
  color: #e84747;
}
</style>
