<template>
<!--    <div style="height: 100%; width: 100%">-->
    <div style="width: 100%">
        <l-map
            v-if="currentCenter && currentGeoCenter && currentMapLevel && currentMapLevel.source_type === 'osm' && geoZoom"
            :zoom="geoZoom"
            class="locality-map"
            :center="currentGeoCenter"
            :options="mapOptions"
            @click="clickMap"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            ref="open-street-map"
            @dragend="getMarkerPosition($event)"
        >
            <l-tile-layer
                :url="url"
            />
            <template v-for="(levelMarker) in allLevelMarkers">
                <template v-if="levelMarker.available && currentMapLevel.id === levelMarker.map_level_id">
                    <l-marker :lat-lng="levelMarker.geo_lat_lng"
                              v-if="levelMarker.type == 'basic'"
                              :icon="levelMarker.icon"
                              :z-index-offset="levelMarker.zIndex*100"
                              :draggable="false"
                              @mousedown="markerClick(levelMarker)"
                              @update:lat-lng="updateLatLng(levelMarker,$event)"
                              v-bind:key="levelMarker.id"/>

                    <round-marker
                        :level-marker="levelMarker"
                        v-if="levelMarker.type == 'round'"
                        :draggable="false"
                        :z-index-offset="levelMarker.zIndex*100"
                        v-bind:key="levelMarker.id"/>
                    <advance-marker v-if="levelMarker.type == 'advance'"
                                    :marker="levelMarker"
                                    v-bind:key="levelMarker.id"
                                    :draggable="false"
                                    @click-marker="markerClick(levelMarker)"
                                    :z-index-offset="levelMarker.zIndex*100"
                    />
                </template>
            </template>

            <template v-for="post in posts">
                <post-marker v-bind:key="post.id"
                             :post="post"
                             @select-marker="showPostDetail(post.id)"
                ></post-marker>
            </template>
        <current-location-marker v-if="currentGeoLocation" :user-current-location="currentGeoLocation">

        </current-location-marker>
        </l-map>
    </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import RoundMarker from './marker/osm/RoundMarker';
import AdvanceMarker from './marker/osm/AdvanceMarker';
import PostMarker from './marker/osm/PostMarker';
import CurrentLocationMarker from './marker/osm/CurrentLocationMarker';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'OpenStreetMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    RoundMarker,
    AdvanceMarker,
    PostMarker,
    CurrentLocationMarker
  },
  computed: {
    ...mapGetters({
      currentMap: 'MapStore/currentMap',
      currentMapLevel: 'MapStore/currentMapLevel',
      currentCenter: 'MapStore/currentCenter',
      currentGeoCenter: 'MapStore/currentGeoCenter',
      currentGeoLocation: 'MapStore/currentGeoLocation',
      allLevelMarkers: 'MapStore/allLevelMarkers',
      posts: 'PostStore/posts'
    }),
    filterLevelMarker() {
      const app = this;
      return this.allLevelMarkers.filter(function(levelMarker) {
        if (levelMarker.available && app.currentMapLevel.id === levelMarker.map_level_id) {
          return levelMarker;
        }
      });
    }
  },
  data() {
    return {
      defaultZoom: 11.5,
      geoZoom: 0,
      zoom: 1,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      withPopup: null,
      withTooltip: null,
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true
    };
  },
  watch: {
    currentMapLevel() {
      if (this.currentMapLevel.source_type === 'osm') {
        for (let i = 0; i < this.currentMap.zoom_config.length; i++) {
          if (this.currentMap.zoom_config[i].map_level_id === this.currentMapLevel.id) {
            this.defaultZoom = this.geoZoom = this.currentMap.zoom_config[i].geo_zoom_level;
            this.zoom = this.currentMap.zoom_config[i].zoom_level;
            break;
          }
        }
      }
    },
    async currentCenter() {
      if (this.currentCenter.lat && this.currentCenter.lng && this.currentMapLevel.source_type !== 'osm') {
        const geoCenter = await this.convertXYtoLatLng(this.currentCenter);
        this.setCurrentGeoCenter(geoCenter);
      }
    }
  },
  mounted() {
  },
  methods: {

    async getMarkerPosition(event) {
      const bounds = this.$refs['open-street-map'].mapObject.getBounds();

      if (bounds) {
        const coordinates = [
          {
            lng: bounds._southWest.lng,
            lat: bounds._northEast.lat
          },
          {
            lng: bounds._northEast.lng,
            lat: bounds._northEast.lat
          },
          {
            lng: bounds._northEast.lng,
            lat: bounds._southWest.lat
          },
          {
            lng: bounds._southWest.lng,
            lat: bounds._southWest.lat
          }
        ];
        await this.changeBound({ coordinates });
        await this.getPosts();
      }
    },

    ...mapActions({
      setCurrentMapLevel: 'MapStore/setCurrentMapLevel',
      setCurrentMapLevelByZoom: 'MapStore/setCurrentMapLevelByZoom',
      setCurrentCenter: 'MapStore/setCurrentCenter',
      setCurrentGeoCenter: 'MapStore/setCurrentGeoCenter',
      convertXYtoLatLng: 'MapStore/convertXYtoLatLng',
      convertLatLngToXY: 'MapStore/convertLatLngToXY',
      getPosts: 'PostStore/getPosts',
      setPopupStatus: 'PopupStore/setPopupStatus',
      setPopupPost: 'PostStore/setPopupPost',
      clickPost: 'PostStore/clickPost',
      changeBound: 'PostStore/changeBound'
    }),
    clickMap(event) {
    },
    zoomUpdate(geoZoom) {
      if (geoZoom < this.defaultZoom) {
        this.zoom -= 1;
        this.setCurrentMapLevelByZoom(this.zoom);
      }
    },
    async centerUpdate(center) {
      this.setCurrentGeoCenter(center);
      this.setCurrentCenter(await this.convertLatLngToXY(center));
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert('Click!');
    },
    async showPostDetail(postId) {
      await this.setPopupPost(postId);
      await this.clickPost(postId);
      this.setPopupStatus('true');
    }
  }
};
</script>
<style scoped>
.locality-map {
    height: 58vh;
    z-index: 0;
}
@media (min-width: 1024px) {
    .locality-map {
        height: calc(100vh - 130px);
        margin-top: 0;
    }
}
</style>
