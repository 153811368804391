<template>
    <div>
        <Header @show-login="showLogin()"/>
        <div class="box-reporter bc-white">
          <div class="row">
              <div class="col-3 text-left">
                <img v-if="user.profile_image != null" class="avatar" :src="'/storage/'+user.profile_image.path" alt="avatar">
                <img v-else class="avatar" :src="baseUrl + '/storage/logo.png'" alt="avatar">
              </div>
              <div class="col-1"></div>
              <div class="col-8"></div>
          </div>
          <div class="text-left bb_re2">
            <p class="name">{{ user.name }}</p>
            <p v-if="user.information.bio" class="mb-4">{{ user.information.bio.value }}</p>
          </div>
          <button v-if="$auth.check()" type="button" class="btn btn-default mr-xl-4"><a href="/profile/user">{{ $t('edit_profile') }}</a></button>
          <button v-if="$auth.check()" type="button" class="btn btn-default"><a href="#" @click="showSubcribeModal()">{{ $t('set_favorites') }}</a></button>
        </div>

        <div class="box-post bc-white" v-if="list && list.length != 0">
            <div v-for="(item, $index) in list" :key="$index" class="post_detail">
                <p class="tag_md">
                    <span v-for="category in item.categories" :key="category.id">
                        #{{ category.name }}
                    </span>
                </p>
                <h4>{{item.title}}</h4>
                <p class="time">
                    <span class="author pr-1 text-truncate">{{ item.reporter.name }}</span>
                    <time>{{ item.formatted_publish_time }}</time>
                </p>
                <p class="content" v-html="item.content"></p>
                <a class="see-more-link" @click="showPostDetail(item.id)">{{ $t('see_more') }}</a>
            </div>
        </div>

        <infinite-loading @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <post-detail-modal v-if="currentPopupPost"/>
        <div class="modal fade" id="show_developing_modal"
             tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true"
             data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    あなたも「ロカミン」になって、自分だけのローカル情報プラットフォームを作ろう！
                    <br>ユーザーの皆さん＝「ロカミン」が、ローカリティ！上に自分の「好き！」を集めて、心のふるさと＝「マイ・ローカル（マイロカ）」をカスタマイズできる機能を実装予定！
                    <br>①あなたが「好き！」なトピックスをフォローして、自分だけのオリジナルローカル情報マップを作ろう！
                    <br>②記事に「いいね」して、お気に入りをコレクションしよう！
                    <br>③記事にコメントして、あなたの地元愛を仲間に伝えよう！
                    <br>2021年11月15日実装予定
                    <br>coming soon...
                    <div class="text-center pt-2">
                        <button type="button" class="btn btn-secondary show-developing-button" data-dismiss="modal">閉じる</button>
                    </div>
                </div>
            </div>
        </div>

        <setting-subcribe-modal></setting-subcribe-modal>
    </div>
</template>

<script>
import axios from 'axios';
import numeral from 'numeral';
import { mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'UserDetail',
  components: {
    InfiniteLoading
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      reporterId: this.$route.params.reporterId,
      statistic: [],
      numeral: numeral,
      user: null,
      posts: null,
      page: 1,
      list: []
    };
  },
  computed: {
    ...mapGetters({
      reporter: 'ReporterStore/reporterDetail',
      prefecture: 'ReporterStore/prefecture',
      municipality: 'ReporterStore/municipality',
      currentPopupPost: 'PostStore/currentPopupPost'
    })
  },
  async created() {
    if (!this.$auth.check()) {
      window.location.href = this.baseUrl;
    }
    this.user = this.$auth.user();
  },
  methods: {
    ...mapActions({
      setPopupStatus: 'PopupStore/setPopupStatus',
      setDetailPopupPost: 'PostStore/setDetailPopupPost',
      clickPost: 'PostStore/clickPost',
      setMyloca: 'SubcribeStore/showHideSetting',
      getSubcribeSetting: 'SubcribeStore/getSubcribeSetting',
      checkSubcribeSetting: 'SubcribeStore/checkSubcribeSetting'
    }),
    showLogin() {
      this.$router.push('/frontend/login');
    },
    ...mapActions({
      getFollowPosts: 'UserStore/getFollowPosts'
    }),
    showDevelopingModal() {
      $('#show_developing_modal').modal('show');
    },
    infiniteHandler($state) {
      axios.get(this.baseUrl + '/api/user/' + this.user.id + '/follow-posts', {
        params: {
          page: this.page
        }
      }).then(({ data }) => {
        if (data.length) {
          this.page += 1;
          this.list.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    async showPostDetail(postId) {
      await this.setDetailPopupPost(postId);
      await this.clickPost(postId);
      this.setPopupStatus('true');
    },
    async showSubcribeModal() {
      await this.getSubcribeSetting(this.$auth.user().id);
      this.setMyloca();
    }
  }
};
</script>
<style scoped src="../../sass/pages/reporter_detail.css"></style>
<style scoped>
.modal-content {
    text-align: left;
    padding: 10%;
}

.show-developing-button {
    color: #ffffff;
    background-color: #021B6A;
    border-color: #021B6A;
    box-shadow: none;
}
</style>
