<template>
<div class="b_page_tt">
    /* eslint-disable no-irregular-whitespace */

    <Header @show-login="showLogin()"/>

  <main class="privacy_wrapper">
    <h1 class="privacy_title">プライバシーポリシー</h1>
    <p>合同会社イーストタイムズ（以下「当社」といいます。）は、お客様の個人情報について個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱いに努めます。</p>
    <ol class="privacy_lists">
      <li class="privacy_list_item">
        <h2>1. 個人情報の定義</h2>
        <p>本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、又は個人識別符号が含まれる情報を意味します。</p>
      </li>
      <li class="privacy_list_item">
        <h2>2. 個人情報の利用目的</h2>
        <p>当社は、お客様の個人情報を、以下の目的で利用致します。</p>
        <ol class="privacy_second_lists">
          <li>（１） 当社の商品及びサービス（以下総称して「当社サービス」といいます。）の提供のため</li>
          <li>（２） 当社サービスに関するご案内、お問い合わせ等への対応のため</li>
          <li>（３） 当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</li>
          <li>（４） 当社サービスに関する規約等の変更などを通知するため</li>
          <li>（５） 当社サービスの改善、当社の新サービスの開発等に役立てるため</li>
          <li>（６） 当社サービスに関連して、個人を識別できない形式に加工した統計データを作成するため</li>
          <li>（７） その他、上記利用目的に付随する目的のため</li>
        </ol>
      </li>
      <li class="privacy_list_item">
        <h2>3. 個人情報利用目的の変更</h2>
        <p>当社は、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更する場合にはお客様に事前に通知又は公表します。</p>
      </li>
      <li class="privacy_list_item">
        <h2>4. 個人情報利用の制限</h2>
        <p>当社は、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。</p>
        <ol class="privacy_second_lists">
          <li>（１） 法令に基づく場合</li>
          <li>（２） 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>（３） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>（４） 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
        </ol>
      </li>
      <li class="privacy_list_item">
        <h2>5. 個人情報の適正な取得</h2>
        <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。</p>
      </li>
      <li class="privacy_list_item">
        <h2>6. 個人情報の安全管理</h2>
        <p>当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクにつき、個人情報の安全管理が図られるよう、当社従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先における個人情報の安全管理につき、必要かつ適切な監督を行います。</p>
      </li>
      <li class="privacy_list_item">
        <h2>7. 第三者提供</h2>
        <p>当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ずに、個人情報の第三者提供を行いません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。</p>
        <ol class="privacy_second_lists">
          <li>（１） 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</li>
          <li>（２） 合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
          <li>（３） 個人情報保護法の定めに基づき共同利用する場合</li>
        </ol>
      </li>
      <li class="privacy_list_item">
        <h2>８. 個人情報の開示</h2>
        <p>当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上、お客様に対し遅滞なく開示（当該個人情報が存在しないときにはその旨の通知）を行います。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合はこの限りではありません。なお、個人情報の開示につきましては、手数料（１件あたり1,000円）を頂戴しておりますので予めご了承ください。</p>
      </li>
      <li class="privacy_list_item">
        <h2>９. 個人情報の訂正等</h2>
        <p>当社は、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上、利用目的の達成に必要な範囲内において遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。</p>
      </li>
      <li class="privacy_list_item">
        <h2>10. 個人情報の利用停止等</h2>
        <p>当社は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。</p>
      </li>
      <li class="privacy_list_item">
        <h2>11. Cookie（クッキー）その他の技術の利用</h2>
        <ol class="privacy_second_lists">
          <li>（１） 当社は、Cookieについては、それ単独では特定の個人を識別することができないため、個人情報とは考えておりません。ただしCookieの情報と個人情報が一体となって使用される場合にはこれら情報も個人情報とみなします。また、Cookie情報については、ブラウザの設定で拒否することが可能です。但し、Cookieを無効化すると、当社サービスの一部をご利用いただけなくなる場合があります。</li>
          <li>（２） 本サービスは、Facebook, Inc.（以下「Facebook」といいます）によって提供される機能を使用し、Facebookの独自のプライバシーポリシーの下で情報を取得する場合があります。Facebookのプロフィールの「公開情報」のみが当社に送られます。</li>
          <li>（３）本サービスは、Twitter Japan株式会社（以下「Twitter」といいます）が提供している機能を使用し、同社のプライバシーポリシーに基づき情報を収集することがあります。この場合、ユーザーのTwitterプロフィールの「公開情報」のみが当社に送られます。</li>
          <li>（４）本サービスは、LINE株式会社（以下「LINE」といいます）が提供している機能を使用し、同社のプライバシーポリシーに基づき情報を収集することがあります。この場合、ユーザーのLINEアカウントにおける「公開情報」のみが当社に送られます。</li>
          <li>（５）本サービスは、Google LLC（以下「Google」といいます）が提供している機能を使用し、同社のプライバシーポリシーに基づき情報を収集することがあります。この場合、ユーザーのGoogleアカウントにおける「公開情報」のみが当社に送られます。</li>
          <li>
            （６）当社は、当社サービスの利用状況等を調査及び分析のため、当社サービス上に Google LCCが提供する Google アナリティクスを利用しています。Googleアナリティクスでデータが収集、処理される仕組みその他Googleアナリティクスの詳しい情報につきましては、同社のサイトをご覧ください。
            <ul class="privacy_third_lists">
              <li>
                ・Google アナリティクス 利用規約：<br>
                <a href=" https://www.google.com/analytics/terms/jp.html"> https://www.google.com/analytics/terms/jp.html</a>
              </li>
              <li>
                ・お客様が Google パートナーのサイトやアプリを使用する際の Google によるデータ使用：<br>
                <a href=" https://www.google.com/intl/ja/policies/privacy/partners/"> https://www.google.com/intl/ja/policies/privacy/partners/</a>
              </li>
              <li>
                ・Google プライバシーポリシー：<br>
                <a href=" http://www.google.com/intl/ja/policies/privacy/"> http://www.google.com/intl/ja/policies/privacy/</a>
              </li>
            </ul>
            <p>
              なお、お客様として、ご自身のデータが Google アナリティクスで使用されることを望まない場合は、Google 社の提供する Google アナリティクス オプトアウト アドオンをご利用ください。
            </p>
            ・Google アナリティクス オプトアウト アドオン：<br>
            <a href=" https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>
          </li>
          <li>
            （７） 当社サービスでは「Google Analyticsの広告向けの機能」を有効にする場合があり、その場合、下記の機能を利用し、広告やサイト改善のためDoubleClick CookieなどのサードパーティCookieを利用しています。
            <ul class="privacy_third_lists">
              <li>・Google Analyticsリマーケティング</li>
              <li>・Google Analyticsのユーザー属性レポートとインタレスト レポート</li>
            </ul>
            <p>これにより、本サービスではGoogle AnalyticsのCookieを利用して、お客様の年齢・性別・閲覧履歴・本サービスに関する関心の傾向をおおよそ把握するための分析が可能となっております。</p>
            <p>「Google Analyticsの広告向けの機能」を使用されることを望まない場合は、設定によってトラッキングを無効にすることが可能です。Google Analytics オプトアウト アドオンをブラウザにインストールされると無効にすることができます。</p>
          </li>
        </ol>
      </li>
      <li class="privacy_list_item">
        <h2>12. お問い合わせ</h2>
        <p>開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。</p>
        <address>
          〒150-0021東京都渋谷区恵比寿西1丁目33-6 co-ba ebisu 1F<br>
          合同会社イーストタイムズ<br>
          個人情報管理責任者 畠山 智行<br>
          TEL : 03-6822-3656<br>
          Email: info@the-east.jp<br>
          受付時間：平日10時から18時まで
        </address>
      </li>
      <li class="privacy_list_item">
        <h2>13. 本プライバシーポリシーの変更</h2>
        <p>当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。</p>
      </li>
    </ol>
    <p class="privacy_end">以上</p>
  </main>

    <Footer/>
</div>
</template>

<script>
export default {
  name: 'Wanted',
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    }
  }
};
</script>
<style scoped src="../../sass/pages/style.css"></style>
