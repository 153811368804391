import BaseApi from './BaseApi';

class CategoryApi extends BaseApi {
    apiUrl = this.baseUrl + '/api/locality';

    index() {
      return this.axios.get(this.apiUrl + '/category');
    }

    getCategoriesTree() {
      return this.axios.get(this.apiUrl + '/category-tree');
    }
}
export default new CategoryApi();
