import MenuApi from '../api/MenuApi';
export const namespaced = true;
export const state = {
  main_menus: []
};

export const mutations = {
  SET_MAIN_MENUS(state, mainMenus) {
    state.main_menus = mainMenus;
  }
};

export const getters = {
  mainMenus() {
    return state.main_menus;
  }
};

export const actions = {
  async getMainMenus({ commit }) {
    const result = await MenuApi.getMenuByPackKey('frontend-menu');
    commit('SET_MAIN_MENUS', result.data);
  }
};
