<template>
  <VueSlickCarousel class="banner d-sm-none" :arrows="true">
    <a href="/wanted"><img src="/locality/banner/banner1.png" alt="banner"></a>
    <a href="/reporter/27"><img src="/locality/banner/banner2.png" alt="banner"></a>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: 'BannerArticle',
  components: { VueSlickCarousel },
  props: {
    banner_imgs: [{
      name: String,
      path: String
    }]
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/banner_article.css"></style>
<style>
.banner button {
    background: #c9c9c9 !important;
    border-radius: 50%;
}
</style>
