import axios from 'axios';
// const baseURL = process.env.MIX_API_URL;
class Auth {
  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  check() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  user() {
    return this.currentUser;
  }

  fetch() {
    return this.currentUser;
  }

  async reporterLogin(loginData) {
    const result = await axios.post(process.env.MIX_API_URL + '/frontend/auth/reporter/login', loginData);
    return result.data;
  }

  async reporterForgotPassword(userData) {
    const result = await axios.post(process.env.MIX_API_URL + '/frontend/auth/reporter/forgot-password', userData);
    return result.data;
  }

  async reporterUpdateProfile(reporterId, reporterData) {
    console.log(reporterId);
    console.log(reporterData);
    const result = await axios.patch(process.env.MIX_API_URL + `/frontend/reporter/${reporterId}`, reporterData);
    return result.data;
  }

  async userUpdateProfile(userId, userData) {
    const result = await axios.patch(process.env.MIX_API_URL + `/frontend/user/${userId}`, userData);
    return result.data;
  }
}

export default {
  install(Vue, currentUser) {
    const auth = new Auth(currentUser);
    // define an instance method
    Vue.prototype.$auth = auth;
  }
};
