<template>
    <div class="box-header">
        <header>
            <ul>
                <li><a href="/topics"><img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo"></a></li>
                <!-- <li><a v-if="!$auth.check()" href="#" @click="$emit('show-login')">login</a></li> -->
                <!-- <li><a v-if="$auth.check()" href="/frontend/logout">logout</a></li> -->
                <li>
                    <div class="box_login" v-if="!$auth.check()" @click="$emit('show-login')">
                        <i class="fas fa-user-circle"></i> <span>ログイン</span>
                    </div>
                    <div class="box_profile" v-if="$auth.check()" data-toggle="modal" data-target="#profileModal">
                        <img :src="$auth.user().profile_url" alt="avatar">
                    </div>
                    <i class="fas fa-bars icon_menu" data-toggle="modal" data-target="#menuModal"></i>
                </li>
            </ul>
        </header>

        <!-- Modal -->
        <div class="modal fade" id="menuModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body pl-5 pl-xl-0 pr-0">
                        <button type="button" class="close pr-3 d-xl-none" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <ul>
                            <li v-if="$auth.check()"><a class="pl-0 pl-xl-5" href="/topics"><img alt="locality logo" :src="baseUrl + '/locality/images/locality_logo.png'"></a></li>
                            <li v-if="$auth.check()"><a class="pl-0 pl-xl-5" href="#" data-dismiss="modal" aria-label="Close" @click="showDevelopingModal()">{{ $t('become_a_locamine') }}</a></li>
<!--                            <li v-if="$auth.check()"><a class="pl-0 pl-xl-5" href="/myloca">{{ $t('become_a_locamine') }}</a></li>-->
<!--                            <li v-if="!$auth.check()"><a class="pl-0 pl-xl-5" href="/frontend/login">{{ $t('become_a_locamine') }}</a></li>-->
                            <li v-if="!$auth.check()"><a class="pl-0 pl-xl-5" href="" @click="$emit('show-login')">ログイン</a></li>
                            <!-- <li><a class="pl-0 pl-xl-5" href="">特集</a></li> -->
                            <!-- <li><a class="pl-0 pl-xl-5" href="/reporters">ハツレポーター一覧</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/wanted">ハツレポーター募集</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/about">ローカリティ！とは</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/contact">お問い合わせ</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/terms">利用規約</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/privacy">プライバシーポリシー</a></li> -->
                            <li v-for="menu in mainMenus" :key="menu.id" v-show="menu.is_actived"><a class="pl-0 pl-xl-5" :href="menu.url">{{ menu.name }}</a></li>
                            <li><a class="pl-0 pl-xl-5" href="/topics"><img alt="locality logo" :src="baseUrl + '/storage/logo.png'"></a></li>
                            <li class="d-none d-xl-flex cancel-pc"><a class="pl-0 pl-xl-5" href="" data-dismiss="modal" aria-label="Close">キャンセル</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

<!-- Modal profile -->
<div v-if="$auth.check()" class="modal fade" id="profileModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <br>
        <img class="avatar" :src="($auth.user().profile_url)?$auth.user().profile_url:baseUrl + '/storage/logo.png'"
             alt="avatar">
        <p class="name mb-4">{{ $auth.user().name }}</p>
        <div class="mb-2" v-if="currentStatistic">
          <div class="row">
            <div class="col-3 pr-0">{{ numeral(currentStatistic.published_posts).format(0,0) }} <br><span class="sm_spp">投稿</span></div>
            <div class="col-4 pr-0">{{ numeral(currentStatistic.followers).format(0,0) }} <br><span class="sm_spp">フォロワー</span></div>
            <div class="col-4 pr-0">{{ numeral(currentStatistic.following).format(0,0) }} <br><span class="sm_spp">フォロー中</span></div>
            <div class="col-5 mt-2 mt-xl-3 ic-he"><i class="fas fa-heart mr-1"></i> {{ numeral(currentStatistic.liked).format(0,0) }}</div>
            <div class="col-5 mt-2 mt-xl-3"><span class="spe_pro"><span class="ic_pp">p</span></span>
              {{ numeral(currentStatistic.final_location_point).format(0,0) }}pt</div>
          </div>
        </div>
        <button v-if="$auth.user().reporter_information" type="button" class="btn bt_pf mb-4"><a
            :href="'/reporter/' + $auth.user().id">マイページへ</a></button>
        <button v-if="!$auth.user().reporter_information" type="button" class="btn bt_pf mb-4"><a
            :href="'/user/' + $auth.user().id">マイページへ</a></button>
        <p><a class="logout" href="/frontend/auth/logout">ログアウト</a></p>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import numeral from 'numeral';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      currentStatistic: false,
      numeral: numeral
    };
  },
  created() {
    if (this.$auth.check() && this.$auth.user().reporter_information) {
      this.getStatistic(this.$auth.user().id).then((statistic) => {
        this.currentStatistic = statistic;
      });
    }
    this.getMainMenus();
  },
  computed: {
    ...mapGetters({
      mainMenus: 'MenuStore/mainMenus'
    })
  },
  methods: {
    ...mapActions({
      getStatistic: 'ReporterStore/getStatistic',
      getMainMenus: 'MenuStore/getMainMenus'
    }),
    showDevelopingModal() {
      $('#show_developing_modal').modal('show');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/compoments/header.css"></style>
