<template>
  <div class="b_page_tt">
    <Header @show-login="showLogin()" />

    <div class="container">
      <div class="box-main">
        <h1>{{ currentPage.title }}</h1>
        <div class="ck-content">
          <p v-html="currentPage.content"></p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'CustomPage',
  data() {
    return {
      slugPage: this.$route.params.slug_page
    };
  },
  created() {
    this.getPageBySlug(this.slugPage);
  },
  methods: {
    ...mapActions({
      getPageBySlug: 'PageStore/getPageBySlug'
    }),

    showLogin() {
      this.$router.push('/frontend/login');
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'PageStore/currentPage'
    })
  }
};
</script>
<style>
  .box-main {
    display: flow-root;
    padding: 100px 0 80px;
    background-color: white;
    padding: 100px 20px 80px;
    text-align: left;
  }
  .box-main h1{
    text-align: center;
    padding: 40px;
  }
  @media(max-width: 415px) {
    .box-main h1{
      font-size: 20px;
      padding: 15px 0;
    }
  }
</style>
<style scoped src="../../sass/pages/style.css"></style>
