<template>
  <div>
    <l-marker
      :icon="marker.mainIcon"
      :lat-lng="marker.geo_lat_lng"
      :radius="0.1"
      ref="mainMarker"
      :draggable="false"
      @update:lat-lng="updateMainLatLng($event)"
    />

    <l-marker :lat-lng="marker.ref_geo_lat_lng"
              :icon="marker.icon"
              ref="refMarker"
              :draggable="false"
              @click="$emit('click-marker',marker)"
              @update:lat-lng="updateRefLatLng($event)"
    >

    </l-marker>
    <l-polyline
      :lat-lngs="polyLatLngs"
      ref="polyLine"
      :color="'white'"
    ></l-polyline>
  </div>
</template>

<script>
import { LMarker, LPolyline } from 'vue2-leaflet';

export default {
  name: 'AdvanceMarker',
  components: {
    LMarker,
    LPolyline
  },
  props: [
    'marker',
    'status'
  ],
  watch: {
    'marker.icon.options.iconSize': {
      deep: true,
      handler(newVal, oldVal) {
        if (this.marker.marker.keep_original_ratio) {
          const imgPreview = $('#preview img')[0];
          if (imgPreview) {
            const originalRatio = imgPreview.naturalWidth / imgPreview.naturalHeight;
            this.marker.icon.options.iconSize[1] = this.marker.icon.options.iconSize[0] / originalRatio;
          }
        }
      }
    }
  },
  data() {
    return {
      polyLatLngs: [this.marker.geo_lat_lng, this.marker.ref_geo_lat_lng],
      polylineLatlng: []
    };
  },
  mounted() {
    this.$refs.mainMarker.mapObject.on('dragstart', this.dragStartHandler)
      .on('drag', this.dragHandler);
    this.$refs.refMarker.mapObject.on('dragstart', this.dragStartHandler)
      .on('drag', this.dragHandler);
  },
  methods: {
    updateMainLatLng(evt) {
      this.$emit('click-marker', this.marker);
      this.$emit('update-main-latlng', evt);
    },
    updateRefLatLng(evt) {
      this.$emit('click-marker', this.marker);
      this.$emit('update-ref-latlng', evt);
    },
    dragStartHandler(e) {
      const latlngs = this.$refs.polyLine.mapObject.getLatLngs();
      const latlng = e.target.getLatLng();
      for (let i = 0; i < latlngs.length; i++) {
        if (latlng.equals(latlngs[i])) {
          this.polylineLatlng = i;
        }
      }
    },
    dragHandler(e) {
      var latlngs = this.$refs.polyLine.mapObject.getLatLngs();
      var latlng = e.target.getLatLng();
      latlngs.splice(this.polylineLatlng, 1, latlng);
      this.$refs.polyLine.mapObject.setLatLngs(latlngs);
    }
  }
};
</script>
