<template>
    <div>
        <Header @show-login="showLogin()"/>
        <section>
          <Reporter :reporters="reporters"
            :prefecture="prefecture"
            :municipality="municipality"
          />
          <div infinite-wrapper>
            <div style="overflow: auto;">
              <infinite-loading force-use-infinite-wrapper="true"  @infinite="infiniteHandler">
                <div slot="spinner">Loading...</div>
                <div slot="no-more"></div>
                <div slot="no-results">No results</div>
              </infinite-loading>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'Reporters',
  components: {
    InfiniteLoading
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL
    };
  },
  computed: {
    ...mapGetters({
      prefecture: 'ReporterStore/prefecture',
      municipality: 'ReporterStore/municipality',
      reporters: 'ReporterStore/reporters'
    })
  },
  created() {
    this.getPrefecture();
    this.getMunicipality();
  },
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    },
    ...mapActions({
      infiniteHandler: 'ReporterStore/infiniteHandler',
      getPrefecture: 'ReporterStore/getPrefecture',
      getMunicipality: 'ReporterStore/getMunicipality'
    })
  }
};
</script>
