<template>
  <div>
    <l-image-overlay
      ref="movingMarker"
      :url="(marker.currentStage)?marker.currentStage.icon.options.iconUrl:marker.icon.options.iconUrl"
      :bounds="setBoundOverlay(marker)"
      :z-index="zIndexOffset"
      :interactive="false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
let latLng = null;
let leaflet = {};
let Vue2Leaflet = {};
const _ = require('lodash');
if (process.client) {
  Vue2Leaflet = require('vue2-leaflet');
  leaflet = require('leaflet');
  latLng = leaflet.latLng;
}

export default {
  name: 'TerraneMovingMarker',
  components: {
    LImageOverlay: Vue2Leaflet.LImageOverlay
  },
  props: {
    marker: {
      type: Object,
      default: null
    },
    zIndexOffset: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      allPolys: [],
      latLng,
      polyLatLngs: [this.marker.latLng, this.marker.ref_latLng],
      movingLatLng: [],
      movingMarkerIcon: {},
      movingDuration: 2000,
      currentMovingStateOrder: 0,
      currentMovingState: {},
      movingRepeat: null,
      stageInterval: null,
      movingInterval: null,
      currentMovingLatLng: {},
      stageFadeInterval: null,
      stageFadeCount: 0,
      stageFadeDuration: 0,
      startLatLng: null,
      movingAnimation: {
        startLatLng: null,
        duration: null,
        endLatLng: null,
        lineVector: null,
        stepLng: null
      }
    };
  },
  computed: {
    play_status() {
      return this.marker.play_status;
    }
  },
  watch: {
    play_status() {
      this.intervalStep = 10;
      if (this.marker.play_status === 'play') {
        // Hide main Marker and show Moving Marker
        this.$refs.movingMarker.mapObject.setOpacity(1);

        if (!this.marker.currentStage) {
          this.marker.play_status = 'stop';
          return;
        }

        // Set current Stage
        this.marker.currentStageOrder = 0;
        this.marker.currentStage = _.cloneDeep(this.marker.moving_stages[0]);
        this.currentMovingLatLng = _.cloneDeep(this.marker.latLng);
        this.marker.lat = this.currentMovingLatLng.lat;
        this.marker.lng = this.currentMovingLatLng.lng;

        // Reset interval if set
        if (this.stageInterval) {
          clearInterval(this.stageInterval);
        }
        if (this.marker.currentStage.type === 'move_to') {
          // hit move to
          this.setupMoving();
        }
        // Fade in
        if (this.marker.currentStage.type === 'fade_in') {
          this.fadeIn(this.marker.currentStage.duration);
        }
        // Fade out
        if (this.marker.currentStage.type === 'fade_out') {
          this.fadeOut(this.marker.currentStage.duration);
        };

        this.stageInterval = setInterval(() => {
          if (this.marker.currentStage.duration > 0) {
            if (this.marker.currentStage.type === 'move_to') {
              this.currentMovingLatLng.lng += this.movingAnimation.stepLng;
              this.currentMovingLatLng.lat = (this.currentMovingLatLng.lng - this.movingAnimation.startLatLng.lng) *
                (this.movingAnimation.lineVector.lat / this.movingAnimation.lineVector.lng) + this.movingAnimation.startLatLng.lat;

              this.marker.lat = this.currentMovingLatLng.lat;
              this.marker.lng = this.currentMovingLatLng.lng;
            }

            // counting interval
            this.marker.currentStage.duration -= this.intervalStep;
          } else {
            this.marker.currentStageOrder++;
            if (!this.marker.moving_stages[this.marker.currentStageOrder]) {
              this.marker.currentStageOrder = 0;

              if (!this.marker.moving_stages[this.marker.currentStageOrder]) {
                this.marker.play_status = 'stop';
                clearInterval(this.stageInterval);
                return;
              } else {
                clearInterval(this.movingInterval);
                clearInterval(this.stageFadeInterval);

                this.currentMovingLatLng = _.cloneDeep(this.marker.latLng);
                this.marker.lat = this.currentMovingLatLng.lat;
                this.marker.lng = this.currentMovingLatLng.lng;
              }
            }
            this.marker.currentStage = _.cloneDeep(this.marker.moving_stages[this.marker.currentStageOrder]);
            if (this.marker.currentStage.type === 'move_to') {
              // hit move to
              this.setupMoving();
            }
            // Fade in
            if (this.marker.currentStage.type === 'fade_in') {
              this.fadeIn(this.marker.currentStage.duration);
            }
            // Fade out
            if (this.marker.currentStage.type === 'fade_out') {
              this.fadeOut(this.marker.currentStage.duration);
            }
          }
        }, this.intervalStep);
      } else {
        this.$refs.movingMarker.mapObject.setOpacity(1);
        this.currentMovingLatLng = _.cloneDeep(this.marker.latLng);

        clearInterval(this.stageInterval);
        clearInterval(this.stageFadeInerval);
      }
    },
    movingLatLng: {
      deep: true,
      handler() {

      }
    }

  },
  mounted() {
    this.currentMovingLatLng = _.cloneDeep(this.marker.latLng);
    this.marker.currentStage = _.cloneDeep(this.marker.moving_stages[0]);
    this.marker.play_status = 'play';
  },
  beforeDestroy() {
    clearInterval(this.stageInterval);
    clearInterval(this.movingInterval);
    clearInterval(this.stageFadeInerval);
    this.marker.play_status = 'stop';
  },
  methods: {
    ...mapActions({
      updateMovingStage: 'MovingStageStore/updateStage'

    }),
    setBoundOverlay(marker) {
      return [
        [parseFloat(marker.lat) - marker.marker.size_h / 2, parseFloat(marker.lng) - marker.marker.size_w / 2],
        [parseFloat(marker.lat) + marker.marker.size_h / 2, parseFloat(marker.lng) + marker.marker.size_w / 2]
      ];
    },
    setupMoving() {
      this.movingAnimation.startLatLng = _.cloneDeep(this.currentMovingLatLng);
      this.movingAnimation.duration = this.marker.currentStage.duration;
      this.movingAnimation.endLatLng = this.marker.currentStage.stop;

      this.movingAnimation.lineVector = {
        lat: this.movingAnimation.endLatLng.lat - this.movingAnimation.startLatLng.lat,
        lng: this.movingAnimation.endLatLng.lng - this.movingAnimation.startLatLng.lng
      };
      this.movingAnimation.duration = this.movingAnimation.duration / this.intervalStep;
      this.movingAnimation.stepLng = (this.movingAnimation.endLatLng.lng - this.movingAnimation.startLatLng.lng) /
        this.movingAnimation.duration;
    },
    fadeIn(duration) {
      if (this.stageFadeInerval) {
        clearInterval(this.stageFadeInerval);
      }
      this.stageFadeDuration = duration;

      this.stageFadeInerval = setInterval(() => {
        if (this.$refs.movingMarker && this.$refs.movingMarker.mapObject.options.opacity < 1) {
          this.$refs.movingMarker.mapObject.setOpacity(this.$refs.movingMarker.mapObject.options.opacity + 100 / this.stageFadeDuration);
        } else {
          clearInterval(this.stageFadeInerval);
          try {
            this.$refs.movingMarker.mapObject.options.opacity = 1;
          } catch (e) {

          }
        }
      }, 100);
    },
    fadeOut(duration) {
      if (this.stageFadeInerval) {
        clearInterval(this.stageFadeInerval);
      }
      this.stageFadeDuration = duration;
      this.stageFadeInerval = setInterval(() => {
        if (this.$refs.movingMarker && this.$refs.movingMarker.mapObject.options.opacity > 0) {
          this.$refs.movingMarker.mapObject.setOpacity(this.$refs.movingMarker.mapObject.options.opacity - 100 / this.stageFadeDuration);
        } else {
          clearInterval(this.stageFadeInerval);
          this.marker.currentStage = _.cloneDeep(this.marker.moving_stages[this.marker.currentStageOrder]);
          if (this.marker.currentStage.type === 'move_to') {
            // hit move to
            this.moveTo(this.marker.currentStage.stop, this.marker.currentStage.duration);
          }
          // Fade in
          if (this.marker.currentStage.type === 'fade_in') {
            if (this.stageFadeInerval) {
              clearInterval(this.stageFadeInerval);
            }
            this.stageFadeDuration = _.cloneDeep(this.marker.currentStage.duration);

            this.stageFadeInerval = setInterval(() => {
              if (this.$refs.movingMarker && this.$refs.movingMarker.mapObject.options.opacity < 1) {
                this.$refs.movingMarker.mapObject.setOpacity(this.$refs.movingMarker.mapObject.options.opacity + 100 / this.stageFadeDuration);
              } else {
                clearInterval(this.stageFadeInerval);
                try {
                  this.$refs.movingMarker.mapObject.options.opacity = 1;
                } catch (e) {

                }
              }
            }, 100);
          }
          // Fade out
          if (this.marker.currentStage.type === 'fade_out') {
            if (this.stageFadeInerval) {
              clearInterval(this.stageFadeInerval);
            }
            this.stageFadeDuration = _.cloneDeep(this.marker.currentStage.duration);
            this.stageFadeInerval = setInterval(() => {
              if (this.$refs.movingMarker && this.$refs.movingMarker.mapObject.options.opacity > 0) {
                this.$refs.movingMarker.mapObject.setOpacity(this.$refs.movingMarker.mapObject.options.opacity - 100 / this.stageFadeDuration);
              } else {
                clearInterval(this.stageFadeInerval);
                try {
                  this.$refs.movingMarker.mapObject.options.opacity = 0;
                } catch (e) {

                }
              }
            }, 100);
          };
        }
      }, 100);
    }

  }

};
</script>

<style scoped>

</style>
