import L, { latLng } from 'leaflet';
import _ from 'lodash';
import MarkerHelper from '../helpers/MarkerHelper';

export const namespaced = true;
export const state = {
  current_map: {},
  current_map_level: {},
  level_markers: [],
  spots: [],
  current_zoom: null,
  location_level: null,
  current_center: null,
  current_geo_center: [0, 0],
  current_location: null,
  current_geo_location: null,
  loading_map: false
};

export const mutations = {
  SET_CURRENT_MAP(state, map) {
    state.current_map = map;
  },
  SET_CURRENT_ZOOM(state, zoomLevel) {
    state.current_zoom = zoomLevel;
  },
  SET_LOCATION_LEVEL(state, locationLevel) {
    state.location_level = locationLevel;
  },
  SET_CURRENT_CENTER(state, center) {
    state.current_center = center;
  },
  SET_CURRENT_GEO_CENTER(state, center) {
    state.current_geo_center = center;
  },
  SET_CURRENT_LOCATION(state, location) {
    state.current_location = location;
  },

  SET_CURRENT_GEO_LOCATION(state, location) {
    state.current_geo_location = location;
  },

  SET_LOADING_MAP(state, loadingMap) {
    state.loading_map = loadingMap;
  },

  SET_CURRENT_MAP_LEVEL(state, mapLevel) {
    // Processing data for set current Map Level
    mapLevel.bounds = [
      [mapLevel.max_bounds_tl_lat, mapLevel.max_bounds_tl_lng],
      [mapLevel.max_bounds_br_lat, mapLevel.max_bounds_br_lng]
    ];

    mapLevel.center = latLng(mapLevel.center_lat, mapLevel.center_lng);
    mapLevel.url = mapLevel.tiles_link;
    mapLevel.currentZoom = mapLevel.default_zoom;
    mapLevel.currentCenter = latLng(mapLevel.center_lat, mapLevel.center_lng);
    mapLevel.maxZoom = mapLevel.max_zoom;
    mapLevel.minZoom = mapLevel.min_zoom;

    state.current_map_level = mapLevel;

    // Set current Zoom
    if (!state.current_center) {
      state.current_center = state.current_map_level.center;
    }
  },

  SET_CURRENT_MAP_LEVEL_BY_ZOOM(state, zoomLevel) {
    state.current_map.zoom_config.forEach((zoomConfig) => {
      if (zoomConfig.zoom_level === zoomLevel) {
        state.location_level = zoomConfig.location_level;

        state.current_map.map_levels.forEach((mapLevel) => {
          if (mapLevel.id === zoomConfig.map_level_id) {
            mapLevel.bounds = [
              [mapLevel.max_bounds_tl_lat, mapLevel.max_bounds_tl_lng],
              [mapLevel.max_bounds_br_lat, mapLevel.max_bounds_br_lng]
            ];

            mapLevel.center = latLng(mapLevel.center_lat, mapLevel.center_lng);
            mapLevel.url = mapLevel.tiles_link;
            mapLevel.currentCenter = latLng(mapLevel.center_lat, mapLevel.center_lng);

            state.current_map_level = mapLevel;
          }
        });
      }
    });
  },

  PUSH_LEVEL_MARKER(state, levelMarker) {
    state.level_markers.push(levelMarker);
  }

};

export const getters = {
  currentMap() {
    return state.current_map;
  },
  loadingMap() {
    return state.loading_map;
  },

  currentMapLevel() {
    return state.current_map_level;
  },
  allLevelMarkers() {
    return state.level_markers;
  },
  currentZoom() {
    return state.current_zoom;
  },
  currentCenter() {
    return state.current_center;
  },
  currentGeoCenter() {
    return state.current_geo_center;
  },
  currentLocation() {
    return state.current_location;
  },
  currentGeoLocation() {
    return state.current_geo_location;
  },
  locationLevel() {
    return state.location_level;
  }
};

export const actions = {
  setCurrentMap({ commit }, currentMap) {
    currentMap.map_levels.forEach((mapLevel) => {
      mapLevel.level_markers.forEach((levelMarker) => {
        levelMarker.latLng = latLng(levelMarker.lat, levelMarker.lng);
        if (levelMarker.marker.type === 'round') {
          // eslint-disable-next-line no-undef
          levelMarker.icon = MarkerHelper.createIcon(levelMarker, 'round');
        } else if (levelMarker.marker.type === 'basic') {
          // eslint-disable-next-line no-undef
          levelMarker.icon = MarkerHelper.createIcon(levelMarker, 'basic');
        } else if (levelMarker.marker.type === 'terrane') {
          // eslint-disable-next-line no-undef
          levelMarker.imageUrl = (levelMarker.marker.icon) ? levelMarker.marker.icon.link : process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';
          // Fetch Advance Marker
        } else if (levelMarker.marker.type === 'advance') {
          // eslint-disable-next-line no-undef
          levelMarker.icon = MarkerHelper.createIcon(levelMarker, 'basic');
          // eslint-disable-next-line no-undef
          levelMarker.mainIcon = MarkerHelper.createIcon(levelMarker, 'dot');
          levelMarker.ref_latLng = latLng(levelMarker.ref_lat, levelMarker.ref_lng);
        } else if (levelMarker.marker.type === 'moving') {
          // eslint-disable-next-line no-undef
          levelMarker.imageURL = (levelMarker.marker.icon) ? levelMarker.marker.icon.link : process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';

          levelMarker.icon = MarkerHelper.createIcon(levelMarker, 'basic');
          // eslint-disable-next-line no-undef
          levelMarker.mainIcon = MarkerHelper.createIcon(levelMarker, 'dot');
          levelMarker.ref_latLng = latLng(levelMarker.ref_lat, levelMarker.ref_lng);
          const movingStages = [];
          let order = 1;
          Object.values(levelMarker.marker.moving_stages).forEach((stage) => {
            stage.stop = latLng(stage.stop_lat, stage.stop_lng);
            stage.order = order;

            let iconUrl = (levelMarker.marker.icon) ? levelMarker.marker.icon.link : process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';
            if (stage.icon && stage.icon_url) {
              iconUrl = stage.icon_url;
            }
            // eslint-disable-next-line no-undef
            stage.icon = L.divIcon({
              iconUrl: iconUrl,
              shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
              iconAnchor: [15, 15],
              shadowAnchor: [22, 38],
              iconSize: [30, 30],
              html: '<div class="stage-icon"><span>' + order + '</span></div>'
            });
            stage.markerIcon = L.icon({
              iconUrl: iconUrl,

              shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
              iconSize: [levelMarker.marker.size_w, levelMarker.marker.size_h],
              iconAnchor: [levelMarker.marker.icon_anchor_lat, levelMarker.marker.icon_anchor_lng],
              shadowSize: [levelMarker.marker.shadow_size_w, levelMarker.marker.shadow_size_h],
              shadowAnchor: [22, 38]
            });
            movingStages.push(_.cloneDeep(stage));
            order++;
          });
          levelMarker.moving_stages = movingStages;
          levelMarker.play_status = stop;
          levelMarker.currentStage = _.cloneDeep(levelMarker.moving_stages[0]);
          levelMarker.currentStageOrder = 0;
        }
        levelMarker.type = levelMarker.marker.type;
        levelMarker.zIndex = levelMarker.marker.spot.z_index;

        // Calculating GEO Lat Lng
        if (currentMap.x_lat_ratio && currentMap.y_lng_ratio) {
          levelMarker.geo_lat_lng = convertXYtoLatLng(levelMarker.latLng, currentMap);

          if (levelMarker.type === 'advance') {
            levelMarker.ref_geo_lat_lng = convertXYtoLatLng(levelMarker.ref_latLng, currentMap);
          }
        }
        commit('PUSH_LEVEL_MARKER', levelMarker);
      });
    });
    commit('SET_CURRENT_MAP', currentMap);

    commit('SET_CURRENT_MAP_LEVEL', currentMap.map_levels[0]);
    commit('SET_CURRENT_MAP_LEVEL_BY_ZOOM', currentMap.default_zoom);
    commit('SET_CURRENT_ZOOM', currentMap.default_zoom);
  },
  setCurrentCenter({ commit }, center) {
    commit('SET_CURRENT_CENTER', center);
  },
  setCurrentGeoCenter({ commit }, center) {
    commit('SET_CURRENT_GEO_CENTER', center);
  },
  setCurrentLocation({ commit }, location) {
    commit('SET_CURRENT_LOCATION', location);
  },
  setCurrentGeoLocation({ commit }, location) {
    commit('SET_CURRENT_GEO_LOCATION', location);
  },

  setCurrentMapLevel({ commit }, mapLevel) {
    return commit('SET_CURRENT_MAP_LEVEL', mapLevel);
  },
  setCurrentMapLevelByZoom({ commit, state }, zoomLevel) {
    if (state.current_zoom !== zoomLevel) {
      commit('SET_CURRENT_ZOOM', zoomLevel);
      return commit('SET_CURRENT_MAP_LEVEL_BY_ZOOM', zoomLevel);
    }
  },
  convertLatLngToXY({ state }, latLngLocation) {
    let rootLat = state.current_map.root_lat;
    let rootLng = state.current_map.root_lng;
    let xLatRatio = state.current_map.x_lat_ratio;
    let yLngRatio = state.current_map.y_lng_ratio;
    if (state.current_map.marked_spots && state.current_map.marked_spots.length > 2) {
      const distances = [];
      state.current_map.marked_spots.forEach((markedSpot) => {
        const distance = distanceLatLng(latLngLocation.lat, latLngLocation.lng, markedSpot.geo[0], markedSpot.geo[1]);
        distances.push({
          distance,
          point: markedSpot
        });
      });
      distances.sort((a, b) => {
        if (a.distance > b.distance) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        return 0;
      });

      const ratios = getRatios(distances[0].point, distances[1].point);
      rootLat = ratios.root_lat;
      rootLng = ratios.root_lng;
      xLatRatio = ratios.x_lat_ratio;
      yLngRatio = ratios.y_lng_ratio;
    }

    const currentLat = degreeToRadian(latLngLocation.lat - rootLat) * xLatRatio;

    const currentLng = degreeToRadian(latLngLocation.lng - rootLng) * yLngRatio;

    return { lat: currentLat, lng: currentLng };
  },
  convertXYtoLatLng({ state }, XYLatLng) {
    return convertXYtoLatLng(XYLatLng, state.current_map);
  },
  getGPSLocation({ commit }) {
    if (navigator.geolocation) {
      // const self = this;

      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, function error() {
          commit('SET_LOADING_MAP', false);
        });
      }).then(async(coords) => {
        return { lat: coords.coords.latitude, lng: coords.coords.longitude };
      });
    } else {
      return false;
    }
  },
  setLoadingMap({ commit }, loadingMap) {
    commit('SET_LOADING_MAP', loadingMap);
  }
};

// Helper Functions
function convertXYtoLatLng(latLngLocation, currentMap) {
  let rootLat = currentMap.root_lat;
  let rootLng = currentMap.root_lng;
  let xLatRatio = currentMap.x_lat_ratio;
  let yLngRatio = currentMap.y_lng_ratio;
  if (currentMap.marked_spots && currentMap.marked_spots.length > 2) {
    const distances = [];
    currentMap.marked_spots.forEach((markedSpot) => {
      const distance = distanceXY(latLngLocation.lat, latLngLocation.lng, markedSpot.xy[0], markedSpot.xy[1]);
      distances.push({
        distance,
        point: markedSpot
      });
    });
    distances.sort((a, b) => {
      if (a.distance > b.distance) {
        return 1;
      }
      if (a.distance < b.distance) {
        return -1;
      }
      return 0;
    });

    const ratios = getRatios(distances[0].point, distances[1].point);
    rootLat = ratios.root_lat;
    rootLng = ratios.root_lng;
    xLatRatio = ratios.x_lat_ratio;
    yLngRatio = ratios.y_lng_ratio;
  }

  const currentLat = radianToDegree(degreeToRadian(rootLat) + latLngLocation.lat / xLatRatio);
  const currentLng = radianToDegree(degreeToRadian(rootLng) + latLngLocation.lng / yLngRatio);

  return latLng(currentLat, currentLng);
}

function degreeToRadian(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}
function radianToDegree(radian) {
  const pi = Math.PI;
  return radian * (180 / pi);
}

function distanceXY(lat1, lon1, lat2, lon2) {
  return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lon2 - lon1, 2));
}

function distanceLatLng(lat1, lon1, lat2, lon2) {
  const theta = lon1 - lon2;
  const dist = Math.sin(degreeToRadian(lat1)) * Math.sin(degreeToRadian(lat2)) +
      Math.cos(degreeToRadian(lat1)) * Math.cos(degreeToRadian(lat2)) * Math.cos(degreeToRadian(theta));
  return radianToDegree(Math.acos(dist));
}

function getRatios(mark, secMark) {
  const xLatRatio = (mark.xy[0] - secMark.xy[0]) /
      degreeToRadian(mark.geo[0] - secMark.geo[0]);

  const rootLat = radianToDegree(degreeToRadian(mark.geo[0]) - mark.xy[0] / xLatRatio);

  const yLngRatio = (mark.xy[1] - secMark.xy[1]) /
      degreeToRadian(mark.geo[1] - secMark.geo[1]);

  const rootLng = radianToDegree(degreeToRadian(mark.geo[1]) - mark.xy[1] / yLngRatio);
  return {
    x_lat_ratio: xLatRatio,
    y_lng_ratio: yLngRatio,
    root_lat: rootLat,
    root_lng: rootLng
  };
}
