import BaseApi from './BaseApi';

class MenuApi extends BaseApi {
  localityApiUrl = this.baseUrl + '/api/locality';

  getMenuByPackKey(key) {
    return this.axios.get(this.localityApiUrl + '/menu' + `/${key}`);
  }
}

export default new MenuApi();
