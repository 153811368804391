import CategoryApi from '../api/CategoryApi';
export const namespaced = true;
export const state = {
  categories: [],
  categories_tree: [],
  select_popup: false
};

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CATEGORIES_TREE(state, categoriesTree) {
    state.categories_tree = categoriesTree;
  },
  SET_SELECT_POPUP(state, status) {
    state.select_popup = status;
  }
};
export const getters = {
  categories() {
    return state.categories;
  },
  categoriesTree() {
    return state.categories_tree;
  },
  selectPopup() {
    return state.select_popup;
  }
};

export const actions = {
  async getCategories({ commit }) {
    const response = await CategoryApi.index();
    const categories = response.data;
    commit('SET_CATEGORIES', categories);
  },
  async getCategoriesTree({ commit }) {
    const response = await CategoryApi.getCategoriesTree();
    const categoriesTree = response.data;
    commit('SET_CATEGORIES_TREE', categoriesTree);
  },
  setSelectPopup({ commit }, status) {
    commit('SET_SELECT_POPUP', status);
  }
};
