<template>
    <div>
        <div class="login-header">
            <a href="/">
                <img alt="locality logo" :src="baseUrl + '/storage/logo.png'" class="logo">
            </a>
        </div>
        <div class="login-body">
            <div class="id-login">
                <div class="item">
                    <p class="title">{{ $t('frontend.login.id_or_email_title') }}</p>
                </div>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(authenticate)">
                        <div class="item">
                            <validation-provider
                                :rules="'required|email|regex:^[a-zA-Z0-9_.+-]+@(?!-)[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'"
                                :custom-messages="{
                                    required: $t('frontend.login.validate.email_required'),
                                    email: $t('frontend.login.validate.email_email'),
                                    regex: $t('frontend.login.validate.email_email')
                                }"
                                v-slot="{ errors }"
                            >
                                <input type="text" v-model="form.email" :placeholder="$t('frontend.login.id_or_email')" @click="removeLoginError" @keyup="trimEmail()">
                                <span v-if="loginError" class="error-message">{{ $t('frontend.login.error') }}</span>
                                <span class="error-message">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="item">
                            <validation-provider
                                :rules="'required'"
                                :custom-messages="{
                                    required: $t('frontend.login.validate.password_required')
                                }"
                                v-slot="{ errors }"
                            >
                                <input type="password" v-model="form.password" :placeholder="$t('Password')" @click="removeLoginError">
                                <span class="error-message">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="item">
                            <button type="submit" class="btn-submit" @click="removeLoginError">{{ $t('login')}}</button>
                        </div>
                    </form>
                </ValidationObserver>
                <div class="item register-link">
                    <a href="/frontend/reporter/forgot-password" >{{ $t('frontend.login.forgot_password') }}</a>
                </div>
            </div>
            <div class="login-separator">
                <div class="separator">
                    <span>or</span>
                </div>
            </div>
            <div class="social-login">
                <div class="item">
                    <p class="title">{{ $t('frontend.login.social_title') }}</p>
                </div>
                <div class="item">
                    <img alt="locality login facebook" :src="baseUrl + '/locality/images/login-button-facebook.svg'" class="login-button" @click="socialLogin('facebook')">
                </div>
                <div class="item">
                    <img alt="locality login google" :src="baseUrl + '/locality/images/login-button-google.svg'" class="login-button" @click="socialLogin('google')">
                </div>
                <div class="item">
                    <img alt="locality login instagram" :src="baseUrl + '/locality/images/login-button-instagram.svg'" class="login-button" @click="socialLogin('instagram')">
                </div>
                <div class="item">
                    <img alt="locality login twitter" :src="baseUrl + '/locality/images/login-button-twitter.svg'" class="login-button mb-3" @click="socialLogin('twitter')">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);
extend('regex', regex);

export default {
  name: 'Login',
  props: [
    'mapInfo'
  ],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      form: {
        email: '',
        password: ''
      },
      loginError: false
    };
  },
  created() {
    if (this.$auth.check()) {
      this.$router.push('/');
    }
  },
  methods: {
    socialLogin(type) {
      const redirect = process.env.MIX_APP_URL;
      const socialLoginUrl = process.env.MIX_APP_URL + '/login/' + type + '?redirect=' + redirect;
      window.location = socialLoginUrl;
    },
    async authenticate() {
      try {
        const result = await this.$auth.reporterLogin({
          email: this.form.email,
          password: this.form.password
        });

        if (result.status === 'success') {
          window.location.href = this.baseUrl;
        } else {
          this.loginError = true;
        }
      } catch (error) {
        this.loginError = true;
      }
    },
    removeLoginError() {
      this.loginError = false;
    },
    trimEmail() {
      const email = this.form.email;
      this.form.email = email.trim();
    }
  }
};
</script>

<style scoped src="../../sass/pages/login.css"></style>
