import BaseApi from './BaseApi';

class SlideApi extends BaseApi {
  localityApiUrl = this.baseUrl + '/api/locality';

  getSlideByPackKey(key) {
    return this.axios.get(this.localityApiUrl + '/slide' + `/${key}`);
  }
}

export default new SlideApi();
