<template>
    <div class="geomark" v-show="showMap">
        <visual-map
            v-if="currentMapLevel && currentMapLevel.source_type === 'local'"
        ></visual-map>
        <open-street-map
        ></open-street-map>
        <slot></slot>
        <div id="loading-map" v-if="loadingMap">
            <img src="/locality/images/loading.gif" alt="">
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import 'leaflet/dist/leaflet.css';
import VisualMap from '../components/VisualMap';
import OpenStreetMap from '../components/OpenStreetMap';
export default {
  name: 'LocalityMapPage',
  props: [
    'mapInfo'
  ],
  components: {
    VisualMap,
    OpenStreetMap
  },
  computed: {
    ...mapGetters({
      currentMap: 'MapStore/currentMap',
      currentMapLevel: 'MapStore/currentMapLevel',
      post: 'PostStore/getPosts',
      showMap: 'PageStore/showMap',
      loadingMap: 'MapStore/loadingMap'
    })
  },
  data() {
    return {};
  },
  async created() {
    this.setCurrentMap(this.mapInfo);
  },
  methods: {
    ...mapActions({
      getMapInfo: 'MapStore/getMapInfo',
      setCurrentMap: 'MapStore/setCurrentMap',
      getPosts: 'PostStore/getPosts'
    }),
    showPostDetail(post) {
      this.$emit('show-post-detail', post);
    }
  }
};
</script>

<style scoped>
.geomark {
    background: green;
    margin-top: 56px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}
div#loading-map {
    position: absolute;
    top: 0;
    width: 100%;
    background: #ffffffbd;
    height: 100%;
}

div#loading-map img {
    vertical-align: bottom;
    display: inline-block;
    width: 30%;
    margin-top: 33%;
}

@media (min-width: 1024px) {
    .geomark {
        height: 100vh;
        margin-top: 0;
        order: 2;
        z-index: 0;
        width: 58vw;
        padding-top: 80px;
        padding-bottom: 50px;
        right: 0;
    }
}
</style>
