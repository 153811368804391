<template>
    <div>
        <Header
            @show-login="showLogin()"
        />
        <main>
            <LocalityMapPage
                :map-info="mapInfo"
            >
            <Banner/>
            </LocalityMapPage>
            <Article :articles="posts"/>
        </main>
        <NavBar/>
        <select-category :is-select="false"></select-category>
        <Footer/>
    </div>
</template>

<script>
import LocalityMapPage from './LocalityMapPage';
import SelectCategory from '../components/SelectCategory';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Topics',
  props: [
    'mapInfo'
  ],
  components: { LocalityMapPage, SelectCategory },
  computed: {
    ...mapGetters({
      posts: 'PostStore/posts',
      popupStatus: 'PopupStore/popupStatus',
      isShowSetting: 'SubcribeStore/isShowSetting'
    })
  },
  data() {
    return {
      spotDetail: null,
      vh: null
    };
  },
  methods: {
    ...mapActions({
      setCurrentPage: 'PageStore/setCurrentPage',
      setCurrentPageByUrl: 'PageStore/setCurrentPageByUrl',
      changePostType: 'PostStore/changePostType',
      openPopup: 'PopupStore/openPopup',
      closePopup: 'PopupStore/closePopup',
      setPopupStatus: 'PopupStore/setPopupStatus',
      setPopupPost: 'PostStore/setPopupPost',
      clickPost: 'PostStore/clickPost'
    }),
    showLogin() {
      this.$router.push('/frontend/login');
    },
    async showPostDetail(postId) {
      await this.setPopupPost(postId);
      await this.clickPost(postId);
      this.setPopupStatus('true');
    },
    resizeHeight(e) {
      this.vh = e.currentTarget.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
      console.log(this.vh);
    }
  },
  created() {
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    window.addEventListener('resize', this.resizeHeight);
  },
  async mounted() {
    if (this.$auth.check()) {
      await this.setCurrentPageByUrl({
        urlPath: this.$route.path,
        userId: this.$auth.user().id
      });
    } else {
      await this.setCurrentPageByUrl({
        urlPath: this.$route.path
      });
    }
    if (this.$route.params.spot_id) {
      const postId = parseInt(this.$route.params.spot_id);
      await this.showPostDetail(postId);
    }

    if (this.$auth.check()) {
      if (parseInt(sessionStorage.getItem('spotLikeId'))) {
        await this.showPostDetail(parseInt(sessionStorage.getItem('spotLikeId')));
      }
    }
  }
};
</script>
