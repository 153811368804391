<template>
  <l-marker :lat-lng="levelMarker.geo_lat_lng"
            :icon="levelMarker.icon"
            :draggable="false"
            :z-index-offset="zIndexOffset"
            @click="markerClick(levelMarker)"
            @update:lat-lng="updateLatLng($event)"
  >
  </l-marker>
</template>
<script>

import { LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'RoundMarker',
  components: {
    LMarker
  },
  props: [
    'levelMarker',
    'zIndexOffset',
    'sourceType',
    'currentMap'
  ],
  watch: {
    levelMarker: {
      deep: true,
      handler(newValue, oldValue) {
        this.levelMarker.icon.options.iconSize[1] = newValue.icon.options.iconSize[0];
        this.levelMarker.icon.options.iconAnchor[0] = this.levelMarker.icon.options.iconSize[0] / 2;
        this.levelMarker.icon.options.iconAnchor[1] = this.levelMarker.icon.options.iconSize[1] + 10;
      }
    }
  },
  created() {
  },
  methods: {
    markerClick(marker) {
      this.$emit('round-marker-click', marker);
    },
    updateLatLng(evt) {
      // this.$emit('update-round-latlng', evt);
    }

  }
};
</script>
