import BaseApi from './BaseApi';

class CommentApi extends BaseApi {
  getComments(postId) {
    return this.axios.get(this.baseUrl + '/api/locality/post/' + postId + '/comments');
  }

  createComment(postId, commentData) {
    return this.axios.post(this.baseUrl + '/api/locality/post/' + postId + '/comments/create', commentData);
  }

  toggleLike(commentId, userId) {
    return this.axios.post(this.baseUrl + '/api/locality/post/comments/' + commentId + '/toggle-like', {
      user_id: userId
    });
  }
}

export default new CommentApi();
