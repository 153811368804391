<template>
    <div>
        <l-marker
            v-if="userCurrentLocation"
            :lat-lng="userCurrentLocation"
            :icon="currentLocationIcon"
            :z-index-offset="10000000"
            :draggable="false"
        />
    </div>
</template>

<script>
import { icon } from 'leaflet';
import { LMarker } from 'vue2-leaflet';
export default {
  name: 'CurrentLocationMarker',
  components: {
    LMarker
  },
  props: ['userCurrentLocation'],
  data() {
    return {
      currentLocationIcon: icon({
        iconUrl: process.env.MIX_API_URL + '/locality/images/current_marker.png',
        shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
        iconSize: [40, 70],
        iconAnchor: [15, 70],
        shadowSize: [68, 77],
        shadowAnchor: [20, 77]
      })
    };
  },
  created() {
  }
};
</script>

<style scoped>

</style>
