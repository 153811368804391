import PageApi from '../api/PageApi';
export const namespaced = true;

export const state = {
  current_page: '',
  show_map: true,
  urls: {
    '/topics': 'post_topic',
    '/myloca': 'my_location'
  }
};

export const mutations = {
  SET_CURRENT_PAGE(state, page) {
    state.current_page = page;
  },
  SET_SHOW_MAP(state, show) {
    state.show_map = show;
  }
};

export const getters = {
  currentPage() {
    return state.current_page;
  },
  showMap() {
    return state.show_map;
  }
};

export const actions = {
  setCurrentPage({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  changeShowMap({ commit }, show) {
    commit('SET_SHOW_MAP', show);
  },
  async setCurrentPageByUrl({ commit, dispatch }, { urlPath, userId = null }) {
    if (state.urls[urlPath]) {
      const currentPage = state.urls[urlPath];
      commit('SET_CURRENT_PAGE', currentPage);
      dispatch('PostStore/getPostByPage', {
        page: currentPage,
        userId
      }, { root: true });
    } else {
      commit('SET_CURRENT_PAGE', 'post_topic');
    }
  },

  async getPageBySlug({ commit }, slug) {
    const response = await PageApi.getPageBySlug(slug);
    commit('SET_CURRENT_PAGE', response.data);
  }
};
