import BaseApi from './BaseApi';

class ReporterApi extends BaseApi {
  apiUrl = this.baseUrl + '/api/locality/reporter';
  index(page) {
    return self.axios.get(this.baseUrl + '/api/locality/reporter', {
      params: {
        page: page
      }
    });
  }

  getAllReporter() {
    return self.axios.get(this.apiUrl + '/all');
  }

  edit(reporterId) {
    return self.axios.get(this.baseUrl + '/api/locality/reporter/' + reporterId + '/edit');
  }

  findPostByReporterId(reporterId) {
    return self.axios.get(this.baseUrl + '/api/locality/reporter/' + reporterId + '/get-post');
  }

  getPrefecture() {
    return self.axios.get(this.baseUrl + '/api/locality/reporter/get-prefecture');
  }

  getMunicipality() {
    return self.axios.get(this.baseUrl + '/api/locality/reporter/get-municipality');
  }

  toggleFollow(reporterId, userId) {
    return this.axios.post(this.apiUrl + '/' + reporterId + '/toggle-follow', {
      user_id: userId
    });
  }

  getStatistic(reporterId) {
    return this.axios.get(this.apiUrl + '/' + reporterId + '/get-statistic');
  }
}

export default new ReporterApi();
