<template>
    <div class="comment-create">
      <img class="comment-img-large" :src="$auth.user().profile_url" alt="">
      <div class="comment-input">
        <input type="text" v-model="newComment.content" placeholder="コメントを追加...">
        <a href="#" @click="submitComment()" class="comment-submit">投稿する</a>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CommentCreate',
  props: [
    'post'
  ],
  data() {
    return {
      newComment: {}
    };
  },
  computed: {
    ...mapGetters({
      comments: 'CommentStore/comments'
    })
  },
  created() {
    // console.log(this.$auth.user());
  },
  methods: {
    ...mapActions({
      createComment: 'CommentStore/createComment',
      updateCommentCount: 'PostStore/updateCommentCount'
    }),
    async submitComment() {
      if (this.newComment.content) {
        this.newComment.post_id = this.post.id;
        const currentUser = this.$auth.user();
        this.newComment.user_id = currentUser.id;
        await this.createComment(this.newComment);

        await this.updateCommentCount({ post_id: this.post.id, comments_count: this.comments.length });

        this.newComment = {};
        // const postContent = $('.post-content')[0];
        // const commentList = $('.comment-list')[0];
        // postContent.scrollTo({ top: commentList.offsetHeight + window.scrollY, behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
img.comment-img-large {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  margin-right: 15px;
  object-fit: contain;
  border: 1px solid #021B6A;
}
.comment-create {
  padding: 15px 5px;
  background: #fff;
  display: flex;
  position: relative;
  z-index: 999;
  margin-bottom: -18px;
  border-radius: 11px;
}
.comment-input {
  border: 2px solid #eee;
  width: 100%;
  border-radius: 50px;
  padding-left: 10px;
  padding-top: 8px;
  padding-right: 80px;
  position: relative;
}
.comment-input input {
  width: 100%;
  outline: none;
  border: none;
}
a.comment-submit {
  position: absolute;
  top: 8px;
  right: 10px;
}
</style>
