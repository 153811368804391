<template>
    <section>
        <article v-for="reporter in reporters" :key="reporter.id">
          <div class="top_reporter">
            <div class="thumbnail">
                <a :href="'/reporter/'+reporter.id">
                <img v-if="reporter.profile_url" :src="reporter.profile_url" alt="avatar">
                <img v-else :src="baseUrl + '/storage/logo.png'" alt="avatar">
                </a>
            </div>
            <div class="reporter-infomation mb-2" :class="{info2:!$auth.check()}">
                <a :href="'/reporter/'+reporter.id"><h2>{{ reporter.name }}</h2></a>
                <!-- <span class="address">{{ reporter.email }}</span> -->
                <span class="address mb-1" v-show="reporter.reporter_information.prefecture_id">{{ prefecture[reporter.reporter_information.prefecture_id] }} - {{ municipality[reporter.reporter_information.municipality_id] }}</span>
            </div>
          <follow-button :reporter="reporter"></follow-button>
          </div>
            <hr>
              <div class="reporter-profile text-left">{{ reporter.reporter_information.bio }}</div>
        </article>
    </section>
</template>

<script>
import FollowButton from './Reporter/FollowButton';

export default {
  name: 'Reporter',
  props: ['reporters', 'prefecture', 'municipality'],
  components: {
    FollowButton
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../sass/pages/reporter.css"></style>
