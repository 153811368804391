<template>
    <div class="modal fade setting-myloca-modal" id="setting_myloca_modal"
         tabindex="-1" role="dialog"
         aria-labelledby="settingSubscribeModalLabel" aria-hidden="true"
         data-keyboard="false">
        <div class="modal-dialog modal-xl modal-myloca" role="document">
            <section v-show="step === 1" class="modal-content setting-myloca-content setting-myloca-step-1-custom">
                <header>
                    {{ $t('setting_subscribe.site_title')}}
                </header>
                <main class="body_site">
                    <VueSlickCarousel :arrows="true" :dots="true">
                        <div>
                            <img src="/locality/mylocaSlider/image-1.jpg" alt="slider">
                            <div class="body_site-text">
                                <span>
                                    ローカリティ！には、全国のレポーターから寄せられた、知られざる「驚き・発見・感動」が満ち溢れています。
                                    そんなローカリティ！で、いつでもあなたの好みに合ったローカル情報に出会える機能を実装しました。
                                    それが『マイ・ローカリティ！』、略して『マイロカ』です。
                                </span>
                            </div>
                        </div>
                        <div>
                            <img src="/locality/mylocaSlider/image-2.jpg" alt="slider">
                            <div class="body_site-text">
                                <span>
                                    『マイロカ』では、お好きな地域やカテゴリ、レポーターを選んで、フォローできます。
                                    フォロー設定が完了すると、地図はあなたの好みに基づいた情報のみが表示される『マイロカマップ』になります。
                                    また、お気に入りの記事に「いいね」をしたり、コメントを残したりすることもできます。
                                </span>
                            </div>
                        </div>
                        <div>
                            <img src="/locality/mylocaSlider/image-3.jpg" alt="slider">
                            <div class="body_site-text">
                                <span>
                                    お気に入りの地域やトピックスをフォローして、あなたの「好き！」で埋め尽くされた、自分だけの「心のふるさと」を作ろう！
                                    ローカリティ！にログインして『ロカミン』になると、欲しい情報にスムーズにアクセスできる、様々な機能が使えるようになります。
                                </span>
                            </div>
                        </div>
                        <div>
                            <img src="/locality/mylocaSlider/image-4.jpg" alt="slider">
                            <div class="body_site-text">
                                <span>
                                    誰かの「驚き・発見・感動」を通じて、共感しあえる仲間とつながり、好きなものをもっと好きになる ──。
                                    ローカリティ！はそんな世界を目指しています。
                                    さあ、あなたも『ロカミン』になって「驚き・発見・感動」に満ちたローカルライフを楽しみましょう！
                                </span>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </main>
                <footer>
                    <button class="button-next" @click="setStep(2)">{{ $t('setting_subscribe.set_locamine_immediately')}}</button>
                    <button class="button-close" @click="closePopup()">{{ $t('close') }}</button>
                </footer>
            </section>
            <section v-show="step === 2" class="modal-content setting-myloca-content">
                <header class="fs-20">
                    {{ $t('setting_subscribe.locamine_setting')}}
                </header>
                <main>
                    <div class="title">
                        <span class="fs-18">{{ $t('setting_subscribe.step_1.title')}}</span>
                        <div v-if="error === true && step === 2" class="text-danger">{{ $t('setting_subscribe.validation.null') }}</div>
                    </div>
                    <treeselect
                        :options="locationOptions"
                        name="location"
                        v-model="settingData.location"
                        :multiple="true"
                        :value-consists-of="'LEAF_PRIORITY'"
                        :alwaysOpen="true"
                        @select="settingError(false)"
                    ></treeselect>
                </main>
                <footer>
                    <button class="button-next fs-20" @click="setStep(4)">{{ $t('setting_subscribe.next_button') }}</button>
                    <button class="button-close" @click="closePopup()">{{ $t('close') }}</button>
                </footer>
            </section>
            <section v-show="step === 3" class="modal-content setting-myloca-content">
                <header class="fs-20">
                    {{ $t('setting_subscribe.locamine_setting')}}
                </header>
                <main>
                    <div class="title">
                        <span class="fs-18">{{ $t('setting_subscribe.step_2.title')}}</span>
                        <div v-if="error === true && step === 3" class="text-danger">{{ $t('setting_subscribe.validation.null') }}</div>
                    </div>
                    <treeselect
                        :options="categoriesOptions"
                        name="categories"
                        v-model="settingData.categories"
                        :multiple="true"
                        :value-consists-of="'ALL_WITH_INDETERMINATE'"
                        :alwaysOpen="true"
                        @select="settingError(false)"
                    ></treeselect>
                </main>
                <footer>
                    <button class="button-next fs-20" @click="setStep(4)">{{ $t('setting_subscribe.next_button') }}</button>
                    <button class="button-close" @click="closePopup()">{{ $t('close') }}</button>
                </footer>
            </section>
            <section v-show="step === 4" class="modal-content setting-myloca-content">
                <header class="fs-20">
                    {{ $t('setting_subscribe.locamine_setting')}}
                </header>
                <main>
                    <div class="title">
                        <span class="fs-18">{{ $t('setting_subscribe.step_3.title')}}</span>
                        <div v-if="error === true && step === 4" class="text-danger">{{ $t('setting_subscribe.validation.null') }}</div>
                    </div>
                    <treeselect
                        :options="reportersOptions"
                        name="reporters"
                        v-model="settingData.reporters"
                        :multiple="true"
                        :value-consists-of="'ALL_WITH_INDETERMINATE'"
                        :alwaysOpen="true"
                        @select="settingError(false)"
                    ></treeselect>
                </main>
                <footer>
                    <button class="button-next fs-20" @click="setStep(5)">{{ $t('setting_subscribe.next_button') }}</button>
                    <button class="button-close" @click="closePopup()">{{ $t('close') }}</button>
                </footer>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'user.SettingSubcribeModal',
  components: {
    Treeselect,
    VueSlickCarousel
  },
  data() {
    return {
      step: 1,
      locationOptions: [],
      categoriesOptions: [],
      reportersOptions: [],
      error: false,
      isSave: false
    };
  },
  watch: {
    isShowSetting() {
      if (this.isShowSetting) {
        $('#setting_myloca_modal').modal('show');
      }
    }
  },
  computed: {
    ...mapGetters({
      isShowSetting: 'SubcribeStore/isShowSetting',
      settingData: 'SubcribeStore/subcribeSetting',
      locations: 'LocationStore/locations',
      categoriesTree: 'CategoryStore/categoriesTree',
      reporters: 'ReporterStore/allReporter'
    })
  },
  methods: {
    ...mapActions({
      setCurrentPage: 'PageStore/setCurrentPage',
      getLocations: 'LocationStore/getLocations',
      getCategoriesTree: 'CategoryStore/getCategoriesTree',
      getReporters: 'ReporterStore/getAllReporter',
      getPosts: 'PostStore/getPosts',
      changePostType: 'PostStore/changePostType',
      saveSetting: 'SubcribeStore/saveSetting',
      showHideSetting: 'SubcribeStore/showHideSetting'
    }),
    setStep(step) {
      switch (step) {
      case 2:
        this.step = step;
        break;
      case 4:
        if (this.settingData.location.length === 0) {
          this.settingError(true);
        } else {
          this.settingError(false);
          this.step = step;
        }
        break;
      case 3:
        if (this.settingData.categories.length === 0) {
          this.settingError(true);
        } else {
          this.settingError(false);
          this.step = step;
        }
        break;
      case 5:
        if (this.settingData.reporters.length === 0) {
          this.settingError(true);
        } else {
          this.settingError(false);
          this.saveSettingMylocal();
        }
        break;
      }
    },
    settingError(status) {
      this.error = status;
    },
    async saveSettingMylocal() {
      this.step = 1;
      const userId = this.$auth.user().id;
      await this.saveSetting({
        userId,
        data: this.settingData
      });
      await this.getPosts({
        type: 'myloca',
        user_id: this.$auth.user().id
      });
      this.isSave = true;
      $('#setting_myloca_modal').modal('hide');
    },
    resetPopupData() {
      this.step = 1;
      this.error = false;
      this.settingData.reporters = [];
      this.settingData.categories = [];
      this.settingData.location = [];
      this.showHideSetting();
      if (this.$route.name !== 'user.profile') {
        this.changePostType('topic');
        this.setCurrentPage('post_topic');
      }
    },
    closePopup() {
      $('#setting_myloca_modal').modal('hide');
    }
  },
  async mounted() {
    await this.getLocations();
    await this.getCategoriesTree();
    await this.getReporters();
    // set treeselect location options type
    this.locations.forEach((prefecture) => {
      const optionData = {
        id: 'prefecture - ' + prefecture.id,
        label: prefecture.name
      };
      if (prefecture.municipalities) {
        optionData.children = [];
        prefecture.municipalities.forEach((municipalitie) => {
          const optionChildData = {
            id: municipalitie.id,
            label: municipalitie.name
          };
          optionData.children.push(optionChildData);
        });
      }
      this.locationOptions.push(optionData);
    });
    // set treeselect category options type
    this.categoriesTree.forEach((categoryLevel1) => {
      const categoryLevel1Data = {
        id: categoryLevel1.id,
        label: categoryLevel1.name
      };
      if (categoryLevel1.children) {
        categoryLevel1Data.children = [];
        categoryLevel1.children.forEach((categoryLevel2) => {
          const catLevel2Data = {
            id: categoryLevel2.id,
            label: categoryLevel2.name
          };
          if (categoryLevel2.children) {
            catLevel2Data.children = [];
            categoryLevel2.children.forEach((categoryLevel3) => {
              const catLevel3Data = {
                id: categoryLevel3.id,
                label: categoryLevel3.name
              };
              catLevel2Data.children.push(catLevel3Data);
            });
          }
          categoryLevel1Data.children.push(catLevel2Data);
        });
      }
      this.categoriesOptions.push(categoryLevel1Data);
    });
    // set treeselect category options type
    this.reporters.forEach((reporter) => {
      const optionData = {
        id: reporter.id,
        label: reporter.name
      };
      this.reportersOptions.push(optionData);
    });

    $('#setting_myloca_modal').on('hide.bs.modal', event => {
      if (this.isSave === false) {
        this.resetPopupData();
      }
    });
  }
};
</script>

<style scoped>
    #setting_myloca_modal {
        padding-right: 0px !important;
    }

    .modal-myloca {
        max-width: 500px;
    }

    .setting-myloca-content {
        border-radius: 15px;
        padding: 15px;
    }

    .setting-myloca-content header {
        background-color: #001e62;
        color: #ffffff;
        font-weight: bold;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 5px;
        text-align: left;
    }

    .setting-myloca-content main {
        font-weight: bold;
        display: block;
        height: 60vh;
        color: #001e62;
    }

    .setting-myloca-content main .title{
        text-align: left;
        padding: 12px;
    }

    .setting-myloca-content .body_site {
        /*border: 1px solid #001e62;*/
        border-radius: 10px;
    }

    .setting-myloca-content footer {
        margin-top: 10px;
        font-weight: bold;
    }

    footer .button-next {
        width: 100%;
        margin-bottom: 10px;
        background-color: #001e62;
        border: unset;
        color: #ffffff;
        height: 40px;
        border-radius: 20px;
    }

    footer .button-close {
        width: 70%;
        background-color: #999999;
        border: unset;
        border-radius: 20px;
        color: #ffffff;
    }

    .fs-18 {
        font-size: 18px;
    }

    .fs-20 {
        font-size: 20px;
    }
</style>
<style>
    .body_site .slick-next {
        right: 4px;
    }
    .body_site .slick-prev {
        left: 2px;
        z-index: 1;
    }
    .body_site .slick-prev:before, .body_site .slick-next:before {
        color: #212529;
    }
    /*custom treeselect css*/
    .vue-treeselect__menu {
        border: unset;
        box-shadow: unset !important;
        max-height: 45vh !important;
    }

    /*hide select box*/
    .vue-treeselect__control {
        display: none;
    }

    /*always show scrollbar*/
    div::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }
    div::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    @media (min-width:320px) and (max-width: 375px) {
        /*custom treeselect css*/
        .vue-treeselect__menu {
            max-height: 45vh !important;
        }
    }

    @media (max-width: 320px) {
        /*custom treeselect css*/
        .vue-treeselect__menu {
            max-height: 35vh !important;
        }
    }

    /*custome vue carousel*/
    .setting-myloca-modal .slick-slider {
        height: 100%;
    }

    .setting-myloca-modal .slick-slider img {
        height: 100%;
        margin: 0 auto;
    }

    .setting-myloca-modal .slick-slider .slick-list {
        height: 100%;
    }

    .setting-myloca-modal .slick-list div {
        height: 100%;
    }

    .setting-myloca-modal .slick-dots {
        bottom: 10px;
    }

    .slick-dots li button:before {
        font-size: 11px;
        opacity: 1;
        color:#777777;
    }

    .slick-dots li.slick-active button:before{
        opacity: 1;
    }
</style>

<style scoped>
    /*formart height setting myloca step 1*/
    .setting-myloca-step-1-custom main
    , .setting-myloca-step-1-custom .slick-slider
    , .setting-myloca-step-1-custom .slick-slider .slick-list
    , .setting-myloca-step-1-custom .slick-slider img {
        height: unset;
    }

    /*custome setting myloca step 1*/
    .setting-myloca-content .body_site-text {
        color: #ffffff;
        padding: 20px 40px;
        text-align: justify;
        background-color: #091e62;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 190px;
    }

    @media (max-width: 445px) {
        .setting-myloca-content .body_site-text {
            height: 210px;
        }
    }
    @media (max-width: 400px) {
        .setting-myloca-content .body_site-text {
            height: 235px;
        }
    }
    @media (max-width: 373px) {
        .setting-myloca-content .body_site-text {
            height: 260px;
        }
    }
    @media (max-width: 344px) {
        .setting-myloca-content .body_site-text {
            height: 280px;
        }
    }
    @media (max-width: 336px) {
        .setting-myloca-content .body_site-text {
            height: 300px;
        }
    }
</style>
