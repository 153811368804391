import ReporterApi from '../api/ReporterApi';
export const namespaced = true;
export const state = {
  reporters: [],
  all_reporter: [1, 2],
  current_reporter: {},
  reporter_detail: [],
  posts: [],
  page: 1,
  prefecture: [],
  municipality: []
};
export const getters = {
  reporters() {
    return state.reporters;
  },
  allReporter() {
    return state.all_reporter;
  },
  currentReporter() {
    return state.current_reporter;
  },
  reporterDetail() {
    return state.reporter_detail;
  },
  posts() {
    return state.posts;
  },
  prefecture() {
    return state.prefecture;
  },
  municipality() {
    return state.municipality;
  }
};

export const mutations = {
  SET_REPORTERS(state, reporters) {
    state.reporters = reporters;
  },
  SET_ALL_REPORTER(state, allReporter) {
    state.all_reporter = allReporter;
  },
  PUSH_REPORTER(state, reporter) {
    state.reporters.push(reporter);
  },
  SET_REPORTER_DETAIL(state, reporterDetail) {
    state.reporter_detail = reporterDetail;
  },
  SET_POSTS(state, posts) {
    state.posts = posts;
  },
  SET_PREFECTURE(state, prefecture) {
    state.prefecture = prefecture;
  },
  SET_MUNICIPALITY(state, municipality) {
    state.municipality = municipality;
  },
  PUSH_FOLLOWER(state, followData) {
    state.reporters.forEach(reporter => {
      if (reporter.id === followData.reporter_id) {
        reporter.followers.push(followData.follower);
      }
    });
  },
  REMOVE_FOLLOWER(state, followData) {
    state.reporters.forEach(reporter => {
      console.log(reporter);

      if (reporter.id === followData.reporter_id) {
        reporter.followers.forEach(follower => {
          if (follower.user_id === followData.user_id) {
            console.log('Remove follower');
            reporter.followers.splice(reporter.followers.indexOf(follower), 1);
          }
        });
      }
    });
  }
};

export const actions = {
  async getReporters({ commit }) {
    const response = await ReporterApi.index();
    const reporters = response.data;
    commit('SET_REPORTERS', reporters.data);
  },

  async getAllReporter({ commit }) {
    const response = await ReporterApi.getAllReporter();
    const reporters = response.data;
    commit('SET_ALL_REPORTER', reporters);
  },

  async getReporterDetail({ commit }, reporterId) {
    const response = await ReporterApi.edit(reporterId);
    const reporterDetail = response.data;
    commit('SET_REPORTER_DETAIL', reporterDetail);
  },
  async findPostByReporterId({ commit }, reporterId) {
    const response = await ReporterApi.findPostByReporterId(reporterId);
    const posts = response.data;
    commit('SET_POSTS', posts);
  },
  async getPrefecture({ commit }) {
    const response = await ReporterApi.getPrefecture();
    const prefecture = response.data;
    commit('SET_PREFECTURE', prefecture);
  },
  async getMunicipality({ commit }) {
    const response = await ReporterApi.getMunicipality();
    const municipality = response.data;
    commit('SET_MUNICIPALITY', municipality);
  },
  syncReporter({ state }, reporter) {
    let syncReporter = null;
    state.reporters.forEach(existedReporter => {
      if (existedReporter.id === reporter.id) {
        syncReporter = existedReporter;
      }
    });
    if (!syncReporter) {
      state.reporters.push(reporter);
      syncReporter = reporter;
    }
    return syncReporter;
  },
  async toggleFollow({ commit }, followData) {
    const response = await ReporterApi.toggleFollow(followData.reporter_id, followData.user_id);
    const result = response.data;

    if (result.status === 'followed') {
      followData.follower = result.follower;
      followData.status = result.status;
      commit('PUSH_FOLLOWER', followData);
    } else {
      commit('REMOVE_FOLLOWER', followData);
    }
    return followData;
  },
  infiniteHandler({ state, commit }, $state) {
    ReporterApi.index(state.page).then(({ data }) => {
      if (data.data.length) {
        state.page += 1;
        state.reporters.push(...data.data);
        $state.loaded();
      } else {
        $state.complete();
      }
    });
  },
  async getStatistic({ commit }, reporterId) {
    const response = await ReporterApi.getStatistic(reporterId);
    return response.data;
  }
};
