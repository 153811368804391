<template>
  <div />
</template>
<script>

export default {
  name: 'SocialCallbackPage',
  async mounted() {
    // const id = this.$route.query.user;
    // const token = this.$route.query.token;
    // if (token) {
    // const response = await this.$auth
    //   .login({
    //     data: {
    //       token: token,
    //       id: id
    //     },
    //     remember: 'user',
    //     staySignedIn: true,
    //     fetchUser: true
    //   });
    // this.$router.push('/');
    // }
  }
};
</script>
<style scoped>
</style>
