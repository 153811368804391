<template>
  <div>
    <Header />
    <main>
      <LocalityMapPage
        :map-info="mapInfo"
      >
        <Banner />
      </LocalityMapPage>
      <Article :articles="articles"/>
    </main>
    <NavBar :page="3" />
  </div>
</template>

<script>

import LocalityMapPage from './LocalityMapPage';

export default {
  name: 'Myloca',
  props: [
    'mapInfo'
  ],
  components: { LocalityMapPage },
  data() {
    return {
      articles: [{
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }, {
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }]
    };
  }
};
</script>
