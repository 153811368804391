<template>
  <div>
    <Header />
    <main>
      <LocalityMapPage
        :map-info="mapInfo"
      >
        <Banner />
      </LocalityMapPage>
<!--      <GeoMark>-->
<!--        <NewsOrRanking page="news" />-->
<!--        <Banner />-->
<!--      </GeoMark>-->
      <Article :articles="posts"/>
    </main>
    <NavBar :page="2" />
  </div>
</template>

<script>

import LocalityMapPage from './LocalityMapPage';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'News',
  props: [
    'mapInfo'
  ],
  components: { LocalityMapPage },
  data() {
    return {
      articles: [{
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }, {
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }]
    };
  },
  computed: {
    ...mapGetters({
      posts: 'PostStore/posts'
    })
  },
  methods: {
    ...mapActions({
      setCurrentPage: 'PageStore/setCurrentPage',
      changePostType: 'PostStore/changePostType'

    })
  },
  created() {
    this.setCurrentPage('post_new');
    this.changePostType('new');
  }
};
</script>
