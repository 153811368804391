<template>
  <div>
    <Header />
    <main>
      <LocalityMapPage
        :map-info="mapInfo"
      >
        <Banner />
      </LocalityMapPage>
      <Article :articles="articles"/>
    </main>
    <NavBar :page="5" />
    <SelectCategory v-bind:isSelect="$route.params.categoryId == 'all'" />
  </div>
</template>

<script>

export default {
  name: 'Topics',
  props: [
    'mapInfo'
  ],
  created() {
    console.log(this.category);
  },
  data() {
    return {
      articles: [{
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }, {
        title: 'ダミー記事',
        thumbnail_path: '/storage/dummy_article.jpg',
        tags: ['ハツレポ', 'テスト'],
        author: '投稿者',
        time: '2021年8月30日',
        good: 0,
        comment: 0,
        share: 0
      }]
    };
  }
};
</script>
