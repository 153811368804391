<template>
    <div>
        <l-marker
            :lat-lng="latLng"
            :icon="icon"
            :draggable="false"
            @click="$emit('select-marker',post)"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import 'leaflet/dist/leaflet.css';
import { latLng } from 'leaflet';
import * as L from 'leaflet';
const Vue2Leaflet = require('vue2-leaflet');

export default {
  name: 'PostMarker',
  components: {
    'l-marker': Vue2Leaflet.LMarker
  },
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  watch: {
    post: {
      deep: true,
      handler(post, oldPost) {
        this.icon = this.createIcon();
      }
    }
  },
  computed: {
    isLiked() {
      let liked = false;
      if (this.$auth.check()) {
        this.post.likes.forEach((like) => {
          if (like.user_id === this.$auth.user().id) {
            liked = true;
          }
        });
      }
      return liked;
    }
  },
  data: function() {
    return {
      latLng: latLng(0, 0),
      icon: null
    };
  },
  methods: {
    ...mapActions({
      convertLatLngToXY: 'MapStore/convertLatLngToXY'
    }),
    markerClick(marker) {
      this.$emit('post-marker-click', marker);
    },
    createIcon() {
      let imageUrl = process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-icon-red.png';
      if (this.post.image) {
        imageUrl = this.post.image.url;
      }
      let html = '<div class="post-marker" style="background-image:url(' + imageUrl + ')">';
      if (this.post.type === 'new' || this.post.type === 'new_all') {
        html += '<span class="marker-new"></span></div>';
      }
      if (this.isLiked) {
        html += '<span class="liked"><i class="fas fa-heart"></i></span>';
      }

      html += '<div>';
      return L.divIcon({
        shadowUrl: process.env.MIX_GEOMARK_API_URL + '/images/markers/marker-shadow.png',
        iconSize: [50, 50],
        shadowSize: [100, 50],
        shadowAnchor: [25, 50],
        iconAnchor: [25, 55],
        html: html
      });
    }
  },
  async created() {
    this.latLng = latLng(this.post.geo_lat, this.post.geo_lng);
    this.icon = this.createIcon();
  }
};
</script>
<style>
.leaflet-marker-icon {
    background: transparent;
    border: none;
}
.post-marker {
    position:relative;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 2px solid #fff;
    width: 48px;
    height: 48px;
    box-shadow: 0px 9px 7px #0000004a;
}
.post-marker:focus {
    outline:none;
}
.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive:focus {
    outline: none;
}
.post-marker:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}
span.marker-new {
  width: 10px;
  height: 10px;
  background: red;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
}

span.liked {
    position: absolute;
    top: 29px;
    right: 0px;
    color: #ff60b7;
    font-size: 15px;
    text-shadow: 0px 0px 4px #0000001c;
}
</style>
