<template>
    <div>
        <Header @show-login="showLogin()"/>
        <div class="box-reporter">
          <div class="row">
              <div class="col-3 text-left">
                <img v-if="reporter.profile_image != null" class="avatar" :src="'/storage/'+reporter.profile_image.path" alt="avatar">
                <img v-else class="avatar" :src="baseUrl + '/storage/logo.png'" alt="avatar">
              </div>
              <div class="col-1"></div>
              <div class="col-8">
                <div class="row" v-if="statistic">
                  <div class="col-3 pr-0">{{ numeral(statistic.published_posts).format(0,0) }} <br><span class="sm_spp">投稿</span></div>
                  <div class="col-4 pr-0">{{ numeral(statistic.followers).format(0,0) }} <br><span class="sm_spp">フォロワー</span></div>
                  <div class="col-4 pr-0">{{ numeral(statistic.following).format(0,0) }} <br><span class="sm_spp">フォロー中</span></div>
                  <div class="col-5 mt-2 mt-xl-3 ic-he"><i class="fas fa-heart mr-1"></i> {{ numeral(statistic.liked).format(0,0) }}</div>
                  <div class="col-6 mt-2 mt-xl-3"><span class="spe_pro text-center"><span class="ic_pp">p</span></span>
                    {{ numeral(statistic.final_location_point).format(0,0) }}pt</div>
                </div>
              </div>
          </div>
          <div class="text-left bb_re2">
            <p class="name">{{ reporter.name }}</p>
            <p v-if="reporter.reporter_information">{{ prefecture[reporter.reporter_information.prefecture_id] }} - {{ municipality[reporter.reporter_information.municipality_id] }}</p>
            <p v-if="reporter.reporter_information" class="mb-4">{{ reporter.reporter_information.bio }}</p>
          </div>
          <button v-if="$auth.check() && $auth.user().id == reporter.id" type="button" class="btn btn-default mr-xl-4"><a href="/profile">プロフィールを編集</a></button>
          <button v-if="$auth.check() && $auth.user().id == reporter.id" type="button" class="btn btn-default"><a href="#" @click="showDevelopingModal()">お気に入りを設定</a></button>
        </div>

        <div class="box-post" v-if="posts.data && posts.data.length != 0">
          <div v-for="post in posts.data" :key="post.id" class="post_detail">
            <p class="tag_md">
               <span v-for="category in post.categories" :key="category.id">
                 #{{ category.name }}
               </span>
            </p>
              <h4>{{post.title}}</h4>
            <p class="time">
                <span class="author pr-1 text-truncate">{{ post.reporter.name }}</span>
                <time>{{ post.formatted_publish_time }}</time>
            </p>
            <p class="content" v-html="post.content"></p>
              <a class="see-more-link" @click="showPostDetail(post.id)">{{ $t('see_more') }}</a>
          </div>
        </div>
        <post-detail-modal v-if="currentPopupPost"/>
        <div class="modal fade" id="show_developing_modal"
             tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true"
             data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    あなたも「ロカミン」になって、自分だけのローカル情報プラットフォームを作ろう！
                    <br>ユーザーの皆さん＝「ロカミン」が、ローカリティ！上に自分の「好き！」を集めて、心のふるさと＝「マイ・ローカル（マイロカ）」をカスタマイズできる機能を実装予定！
                    <br>①あなたが「好き！」なトピックスをフォローして、自分だけのオリジナルローカル情報マップを作ろう！
                    <br>②記事に「いいね」して、お気に入りをコレクションしよう！
                    <br>③記事にコメントして、あなたの地元愛を仲間に伝えよう！
                    <br>2021年11月15日実装予定
                    <br>coming soon...
                    <div class="text-center pt-2">
                        <button type="button" class="btn btn-secondary show-developing-button" data-dismiss="modal">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ReporterDetail',
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      reporterId: this.$route.params.reporterId,
      statistic: [],
      numeral: numeral
    };
  },
  computed: {
    ...mapGetters({
      reporter: 'ReporterStore/reporterDetail',
      posts: 'ReporterStore/posts',
      prefecture: 'ReporterStore/prefecture',
      municipality: 'ReporterStore/municipality',
      currentPopupPost: 'PostStore/currentPopupPost'
    })
  },
  created() {
    this.getReporterDetail(this.reporterId);
    this.findPostByReporterId(this.reporterId);
    this.getPrefecture();
    this.getMunicipality();
    this.getStatistic(this.reporterId).then((statistic) => {
      this.statistic = statistic;
      console.log(this.statistic);
    });
  },
  methods: {
    showLogin() {
      this.$router.push('/frontend/login');
    },
    ...mapActions({
      getReporterDetail: 'ReporterStore/getReporterDetail',
      findPostByReporterId: 'ReporterStore/findPostByReporterId',
      getPrefecture: 'ReporterStore/getPrefecture',
      getMunicipality: 'ReporterStore/getMunicipality',
      getStatistic: 'ReporterStore/getStatistic',
      setPopupStatus: 'PopupStore/setPopupStatus',
      setDetailPopupPost: 'PostStore/setDetailPopupPost',
      clickPost: 'PostStore/clickPost'
    }),
    formatTime(time) {
      const newTime = new Date(Date.parse(time));
      return newTime.getFullYear() + '年' + newTime.getMonth() + '月' + newTime.getDate() + '日';
    },
    showDevelopingModal() {
      $('#show_developing_modal').modal('show');
    },
    async showPostDetail(postId) {
      await this.setDetailPopupPost(postId);
      await this.clickPost(postId);
      this.setPopupStatus('true');
    }
  }
};
</script>
<style scoped src="../../sass/pages/reporter_detail.css"></style>
<style scoped>
.modal-content {
    text-align: left;
    padding: 10%;
}

.show-developing-button {
    color: #ffffff;
    background-color: #021B6A;
    border-color: #021B6A;
    box-shadow: none;
}
</style>
