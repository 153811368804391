import BaseApi from './BaseApi';
class PostApi extends BaseApi {
  localityApiUrl = this.baseUrl + '/api/locality';
  postApiUrl = this.baseUrl + '/api/post';

  getPosts(filter = {}) {
    return this.axios.get(this.localityApiUrl + '/post', { params: filter });
  }

  getPrefectures() {
    return this.axios.get(this.localityApiUrl + '/post/prefecture');
  }

  clickPost(postData) {
    return this.axios.post(this.postApiUrl + `/${postData.spot_id}` + '/click', postData);
  }

  likeSpot(likeData) {
    return this.axios.post(this.postApiUrl + `/${likeData.spot_id}` + '/like', likeData);
  }

  unLikeSpot(likeData) {
    return this.axios.post(this.postApiUrl + `/${likeData.spot_id}` + '/unlike', likeData);
  }

  sharePost(shareData) {
    return this.axios.post(this.postApiUrl + `/${shareData.spot_id}` + '/share', shareData);
  }

  findPostById(postId) {
    return this.axios.get(this.postApiUrl + `/${postId}`);
  }

  findPostDetailById(postId, postData) {
    let canShowMap = true;

    if (postData.canShowMap === false) {
      canShowMap = postData.canShowMap;
    }

    return this.axios.get(this.postApiUrl + `/${postId}`, {
      params: {
        canShowMap: canShowMap
      }
    });
  }
}

export default new PostApi();
